@import "variables";

.SalesGoods-main {
  background-color: var(--backColorMainLightMode);
}

#cx-main {
  .cx-inneer-header {
    height: 60px;
    background-color: var(--white_FFFFFF_LightMode);
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 10px;

    .main-inner {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .left {
        label {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: var(--gray_08_LightMode);
        }
      }

      .right {
        a {
          button {
            border: none;
            padding: 5px 15px;
            border-radius: 17.3325px;
            background-color: var(--green_3DA298_LightMode);
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: var(--white_FFFFFF_LightMode);
          }
        }
      }
    }
  }

  .cx-content-section {
    .main-row {
      margin-top: 20px;
    }

    .personal-section {
      .personal-section-wrapper {
        .templates_body-main {
          // margin-top: 20px;
          display: flex;
          flex-wrap: wrap;
          // justify-content: space-evenly;

          .training-letter {
            margin-bottom: 15px;
            position: relative;
            border-radius: 10px;
            background-color: var(--whitetbheadLightMode);

            .form-check-input:checked + label {
              // background-color: var(--gray_02_LightMode) !important;
              border-radius: 10px;
              padding: 10px;

              .img {
                img {
                  // opacity: 0.5 !important;
                }
              }

              .zoom-img {
                display: block !important;
              }
            }

            .form-check-input:checked + img {
              display: none;
            }

            .text-logo {
              border-bottom: 1px solid var(--blue_53B7E8_LightMode);
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-bottom: 5px;
            }

            label {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: var(--gray_05_LightMode);
              padding: 10px;

              .right-logo {
                cursor: pointer;
                display: flex;
                justify-content: center;

                .img {
                  padding: 1.5rem 0;

                  img {
                    width: 100%;
                  }
                }
              }
            }
          }

          .zoom-img {
            width: 12%;
            display: none;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          .active-temp {
            .img {
              img {
                opacity: 0.6;
              }
            }

            .logo-back {
              background-color: var(--green_3DA298_LightMode);
            }
          }

          .form-check-input:checked + img {
            display: none;
          }
        }
      }
    }
  }
}

.modal-templates {
  .document-popup-modal {
    padding: 5px 15px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      object-fit: cover !important;
    }
  }
}

.salary-slip-modal {
  .modal-header {
    padding-bottom: 0.5rem !important;

    .modal-title {
      font-size: 1.2rem;
    }
  }

  .modal-body {
    .salary-slip-modal-wrapper {
      padding: 10px;
      border-radius: 10px;

      // box-shadow: 0 0px 4px 1px rgba(211, 211, 211, 0.4);
      .year-selector {
        .form-group {
          .form-label {
          }

          select {
            option {
              font-size: 0.8rem;
            }
          }
        }
      }

      .month-selector {
        .form-group {
          .form-label {
          }

          select {
            option {
              font-size: 0.8rem;
            }
          }
        }
      }

      .buttons-wrapper {
        button {
          border-radius: 20px;
        }

        .btn-cancel {
          background-color: #fff;
          color: var(--blue_53B7E8_LightMode);
          border: 1px solid var(--blue_53B7E8_LightMode);
          padding: 5px 35px;
        }

        .btn-generate {
          background-color: var(--blue_53B7E8_LightMode);
          color: #fff;
          border: 1px solid var(--blue_53B7E8_LightMode);
          padding: 5px 25px;
        }
      }
    }

    .modal-footer {
      padding: 1rem 0;
    }

    .download-option {
      border: 1px solid var(--blue_53B7E8_LightMode);
      display: flex;
      align-items: center;
      border-radius: 5px;
      color: var(--gray_05_LightMode);
      padding: 0.5rem 1rem;
      justify-content: space-between;

      .date-wrapper {
      }

      .action-wrapper {
        a {
          text-decoration: none;
          color: var(--gray_05_LightMode);

          //  padding-right: 1rem;
          &:hover {
            color: var(--blue_53B7E8_LightMode);
          }

          span {
            padding-right: 8px;
          }

          img {
            width: 8% !important;
            // vertical-align: baseline !important;
            // &:nth-child(1) {
            //   width: 8%;
            //   vertical-align: baseline;
            //   // margin-left: 0.5rem !important;
            // }
          }
        }
      }
    }
  }
}

.terms-header {
  img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8px;
    height: 5px;
    margin-top: 1rem;
  }
}

.terms-wrapper {
  padding: 20px 15px;

  p {
    color: var(--gray_08_LightMode);
  }
}

.project-table-wrapper {
  margin-top: 15px;
  overflow-x: auto;
  cursor: pointer;
  display: block;
  white-space: nowrap;

  table {
    cursor: default;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;

    .project-row-2 {
      border-radius: 10px !important;
    }

    .project-table-row {
      background-color: var(--white_FFFFFF_LightMode);

      td {
        border-radius: 10px;
        padding: 8px 14px;
        font-size: 14px;
        height: 2.5rem !important;
        color: var(--gray_05_LightMode) !important;
        // display: flex;
        // justify-content: space-between;

        .project-name {
          color: #000;
        }

        .project-links {
          color: var(--blue_53B7E8_LightMode);
        }
      }

      .project-data {
        // display: flex;
        // justify-content: space-between;
        .scope {
          width: 50% !important;
          float: left;
        }

        .download-btn {
          cursor: pointer;
          float: right !important;

          span {
            margin-right: 0.5rem;
          }

          img {
            vertical-align: baseline !important;
          }
        }
      }
    }

    .accordion-table {
      margin-top: 0 !important;

      // border-radius: 10px;
      .accordion-header {
        table {
          cursor: pointer;

          tr {
            td {
              &:nth-child(1) {
                // border-radius: 10px 0px 0px 0px !important;
              }

              &:last-child {
                // border-radius: 0 10px 0 0 !important;
              }
            }
          }
        }
      }

      .accordion-heading {
        padding-left: 14px !important;
        color: var(--gray_05_LightMode);
      }

      .accordion-img {
        padding-left: 10vw !important;
      }

      .team-member-count {
        color: #fff;
        background-color: var(--blue_53B7E8_LightMode);
        padding: 4px 15px;
        border-radius: 15px;
        font-size: 12px;
      }

      .AccordianBody {
        // padding: 0 0 10px 10px !important;
        table {
          tr {
            td {
              width: 25% !important;
            }
          }
        }
      }
    }
  }
}

.personal_card {
  // width: 33.33%;
}

.project-doc-section {
  .project-doc-section-wrapper {
    .templates_body-main {
      // margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-evenly;

      .training-letter {
        margin-bottom: 15px;
        position: relative;
        border-radius: 10px;
        background-color: var(--whitetbheadLightMode);

        .form-check-input:checked + label {
          // background-color: var(--gray_02_LightMode) !important;
          border-radius: 10px;
          padding: 10px;

          .img {
            img {
              // opacity: 0.5 !important;
            }
          }

          .zoom-img {
            display: block !important;
          }
        }

        .form-check-input:checked + img {
          display: none;
        }

        .text-logo {
          border-bottom: 1px solid var(--blue_53B7E8_LightMode);
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 5px;
        }

        label {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: var(--gray_05_LightMode);
          padding: 10px;

          .right-logo {
            display: flex;
            justify-content: center;

            .img {
              padding: 1.5rem 0;

              img {
                width: 100%;
              }
            }
          }
        }
      }

      .zoom-img {
        width: 12%;
        display: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .active-temp {
        .img {
          img {
            opacity: 0.6;
          }
        }

        .logo-back {
          background-color: var(--green_3DA298_LightMode);
        }
      }

      .form-check-input:checked + img {
        display: none;
      }
    }
  }
}

.project-resources {
  .serialnumber2 {
    width: auto !important;
    padding-left: 2rem;
  }
}

.table-row-custom-padding2 {
  table {
    td {
      &:nth-child(1) {
        width: 16% !important;
      }

      &:nth-child(2) {
        width: 21% !important;
        padding-left: 0.7rem !important;
      }

      &:nth-child(3) {
        width: 23% !important;
        padding-left: 2.2rem;
      }

      &:nth-child(4) {
        width: 18% !important;
        padding-left: 1rem;
      }

      &:nth-child(5) {
        padding-left: 1rem !important;

        img {
          padding-left: 10rem;
        }
      }
    }
  }
}

.project-resources-table {
  tr {
    td {
      &:last-child {
        width: 12% !important;
      }
    }
  }
}

.chart-section {
  display: flex;
  align-items: center;
  justify-content: center;
  // vertical-align: middle;
  height: 100%;
  width: auto;
  background-color: var(--whitetbheadLightMode);
  padding: 10px;
  border-radius: 10px;

  .mixed-chart {
  }
}

.textSuccess {
  color: #1cd63a !important;
  cursor: pointer;
}

.textDanger {
  color: #d61c1c !important;
}

.textInfo {
  color: #53b7e8 !important;
  cursor: pointer;
}

.textWarning {
  color: #d6b049 !important;
  cursor: pointer;
}

.textcoral {
  color: coral !important;
}

.btnAbort {
  border: none;
  border-radius: 5px;
  color: #fff !important;
  font-size: 0.7rem !important;
  height: 25px;
  background: #d61c1c;
  width: 75px;
}

.btnComplete {
  border: none;
  border-radius: 5px;
  color: #fff !important;
  font-size: 0.7rem !important;
  height: 25px;
  background: #1cd63a;
  width: 75px;
}

.btnSuccess {
  border: none;
  border-radius: 5px;
  color: #fff !important;
  font-size: 0.7rem !important;
  height: 25px;
  background: #1cd63a;
  width: 75px;
}

.textHold {
  color: #d61c1c;
}

.textComplete {
  color: #1cd63a;
}

.btnCom {
  border: none;
  border-radius: 5px;
  color: #fff !important;
  font-size: 0.7rem !important;
  height: 25px;
  background: #1cd63a;
  width: 75px;
}

.btnHold {
  border: none;
  border-radius: 5px;
  color: #fff !important;
  font-size: 0.7rem !important;
  height: 25px;
  background: #38cad4;
  width: 75px;
}

.btnAborted {
  border: none;
  border-radius: 5px;
  color: #fff !important;
  font-size: 0.7rem !important;
  height: 25px;
  background: #e21f1f;
  width: 75px;
}

.textDanger {
  color: red !important;
}

.cursor {
  cursor: pointer;
}
.form-check-input:checked[type="radio"] {
  box-shadow: none !important;
}
.form-check-label {
  font-size: 14px;
}

.AddQuestion_contianer {
  background-color: var(--background-color);
  height: 100vh;
  padding: 40px;
  padding-top: 90px;
  flex-grow: 1;
}

.header_wrapper {
  padding-top: 90px;
  width: 17%;
}

.AddQuestion_input_wraper {
  width: 100%;
  margin: 20px 0 6px;
}

.AddQuestion_input_wraper input {
  width: 100%;
  height: 35px;
  padding: 5px;
  /* border: 1px solid var(--primary-color); */
  border: 1px solid #53b7e8;
  border-radius: 10px;
  font-size: 14px;
  color: #111111;
  font-family: Arial, sans-serif;
  resize: vertical;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  background-color: white;
  outline: none;
  margin-bottom: 6px;
}
.AddQuestion_input_wraper label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  font-family: Arial, sans-serif;
  color: #11111180;
}

.AddQuestion_Editor_wrapper {
  background-color: white;
  width: 100%;
}
.Answer_input_wraper {
  width: 100%;
  margin: 6px 0 6px;
}
.Answer_input_wraper label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  font-family: Arial, sans-serif;
  color: #11111180;
}
.AddQuestion_submit_btn {
  /* background-color: var(--primary-color); */
  background-color: #53b7e8;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 500;
  /* margin-right: 20px; */
}

/* .AddQuestion_submit_btn:hover {
  background-color: var(--secondary-color);
  color: darken(var(--text), 10%);
} */

.input_wraper {
  /* width: 100%; */
  /* margin: 6px 0; */
  /* padding: 10px; */
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  font-size: 18px;
  font-family: Arial, sans-serif;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  outline: none;
}

.input_wraper select {
  width: 100%;
  height: 38px;
  background-color: white;
  padding: 5px;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  color: #111111;
  font-size: 14px;
  font-family: Arial, sans-serif;
  resize: vertical;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  outline: none;
}

.input_wraper input {
  width: 100%;
  background-color: white;
  padding: 5px;
  /* border: 1px solid var(--primary-color); */
  border: 1px solid #53b7e8;
  border-radius: 10px;
  color: #111111;
  font-size: 14px;
  font-family: Arial, sans-serif;
  resize: vertical;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  outline: none;
}

.input_wraper label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  font-family: Arial, sans-serif;
  color: #11111180;
}

.line-horizontal {
  /* width: 100%; */
  height: 1.5px;
  background-color: #ccc;
  margin: -20px;
  margin-top: 40px;
  /* margin: 20px 0px 20px 0px; */
}

/* Styling the container */
.custom-select__control {
  background-color: white !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 10px !important;
  box-shadow: none;
  outline: none !important;
  height: 35px;
}

.custom-select__multi-value__remove:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
}

/* Styling the input text */
.custom-select__input {
  font-size: 14px !important;
  font-family: Arial, sans-serif;
}
.css-t3ipsp-control {
  border-color: none;
  box-shadow: none !important;
}
.css-hlgwow {
  height: 30px;
}
.css-hlgwow {
  height: 30px;
}
.css-19bb58m {
  height: 30px;
}
.css-1dyz3mf {
  height: 35px;
}
.css-19bb58m {
  height: 30px;
}
.css-1p3m7a8-multiValue {
  height: 33px !important;
}
.css-13cymwt-control {
  height: 35px;
}
.css-9jq23d {
  margin-top: 3px;
}
.custmRow {
  display: flex;
}
.addDltIconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
}
.colWdth {
  width: 48%;
  padding-right: 15px;
}
.colWdthIcon {
  width: auto;
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 768px) {
  .colWdth {
    width: 46%;
  }
}

.Campaignrow {
  display: flex;
  align-items: center;
  margin: 20px;
  background-color: bisque;
}

.Campaigndropdown {
  padding: 5px;
  
  border: none;
  border-bottom-left-radius: 10px;
 border-bottom-left-radius: 10px;

  width: 200px;
  height: 40px;
}

.Campaignbutton {
  padding: 5px 10px;
  background-color: var(--blueBorderColor_53B7E8_LightMode);
}
.CampaignContainer {
  margin: 20px;

}

.CampaignRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

}

.CampaignDropdown,
.CampaignInput {
  padding: 5px;
  
  font-size: 10;
  color: green($color: #000000);
}

.CampaignButton {
  padding: 6px 10px;
  background-color: var(--blueBorderColor_53B7E8_LightMode);
  border: none;
  color: white;
  margin-right: 20px;
}

@media (max-width: 768px) {
  .Campaignrow {
    display: block;
    align-items: center;
    margin: 20px;
  }
}

@import "variables";

.cx-header {
  position: relative;

  .cx-header-wrapper {
    padding: 0 18px;
    height: 60px;
    background-color: var(--white_FFFFFF_LightMode);
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    justify-content: end;
    z-index: 111;
  }

  justify-content: end;
  transition: all 0.4s ease-in-out;

  #customDropdown{
    background-color: white;
    button{
      background-color: white;
    }
    .dropdown-toggle::after{
      display: none;
    }
  }
  header {
    .bell {
      img {
        // margin-top: 7px;
      }
    }

    .btn-header {
      margin-left: 10px;

      .greenBtnHeader {
        height: 35px;
        background-color: #edf7fc;
        border: none;
        border-radius: 17.3325px;
        font-style: normal;
        width: 84px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #53b7e8;
      }

      .blueBtnHeader {
        height: 35px;
        background-color: var(--blueLight_53B7E8_LightMode);
        border: none;
        border-radius: 17.3325px;
        font-style: normal;
        width: 84px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: var(--blue_53B7E8_LightMode);
      }

      // button {
      //     height: 35px;
      //   background-color: var(--greenLight_3DA298_LightMode);
      //   border: none;
      //   border-radius: 17.3325px;
      //   font-style: normal;
      //   width: 84px;
      //   font-weight: 500;
      //   font-size: 14px;
      //   line-height: 21px;
      //   color: var(--green_3DA298_LightMode);
      // }
    }

    .profile-header {
      margin: 0 10px;
      position: relative;
      width: 35px;
      height: 35px;
      border-radius: 50%;

      .pic {
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }

      .add-green {
        position: absolute;
        right: 0px;
        bottom: -8px;
      }
    }

    .arrow {}
  }
}

.ProfileHeader {

  .btn-check:checked+.btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check)+.btn:active {
    border-color: transparent;
  }

  .dropdown-toggle::after {
    display: none !important;
   // color: #53b7e8;
  }

  .header-down-arrow {
    width: 130%;
  }

  .dropdown-menu {
    border-radius: 15px 0 15px 15px;
    margin-top: 15px;
    padding: 15px;
    max-height: 300px;
    overflow-y: auto;
    // border: 1px solid red;

    .dropdown-item {
      padding: 0 !important;
      border-bottom: 1px solid rgba(17, 17, 17, 0.1);

      &:last-child {
        border: none !important;
        margin-bottom: 0 !important;
      }
    }

    a {
      display: flex;
      margin-bottom: 6px;
      text-decoration: none;

      img {
        margin-right: 10px;
      }

      span {
        font-size: 14px;
        margin-left: 10px;
        color: var(--gray_05_LightMode);
      }

      .active {
        color: var(--blue_53B7E8_LightMode);
      }

      // padding: 10px 20px;
    }

    :active {
      background-color: transparent;
      color: black;
    }

    :hover {
      // border-radius: 14px;
      background-color: transparent;
    }
  }
}

.hovercolor:hover {
  color: #53b7e8 !important;
}
@import "variables";
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.main-content-container {
  margin-left: 252px;
  padding: 20px;
  height: 85vh;
  /* background: red; */
  overflow: auto;
}

.background-body-wrapper {
  padding: 20px;
  overflow: auto;
}

.taskMain {
  background-color: var(--backColorMainDarksecond) !important;

  .adjustableTab {
    height: calc(100vh - 300px);

    table {
      thead {
        tr {
          th {
            position: sticky;
            top: 0px;
            background-color: #fff;

            &:first-child {
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            }

            &:last-child {
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
            }

            padding: 10px;
          }
        }
      }
    }
  }
}

.salesReportMain {
  background-color: var(--backColorMainLightMode) !important;
}

.purchasReportMain {
  background-color: var(--backColorMainDarksecond) !important;
}

// ::-webkit-scrollbar {
//   width: 0 !important;
//   height: 0 !important;
// }

.sub-header-role {
  background-color: var(--white_FFFFFF_LightMode);
  border-radius: 10px;
  padding-left: 20px;
  // padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;

  h6 {
    font-size: 18px;
    font-weight: 600;
    color: var(--black_000000_LightMode) !important;
  }
}

.create-btn-sub-header {
  border: 1px solid var(--blue_53B7E8_LightMode);
  background-color: var(--whitetbheadLightMode);
  border-radius: 18px;
  color: var(--blue_53B7E8_LightMode);
  font-size: 14px;
  width: 160px;
  height: 35px;
  margin-right: 10px;
}

.all-anc-btn-sub-header {
  border: none;
  background-color: #fff;
  border-radius: 10px !important;
  color: var(--blue_53B7E8_LightMode);
  font-size: 14px;
  width: 160px;
  height: 35px;
  margin-right: 10px;
}

.my-anc-btn-sub-header {
  border: none;
  background-color: #fff;
  border-radius: 10px !important;
  color: var(--blue_53B7E8_LightMode);
  font-size: 14px;
  width: 190px;
  height: 35px;
  margin-right: 10px;
}

textarea {
  resize: none;

  &::placeholder {
    color: var(--gray_05_LightMode) !important;
    // color: red !important;
  }
}

.salary_btn_add {
  border: none;
  background-color: var(--blue_53B7E8_LightMode);
  border-radius: 18px;
  color: var(--white_FFFFFF_LightMode);
  font-size: 0.8rem;
  width: 60px;
  padding: 2px 4px;
  margin-right: 10px;
}

.request-to-register-btn {
  border: none;
  background-color: var($blue_deeplight_EEF8FD);
  border-radius: 18px;
  color: var(--blue_53B7E8_LightMode);
  font-size: 0.8rem;
  width: 169px;
  height: 35px;
}

.role-content {}

.search-input-wrapper {
  position: relative;
  margin-top: 24px;

  img {
    position: absolute;
    top: 12px;
    left: 227px;
  }

  input {
    width: 250px;
    height: 40px;
    background-color: var(--white_FFFFFF_LightMode);
    border-radius: 10px;
    border: none;
    padding-left: 10px;
    color: var(--black_111111_LightMode);
    font-size: 0.8rem;
    padding-right: 32px;
  }
}

.search-input-wrapper2 {
  position: relative;

  img {
    position: absolute;
    top: 12px;
    right: 10px;
  }

  input {
    width: 250px;
    height: 40px;
    background-color: var(--white_FFFFFF_LightMode);
    border-radius: 10px;
    border: none;
    padding-left: 10px;
    color: var(--black_111111_LightMode);
    font-size: 0.8rem;
    padding-right: 32px;

    &:focus {
      outline: none;
    }
  }
}

.search-input-wrapper input:focus {
  outline: none;
}

.colorlightmode {
  color: grey !important;
}

.colorlightmodeinput {
  border-color: var(--blue_53B7E8_LightMode) !important;
}

.colorButtonLight {
  div {
    button {
      color: var(--blue_53B7E8_LightMode);
    }
  }
}

.wrapperMt {
  margin-top: 24px !important;
}

.entries-wrapper {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-left: 10px;

  p {
    margin-bottom: 0;
    font-size: 0.8rem;
    color: grey;
    display: flex;
    align-items: center;
  }

  select {
    //   width: 65px;
    //   height: 40px;
    //   font-size: 0.8rem;
    //   color: grey;
    //   margin-left: 15px;
    border: none;
    //   background-color: white;
    border-radius: 10px;
  }
}

.custom-table-width {
  table {
    // width: 1400px !important;
  }
}

.Custom-tabs-rable {
  div {
    // border: 1px solid ;
  }
}

.select-drop-icon {
  background-image: url("../images/sidebar/icons/dropDown.svg") !important;
  background-size: 9px 12px !important;
  border: 1px solid var(--gray_05_LightMode);
  color: black !important;
  //AF code
  background-position: 98%;

  option {
    color: var(--gray_05_LightMode) !important;
  }
}

.role-table-header {
  display: flex;
}

.RspSalary {
  .import-export-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    // flex-direction: column;
    button {
      border: none;
      height: 40px;
      width: 40px;
      border-radius: 10px;
      background-color: #ffff;

      img {
        cursor: pointer;
      }

      &:first-child {
        margin-right: 20px;
      }

      &:focus-visible {
        outline: none;
      }
    }

    .file-input-btn {
      position: relative;
      // transform: rotate(90deg);
      cursor: pointer;
      width: 40px;
      height: 40px;
      background-color: #fff;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      input,
      #file-upload-button,
      input::-webkit-file-upload-button {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }
  }
}

.bordernone {
  border-color: var(--green_3DA298_LightMode) !important;
}

.role-btns-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  .DropdownMenoReport {
    padding: 0;

    .btn {
      padding: 0;
    }
  }

  button {
    border: none;
    height: 40px;
    width: 40px;
    background-color: var(--white_FFFFFF_LightMode);
    border-radius: 10px;
    margin-left: 20px;
  }

  .up-img-sales {
    position: absolute;
    top: -11px;
    right: 10px;
    // left: 162px;
  }

  .dropdownMeno {
    margin-top: 15px;
    // height: 150px;
    width: 200px;
    border-radius: 15px;
    padding: 10px;
  }
}

.DropdownMenoReport button::after {
  display: none !important;
}

.DropdownMenoReport .btn:hover {
  background-color: var(--gray_81888C_LightMode);
}

.dropdownmenulist22 {
  padding: 0;

  li {
    padding: 1px 10px;
    margin: 5px 5px;
    font-size: 12px;
    list-style-type: none;
    border-radius: 5px;
    color: var(--gray_05_LightMode);
  }
}

.dropdownmenulist22 li:hover {
  background-color: var(--blue_deeplight_EEF8FD);
  color: var(--blue_53B7E8_LightMode);
}

.dropdownmenulist {
  padding: 0;

  li {
    // border: 1px solid red;
    list-style-type: none;
    width: 100%;
    margin: 10px 0;
    padding: 1px 10px;
    border-radius: 5px;
    color: var(--gray_05_LightMode);
    display: flex;
    justify-content: space-between;
  }

  .innerlistrpt {
    input {
      margin-right: 20px;
      caret-color: pink !important;
    }
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked+label,
  [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }

  [type="radio"]:checked+label:before,
  [type="radio"]:not(:checked)+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1.5px solid var(--gray_05_LightMode);
    border-radius: 100%;
    background: #fff;
  }

  [type="radio"]:checked+label:before,
  [type="radio"]:checked+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid var(--gray_05_LightMode);
    border-radius: 100%;
    background: #fff;
  }

  [type="radio"]:checked+label:after,
  [type="radio"]:not(:checked)+label:after {
    content: "";
    width: 11px;
    height: 11px;
    border-color: var(--green_3DA298_LightMode);
    background: var(--green_3DA298_LightMode);
    position: absolute;
    top: 3.1px;
    left: 3.67px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    border-color: var(--green_3DA298_LightMode);
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    border-color: var(--green_3DA298_LightMode);
    transform: scale(1);
  }
}

// .dropdownmenulist li .checkedimgcustum input:checked {
//     margin-right: 10px;
//     border:  none;
//     padding: 2px;
//     border-radius: 50%;
// }

.downarrowrpt {
  // text-align: end;
  color: var(--black_000000_LightMode);
}

.dropdownmenulist li:hover {
  background-color: var(--backColorMainLightMode);
  color: var(--green_3DA298_LightMode);
}

.lightdropdown22 li:hover {
  background-color: var(--backColorMainDarksecond);
  color: var(--blue_53B7E8_LightMode);
}

.activestateactive {
  background-color: var(--backColorMainLightMode);
  color: var(--green_3DA298_LightMode);
}

.topsectionReport {
  display: flex;
  justify-content: space-between;
  //
  align-items: center;

  .filtermenu {
    font-size: 16px;
    color: var(--gray_05_LightMode);
  }

  .filterline {
    border: 1px solid var(--gray_05_LightMode);
    width: 100px;
    height: 0;
  }
}

.role-searchbar-and-btns {
  // cursor: pointer;
  display: block;
  // overflow-x: auto;
  flex-wrap: wrap;
  white-space: nowrap;
  margin-bottom: 20px;

  // Announcment buttons
  .ancmnt-btn {
    background-color: transparent;
    border: none;
    padding: 8px 0;
    color: var(--gray_05_LightMode);
  }

  .active {
    background-color: var(--blueBorderColor_53B7E8_LightMode);
    color: #ffff;
  }
}

.table-wrapper {
  padding-right: 10px;
  margin-top: 15px;
  overflow-x: auto;
  display: block;
  white-space: nowrap;
  // height: calc(100vh - 200px);
  // overflow-y: scroll;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
  }

  td {
    padding: 9px 10px;
    font-size: 0.75rem;
    height: 2.5rem !important;

    // color: var(--black_000000_LightMode);
    input {
      font-size: 0.75rem;
    }
  }

  th {
    padding: 10px;
    color: var(--gray_08_LightMode);
    font-size: 0.8rem;
    font-weight: 700;
    background-color: white;
    font-family: "Poppins";
    color: red;
    position: sticky;
    top: 0px;

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  td:first-child,
  th:first-child {
    border-radius: 10px 0 0 10px;
    // padding-left: 3rem;
  }
  .status-green {
    color: $green_07BE24_LightMode !important;
    // font-size: 14px;
    cursor: pointer;
  }
  .status-green-dark {
    color: #016711!important;
    // color: $green_07BE24_LightMode !important;
    // font-size: 14px;
    cursor: pointer;
  }
  
  
  .status-label-grey {
    color: #ff9933 !important;
    // font-size: 14px;
    cursor: pointer;
  }
  .status-red {
    color: var(--red_EE1D52_LightMode) !important;
    // font-size: 14px;
    cursor: pointer;
  }
  td:last-child,
  th:last-child {
    border-radius: 0 10px 10px 0;
    padding-right: 2rem;
    // padding: 5px !important;
  }

  .custom-padding-for-btn {
    padding: 4px 14px !important;
  }
}

.table-wrapper2 {
  margin-top: 15px;
  overflow-x: auto;
  cursor: pointer;
  display: block;
  white-space: nowrap;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
  }

  td {
    font-size: 0.75rem;

    height: 2.5rem !important;
    color: var(--black_000000_LightMode);
  }

  th {
    padding: 5px 10px;
    color: var(--gray_05_LightMode);
    font-size: 0.75rem;
  }

  td:first-child,
  th:first-child {
    border-radius: 10px 0 0 10px;
    //
  }

  td:last-child,
  th:last-child {
    border-radius: 0 10px 10px 0;
    //
  }

  .custom-alert {
    background-color: var(--greenLight_E0FAE4_LightMode);
    border: 1px solid var(--green_3DA298_LightMode);
    color: #000;
  }
}

.table-row-custom {
  background-color: var(--white_FFFFFF_LightMode);
  border-radius: 10px;

  span {
    // margin-left: 5px;
    // padding: 4px 10px;
    border-radius: 14px;
    font-weight: 400;
    // color: #939292;
  }

  vertical-align: baseline;

  .Rejected-tabel-data {
    color: var(--Red_text_07BE24_lightMode);
  }

  .Approve-tabel-data {
    color: #07be24;
  }

  .disabled {
    img {
      cursor: not-allowed;
    }
  }

  .pending-tabel-data {
    color: var(--Warning_text_07BE24_lightMode);
  }
}

.create-role-text {
  font-size: 1.12rem;
  color: var(--black_111111_LightMode);
  font-weight: 600;
}

.enter-role-text {
  font-size: 0.8rem;
  color: var(--black_111111_LightMode);
}

.select-drop-icon-1 {
  background-size: 0 !important;
  margin-top: 8px;
}

.form-select:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.emptyPage {
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subheading_salary {
  color: var(--black_000000_LightMode) !important;
}

.tabalHeading {
  display: flex;
  padding: 0;

  li {
    list-style-type: none;
  }
}

.addSalary {
  .nameinpute {
    width: 130px;
  }

  input {
    color: var(--black_111111_LightMode);
    background-color: transparent;
    width: 70px;
    padding: 0;
    margin: 0;
    border: 1px dashed var(--blueBorderColor_53B7E8_LightMode);
    padding-left: 10px;
  }

  input:focus {
    outline: none;
  }
}

.dropdownn {
  position: relative;
  display: inline-block;
}

.dropdown-contentt {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdownn:hover .dropdown-contentt {
  display: block;
}

.firstTabelRow th:first-child {
  padding-left: 2rem !important;
}

.table-row-main td:first-child {
  padding-left: 2rem !important;
}

.firstTabelRow th:first-child,
.firstTabelRow th:last-child {
  // border-radius: 0;
}

.firstTabelRow {
  th {
    // border-bottom: 1px solid var(--black_000000_LightMode) !important;
    border-radius: 0;
    font-weight: 700 !important;
    font-size: 13px;
    font-family: "Poppins";
    font-weight: 700;
    color: #000;
  }
}

// pagenation
.pagination {
  // width: 100%;
  // border: 1px solid red;
  display: flex;
  // height: 22px;
  text-align: right;
  // justify-content: end;
  position: absolute;
  bottom: 4px;
  right: 18px;

  button {
    padding: 4px;
    width: 22px;
    background-color: transparent;
    border: 1px solid var(--green_06685E_LightMode);
    font-size: 10px;
    color: var(--green_06685E_LightMode);
    margin-left: 10px;
    border-radius: 4px;
  }

  .backgroundPagination {
    background-color: var(--green_06685E_LightMode) !important;
    color: var(--white_FFFFFF_LightMode);
  }
}

//pagination

.ligthpagination {
  // width: 100%;
  // border: 1px solid red;
  display: flex;
  // height: 22px;
  text-align: right;
  // justify-content: end;
  position: absolute;
  bottom: 30px;
  right: 18px;

  button {
    padding: 4px;
    width: 22px;
    background-color: transparent;
    border: 1px solid var(--blue_53B7E8_LightMode);
    color: var(--blue_53B7E8_LightMode);
    font-size: 10px;
    margin-left: 10px;
    border-radius: 4px;
  }

  .backgroundPagination {
    background-color: var(--blue_53B7E8_LightMode);
    color: var(--white_FFFFFF_LightMode);
  }
}

// model
.salary_model_main {
  padding: 22px;

  .salary_model_header {
    .sub_heading_main {
      font-size: 20px !important;
      padding-left: 9px;
    }

    color: var(--blue_53B7E8_LightMode);
    border-color: var(--blue_53B7E8_LightMode);
  }
}

.model_tabel {
  width: 100%;

  tr {

    // width: 600px;
    td {
      padding-bottom: 10px;
      color: var(--black_111111_LightMode);
    }

    th {
      padding-bottom: 10px;
    }
  }
}

.salary_datatabel {
  table {
    width: 100%;

    tr {

      // border: 1px solid red;
      td {
        border: 1px solid var(--black_000000_LightMode);
        padding: 5px 15px 5px 15px;
        font-size: 11px;
      }
    }
  }
}

////  sales report

.salasReport_topList {
  display: flex;
  flex-wrap: wrap;
  width: 350px;
  justify-content: space-between;
  align-items: center;

  li {
    list-style-type: none;
    // margin-left: 20px;
    border-right: 1px solid var(--gray_666666_LightMode);
    padding-right: 20px;
    color: var(--gray_02_LightMode);
    cursor: pointer;
  }
}

.activetabs {
  color: green !important;
}

.activetabs2 {
  color: var(--blue_53B7E8_LightMode) !important;
}

.salasReport_topList li:last-child {
  border: none;
}

// date model

.dtaemodel_headimg {
  color: var(--green_3DA298_LightMode);
  font-size: 16px;
}

.datemodel {
  label {
    color: var(--gray_05_LightMode);
    margin-right: 20px;
    font-size: 14px;
  }

  input {
    border: 1px solid var(--green_3DA298_LightMode);
    height: 40px;
    width: 155px;
    padding: 11px 5px;
    font-size: 14px;
    border-radius: 10px;
  }
}

.slaarydetaiscolore {
  color: var(--green_3DA298_LightMode);
}

.datemodel input:focus {
  outline: none;
}

.form-check-input~ :checked {
  border: none;
  background-color: var(--green_3DA298_LightMode);
}

// company model
.companyModelMain {
  display: flex;
  justify-content: space-around;
  align-items: center;

  p {
    color: var(--gray_05_LightMode);
  }
}

.threedotOption {
  background-color: transparent;
  border: none;
  padding: 0;
}

// .threedotOption:hover {
//     background-color: var(--blue_deeplight_EEF8FD) !important;
// }

.dropdownMeno22 {
  border: none;
  box-shadow: 2px 2px 10px var(--gray_05_LightMode);
  background-color: var(--white_FFFFFF_LightMode);
  padding: 0;
  position: absolute !important;
  // inset: -22px -130px auto auto !important;
  margin: 0;
}

.componymodelDrop {
  border: 1px solid var(--green_3DA298_LightMode);
  width: 371px;
}

.salesList {
  td {
    font-size: 10px;
  }
}

.changeViweToggle {
  // border: 1px solid red;
  display: flex;
  justify-content: space-between;
}

//// Invoive details

.invoice_upper_section {
  border-bottom: 1px solid var(--green_3DA298_LightMode);

  .invoice_Upper_tabal {
    label {
      font-size: 14px;
      color: var(--gray_08_LightMode);
      margin-bottom: 10px;
    }

    h3 {
      font-size: 18px;
      color: var(--black_111111_LightMode);
      font-weight: 500;

      span {
        padding: 2px 20px;
        background-color: var(--green_3DA298_LightMode);
        color: var(--white_FFFFFF_LightMode);
        font-size: 14px;
        border-radius: 50px;
      }
    }
  }
}

// Add task Model Final Styles
.innerData {
  padding: 10px;
}

.mainContentmodel {
  width: 100%;
  // border: 1px solid rgb(221 234 243 / 60%) !important;
  padding: 15px;
  // border-radius: 10px;
  // box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%), inset 0 -1px 0 rgb(0 0 0 / 15%);
}

.text-grey {
  font-weight: 500;
  font-size: 15.296px;
  line-height: 23px;
  color: black;
}

.tasKCategory1 {
  color: black;
  border: 1px solid var(--blueBorderColor_53B7E8_LightMode);
  padding: 5px 5px;
}

.editBtn {
  cursor: pointer;
}

.tasKCategory3 {
  color: black;
  border: 1px solid var(--blueBorderColor_53B7E8_LightMode);
  max-width: 100% !important;
}

.bottomLeaveSec {

  // padding: 20px;
  .leave-structure {
    border: 1px solid var(--blueBorderColor_53B7E8_LightMode);
    border-radius: 10px;
    padding: 10px 10px 0;

    // .modal-footer-table-head {
    //   tr {
    //     th {
    //       color: #828282 !important;
    //     }
    //   }
    // }

    .modal-footer-table-body {
      border: 0px solid #fff !important;

      tr {
        &:hover>* {
          box-shadow: none !important;
          color: inherit;
        }

        td {
          font-size: 0.9rem;
          padding: 0.44rem 0.5rem;
          color: var(--gray_05_LightMode) !important;
        }
      }
    }
  }
}

.status-green {
  color: $green_07BE24_LightMode !important;
  font-size: 14px;
  cursor: pointer;
}

.status-label-grey {
  color: #ff9933 !important;
  font-size: 14px;
  cursor: pointer;
}

.status-label-marron {
  color: #ac3939 !important;
  font-size: 14px;
  cursor: pointer;
}

.status-h-leave {
  color: #1a8cff !important;
  font-size: 14px;
  cursor: pointer;
}

.status-h-late,
.status-E-pout {
  color: #000 !important;
  font-size: 14px;
  cursor: pointer;
}

.colorA {
  color: teal;
}

.colorAl {
  color: #1a8cff;
}

.colorP {
  color: orange;
}

.colorR {
  color: red;
}

.colorC {
  color: lightgreen;
}

.colorDe {
  color: gray;
}

.colorRe {
  color: blue;
}

.colorAb {
  color: maroon;
}

.colorH {
  color: darkmagenta;
}

.colorIn {
  color: darkgreen;
}

.status-yellow {
  color: $yellow_FFA903_LightMode !important;
  font-size: 12px;
  cursor: pointer;
}

.status-red {
  color: var(--red_EE1D52_LightMode) !important;
  font-size: 14px;
  cursor: pointer;
}

.status-blue {
  color: var(--blue_53B7E8_LightMode) !important;
  font-size: 14px;
  cursor: pointer;
}

.status-grey {
  color: var(--gray_666666_LightMode) !important;
  font-size: 14px;
  cursor: pointer;
}

.serialnumber {
  width: 15% !important;
  padding-left: 2rem !important;
}

.projectname {
  padding-left: 1.5rem !important;
  width: 20% !important;
}

.totalbudget {
  padding-left: 2.7rem !important;
  width: 30% !important;
}

.remark {
  width: 25% !important;
  // AF padding-left: 0.8rem !important;
}

.btn-green {
  background-color: #1cd63a;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
}

.btn-red {
  cursor: pointer;
  background-color: #dc1818;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
}

.Announcement {
  .top {
    h3 {
      margin: 0;
    }

    p {
      b {
        color: black;
        margin-right: 10px;
      }
    }
  }

  .AnnouncementImg {
    // border: 1px solid grey;
    width: 100%;
    height: 250px;
    margin: 20px 0;
    border-radius: 15px;
    background-color: rgb(203, 203, 203);
  }
}

.pointerCurcer12 {
  cursor: pointer;
}

.ListModel {
  overflow-y: auto;
  padding: 0 10px 10px;
}

.totalslistwrap {
  height: 60vh;
  padding-top: 8px !important;
}

.listMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  .name {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #111111;
    margin-left: 21px;
    margin-top: 10px;
  }

  .destion {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    color: rgba(17, 17, 17, 0.5);
  }
}

.listMain:last-child {
  margin-bottom: 0;
}

.carsousel-img-model {
  .carousel-item {
    // width: 1400px;
    height: 70vh;
  }

  .carousel-control-next {
    position: absolute;
    right: -40%;
  }

  .carousel-control-prev {
    position: absolute;
    left: -40%;
  }
}

.imagecarosla {
  background-color: #111111;
}

.covercount {
  color: white;
  background-color: var(--blue_53B7E8_LightMode);
  padding: 2px 10px;
  border-radius: 80px;
  font-size: 12px;
}

.views-header {
  padding: 15px 20px 10px 20px !important;
}

.birderbotton {
  // padding-bottom: 12px;
  // border-bottom: 1px solid lightgray;
}

.mydiary-btn {
  margin-left: 10px;
}

.mydiary-active {
  background-color: var(--blue_53B7E8_LightMode);
  padding: 7px 13px;
  border: none;
  border-radius: 10px;
  color: white;
}

.mydiary-unactive {
  background-color: white;
  color: rgba(17, 17, 17, 0.5);
  padding: 7px 13px;
  border: none;
  border-radius: 10px;
}

.mydiry-contain {
  height: 350px;
  padding: 0;

  .active {
    padding: 10px 20px;
    background-color: #04b5204d;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid #449751;

    h5 {
      border-bottom: none;
      padding: 0;
      margin: 0;
      color: #016711;
    }
  }

  .heading {
    padding: 10px 20px;
    border-radius: 10px 10px 0 0;

    h5 {
      font-size: 14px;
      color: rgba(17, 17, 17, 0.5);
      margin: 0;
    }
  }

  .check-in-out-details {
    ::-webkit-scrollbar {
      display: none !important;
    }

    table {
      margin: 15px;

      tr {
        td {
          padding-bottom: 10px;
          vertical-align: baseline;

          &:nth-child(1) {
            color: rgba(17, 17, 17, 0.5);
          }

          &:nth-child(3) {
            color: rgba(17, 17, 17, 0.5);
          }
        }
      }
    }
  }

  .custom-label {
    width: 35% !important;
    display: flex !important;
    justify-content: space-between;
    // height: 20%;
  }
}

.custumheight {
  // height: 185px;
}

.overflowx-auto {
  overflow-y: auto;
}

.emptydiart {
  background-color: rgba(243, 207, 207, 1);
  height: 60px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;

  p {
    color: rgba(17, 17, 17, 0.5);
  }
}

.dairy-btn {
  padding-bottom: 30px;
  display: flex;
  justify-content: end;

  button {
    padding: 5px 15px;
    background-color: #fff;
    color: var(--blue_53B7E8_LightMode);
    border: 1px solid var(--blue_53B7E8_LightMode);
    border-radius: 50px;
  }
}

.diary-tabs-main {
  ul {
    border-color: transparent !important;
  }

  .nav-tabs .nav-link {
    background-color: #fff;
    border-radius: 10px;
    margin-right: 10px;
    margin-top: 20px;
    color: rgba(17, 17, 17, 0.5);
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    background-color: var(--blue_53B7E8_LightMode);
    color: white;
  }
}

.box-shdowCustum {
  table {
    margin-left: 5px;
    margin-right: 5px;
    width: 99%;

    .firstTabelRow {
      box-shadow: none;
    }

    tr {
      box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
        rgba(17, 17, 26, 0.1) 0px 0px 8px;
      margin: 10px 15px;
    }
  }
}

.clint-name {
  text-align: left;
  font-size: 10px;
  color: var(--black_000000_LightMode) !important;
  padding: 0 !important;
  margin: 0 !important;
}

.complete-name {
  padding: 0 !important;
  margin: 0 !important;
  color: var(--gray_05_LightMode) !important;
  font-size: 10px;
}

.customwidthFull {
  width: 100%;

  .accordion-header {
    div {
      img {
        margin-top: 15px !important;
        margin-right: 10px;
      }
    }
  }
}

.todayDiary {
  .mydiry-contain {
    min-height: 350px;
    height: fit-content;
  }

  .overflowx-auto {
    overflow-y: initial;
  }

  .mydiary-heading {
    padding: 12px 20px 6px;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid var(--blue_53B7E8_LightMode);
    display: flex;
    justify-content: space-between;

    h5 {
      font-size: 14px;
      color: rgba(17, 17, 17, 0.5);
      margin-bottom: 0;
      vertical-align: middle;
    }

    span {
      font-size: 14px;
      color: rgba(17, 17, 17, 0.5);
    }
  }
}

.btn-width {
  width: 122px;
  height: 35px;
}

.modal-content {
  #leave-detail-btns {
    .saveBtn {
      background-color: #1cd63a;
    }

    .cancelBtn {
      border: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */

      color: #ffffff;
      background-color: #dc1818;
    }
  }

  .leaveDetails-table-wrapper {
    overflow-x: auto;
    padding: 10px;

    table {
      width: 100%;
      min-width: 700px;

      thead {
        tr {
          th {
            width: 22%;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            padding-bottom: 15px;
            color: #828282;
          }
        }
      }

      tbody {
        .form-check-input:focus {
          box-shadow: none;
        }

        .form-check-input:checked[type="checkbox"] {
          background-size: 10px;
          background-image: url(../images/green_tick.png);
        }

        .form-check-input {
          border-radius: 0;
          border: 1px solid rgba(17, 17, 17, 0.5);
        }

        .form-check-input:checked {
          box-shadow: none;
          border-color: #07be24;
          background-color: transparent;
        }

        tr {
          td {
            padding-top: 15px;

            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #828282;

            &:nth-child(4) {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.searchWrapper {
  //border: 2px solid var(--gray_02_LightMode) ;

  // border-bottom: 0.5px solid #666 !important;
}

.breakerLine {
  border-bottom: 1px solid var(--gray_02_LightMode);
  margin-bottom: 10px;
}

.multiSelectContainer ul {
  margin-top: 10px !important;
  border: 1px solid var(--gray_02_LightMode) !important;
  border-radius: 12px !important;
}

.highlightOption {
  background-color: #53b7e8 !important;
}

.multiSelectContainer li:hover {
  background-color: #53b7e8 !important;
}

.chip {
  background-color: #53b7e8 !important;
}

.multiSelectContainer li {
  padding: 8px !important;
  font-size: 12px !important;
}

.noMsgsBlog {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .chatsImg {
    height: auto;
    width: 10%;
    object-fit: fill;
  }

  span {
    font-size: 18px;
    font-weight: 500;
    color: gray !important;
    // opacity: 0.7;
  }
}

// Modal input customize
.p-calendar {
  width: 100% !important;
}

.blueColor {
  color: #53b7e8;
}

.modalProp {
  display: flex;
  flex-direction: column;

  input {
    border-radius: 0.375rem;
    // padding: 0.2rem !important;
    width: 100% !important;
  }
}

.deleteBtn,
.editBtn {
  cursor: pointer;
}

.boldText {
  font-weight: bold;
  font-size: 20px;
  color: var(--gray_05_LightMode) !important;
}

#customTable {
  .adjustWidth {
    max-width: 150px !important;
    white-space: break-spaces !important;
  }
}

.table-wrapper-2 {
  height: calc(100vh - 200px);
  overflow-y: auto;

  table {
    tr {
      th {
        position: sticky;
        top: 0;
        background: #fff;
      }
    }
  }
}

#scrol-tbl {
  height: calc(100vh - 250px);
  overflow-y: scroll;
}

.startdate {
  // padding-left: 4.2rem !important;
  width: 20% !important;
  text-align: start;
}

.enddate {
  //padding-left: 2.7rem !important;
  width: 20% !important;
}

.image-upload>input {
  display: none !important;
}

.badge-add-icon {
  display: none;
}

.image-upload:hover {
  .badge-add-icon {
    display: inline;
  }
}
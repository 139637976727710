.LeadSearchrow {
  flex-direction: row;
  display: flex;
  align-items: center;

  justify-content: space-between;
}
.reset {
  height: 40px;
  background-color: var(
    --blueBorderColor_53B7E8_LightMode
  ); /* Change this to your desired background color */
  color: white; /* Change this to your desired text color */
  text-align: center;
  text-decoration: none;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  align-items: center;

  display: flex;
  width: 100px;
  justify-content: center;
}

.reset:hover {
  color: var(--blueBorderColor_53B7E8_LightMode);
  border: 1px solid var(--blueBorderColor_53B7E8_LightMode) ;
  background-color: white; /* Change this to your desired hover color */
}
// .reset {
//   background-color: var(--blueBorderColor_53B7E8_LightMode);
//   color: white;

//   border-radius: 10;
//   align-items: center;

//   justify-content: center;
//   width: 100px;
//   border: 1px solid var(--blueBorderColor_53B7E8_LightMode);
// }
@media (max-width: 768px) {
  .LeadSearchrow {
    flex-direction: column;
    align-self: flex-start;
    display: flex;
  }
}

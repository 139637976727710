@import "variables.scss";

html,
body {
  margin: 0;
  padding: 0;
}

/*=====================================
---------------------------------------
R E S P O N S I V E 2  - CSS  - S T A R T 
---------------------------------------
=======================================*/

/*=====================================
---------------------------------------
BRAKE POINTS:-
            - max-width: 1024px
            - min-width: 768px
            - max-width: 667px            
            - max-width: 600px            
            - max-width: 568px
            - max-width: 480px
            - max-width: 375px Main Changer
            - max-width: 350px            
            - max-width: 320px            
---------------------------------------   
=======================================*/

@media screen and (max-width: 1300px) {
  .accordion-item {
    width: 1300px;
    // border-radius: 10px !important;
  }
  .download-btn {
    float: right !important;
  }
}

@media screen and (max-width: 1024px) {
  .accordion-item {
    width: 1300px;
    // border-radius: 10px !important;
  }
  .download-btn {
    float: right !important;
  }
  // .accordion {
  //   overflow-x: auto;
  // }
  .table-row-custom-padding {
    table {
      td {
        &:nth-child(4) {
          img {
            margin-left: 18vw !important;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .table-row-custom-padding {
    table {
      td {
        &:nth-child(4) {
          img {
          }
        }
      }
    }
  }
  .accordion {
    overflow-x: auto;
  }
}
@media screen and (min-width: 1600px) {
  .table-row-custom-padding {
    table {
      td {
        &:nth-child(4) {
          img {
            margin-left: 13vw !important;
          }
        }
      }
    }
  }
  .accordion {
    overflow-x: auto;
  }
}
@media all and (max-width: 992px) {
  .salary_datatabel {
    overflow-x: auto;
  }

  .invoice_tabel {
    overflow-x: auto !important;
  }
  .table-row-custom-padding {
    table {
      td {
        &:nth-child(4) {
          img {
            margin-left: 19vw !important;
          }
        }
      }
    }
  }
  .accordion {
    overflow-x: auto;
  }
  #cx-main #purchase-main .header-dropdown-main .dropdown-menu,
  #cx-main
    #expense-main
    .cx-content-section
    .left-section
    .left-top-heder-main
    .right
    .header-dropdown-main
    .dropdown-menu,
  #cx-main
    #income-main
    .cx-content-section
    .left-section
    .left-top-heder-main
    .right
    .header-dropdown-main
    .dropdown-menu {
    // inset: 0px auto auto 0px !important;
  }

  .disc-sign-main {
    div {
      padding: 0;
    }
  }
  .main-invoice-card
    .invoice-inner-main
    .disc-sign-main
    .left
    .Terms-of-Services {
    margin: 10px 0 !important;
  }
  #cx-main
    .cx-content-section
    .left-section
    .main-invoice-card
    .invoice-inner-main
    .disc-sign-main
    .left
    .Terms-of-Services {
    margin: 10px 0 !important;
  }

  .main-auth .login .login-form .left .form-control-div {
    margin-bottom: 27px !important;
  }

  .createAccount .create-ac-form .para-above-btn label {
    margin-bottom: 16px !important;
  }

  .marginbottom50 {
    margin-bottom: 50px !important;
  }
  .mainTimeModal {
    display: block !important;
    .dateTimeSection {
      label {
        padding-left: 0 !important;
        margin-bottom: 10px;
      }
    }
    .punchedHrs {
      div {
        width: 100%;
        div {
          display: flex;
          // flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
}

.ORsectioMain {
  display: none;
}

@media all and (max-width: 900px) {
  .login-main {
    display: flex;
    align-items: unset !important;

    .login-form {
      flex-direction: column;
      align-items: baseline !important;

      .left {
        // margin-bottom: 15px;
      }

      .wrapper {
        display: none;
      }

      .ORsectioMain {
        width: 100%;
        text-align: center;
        display: flex;
        // flex-direction: column;
        // align-items: center;
        z-index: 1;

        .ORsectionLine {
          border-bottom: 1px solid var(--backColorMainLightMode);
          width: 45%;
          position: relative;
          top: -32px;
          z-index: 2;
        }

        .ORsectionLine2 {
          border-bottom: 1px solid var(--backColorMainLightMode);
          display: flex;
          width: 50%;
          position: relative;
          top: -32px;
          right: px;
          z-index: 2;
        }

        .rspORsection {
          // padding: 10px;
        }
      }
    }

    .bottom-content {
      padding-bottom: 30px !important;
    }
  }
  .table-row-custom-padding {
    table {
      td {
        &:nth-child(4) {
          img {
            margin-left: 22vw !important;
          }
        }
      }
    }
  }
  #cx-main
    .cx-content-section
    .left-section
    .left-top-heder-main
    .right
    .header-dropdown-main
    .dropdown-menu,
  #cx-main
    .cx-content-section
    .left-section
    .left-top-heder-main
    .right
    .header-dropdown-main
    .dropdown-menu {
    // inset: -2px 2px auto auto !important;
  }
  .accordion {
    overflow-x: auto;
  }
}

/*================================================================================
    @media all and (max-width: 1024px) START Tab
  ================================================================================*/

@media all and (max-width: 939px) {
  .role-table-header {
    justify-content: space-between;
  }
  .accordion {
    overflow-x: auto;
  }
  .role-btns-wrapper {
    justify-content: unset !important;

    button {
      margin-left: 0px !important;
      margin-right: 20px;
    }
  }

  .role-btns-wrapper button:last-child {
    margin-right: 0;
  }

  .datemodel {
    // width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-left: 20px;

    div {
      width: 50%;
      padding: 0;
    }
  }
  .table-row-custom-padding {
    table {
      td {
        &:nth-child(4) {
          img {
            margin-left: 10vw !important;
          }
        }
      }
    }
  }
}

@media all and (max-width: 991px) {
  .insetlocation {
    inset: -7px auto auto -2px !important;
  }
  .accordion {
    overflow-x: auto;
  }
  .insetposition {
    inset: -7px auto auto -2px !important;
  }

  .main-invoice-card .invoice-inner-main .disc-sign-main {
    margin: 10px 15px 10px 15px !important;
  }
  .right-chat-section {
    width: 100% !important;
    margin-top: 20px;
  }
  .new-conversation-btn {
    display: flex;
    justify-content: end;
    top: 0 !important;
    button {
      position: initial !important;
      margin-top: 15px;
    }
  }
  .table-row-custom-padding {
    table {
      td {
        &:nth-child(4) {
          img {
            margin-left: 22vw !important;
          }
        }
      }
    }
  }
}

@media all and (max-width: 924px) {
  .role-btns-wrapper .up-img-sales {
    left: 10px;
  }
  .table-row-custom-padding {
    table {
      td {
        &:nth-child(4) {
          img {
            margin-left: 22vw !important;
          }
        }
      }
    }
  }
  .accordion {
    overflow-x: auto;
  }
}

/*================================================================================
    @media all and (max-width: 1024px) END Tab
  ================================================================================*/

/*================================================================================
    @media all and (max-width: 768px) START iPhone - 6
  ================================================================================*/

@media all and (max-width: 768px) {
  .createAccount .create-ac-form .para-above-btn {
    text-align: center;
  }
  .table-row-custom-padding {
    table {
      td {
        &:nth-child(4) {
          img {
            margin-left: 28vw !important;
          }
        }
      }
    }
  }
  .accordion {
    overflow-x: auto;
  }
  #cx-main #main-template-dropDown .dropdown-menu {
    inset: 0px auto auto -231px !important;
    min-width: 260px !important;
    width: 260px !important;
    transform: unset !important;
    top: 43px !important;
    right: 14px !important;
  }
  .cx-header .cx-header-wrapper {
    margin-left: unset !important;
  }

  #cx-main {
    margin-left: 65px !important;
    padding: 75px 15px 15px 15px !important;
  }

  .RspSalary,
  .role-searchbar-and-btns {
    display: block !important;
  }

  .role-btns-wrapper .up-img-sales {
    left: 10px;
    right: 0 !important;
  }

  .invoice_tabel {
    overflow-x: auto !important;

    table {
      width: 1000px;
    }
  }

  #cx-main #profile-dropdown-main .dropdown-menu {
    width: 359px !important;
    // inset: -6px auto auto -336px !important;
  }

  #cx-main
    .cx-content-section
    .left-section
    .main-invoice-card
    .invoice-inner-main
    .detail-table-main
    table
    tbody
    td
    .select-table
    .dropdown-menu {
    // inset: -110px auto auto 102px !important;
  }

  .left-top-logo {
    text-align: center;
    margin-bottom: 20px;
  }
}

/*================================================================================
    @media all and (max-width: 768px) END iPhone - 6
  ================================================================================*/

/*================================================================================
    @media all and (max-width: 667px) START iPhone - 6
  ================================================================================*/

@media all and (max-width: 667px) {
  .salary_datatabel {
    width: 100%;
    overflow-x: auto;
  }

  .sales-home-modal .modal-content {
    width: 100% !important;
  }
  .accordion {
    overflow-x: auto;
  }
  .main-invoice-card .invoice-inner-main .detail-table-main {
    // overflow-x: auto;
  }
  .top-logo {
    text-align: center;
    margin-left: 0 !important;
  }
}

.detail-tab-con {
  overflow-x: auto;
}

/*================================================================================
    @media all and (max-width: 667px) END iPhone - 6
  ================================================================================*/

@media all and (max-width: 475px) {
  #cx-main
    #purchase-edit-invoice
    .invoice-expense-top
    .purchase-inv
    .pur-small-screen,
  #cx-main
    #expense-edit-invoice
    .invoice-expense-top
    .purchase-inv
    .pur-small-screen,
  .pur-small-screen {
    width: 100%;
  }
  .accordion {
    overflow-x: auto;
  }
  #cx-main #purchase-edit-invoice .invoice-expense-top .cust-expense-inp,
  #cx-main #expense-edit-invoice .invoice-expense-top .cust-expense-inp,
  #income-edit-invoice .invoice-expense-top .cust-expense-inp {
    width: 97% !important;
  }

  .linnetext {
    margin-left: 30px !important;
  }

  .sub-header-role {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    display: flex !important;
    align-items: center !important;
  }

  .create-btn-sub-header {
    height: 25px !important;
  }
}

/*================================================================================
    @media all and (max-width: 600px) START Tab
  ================================================================================*/

@media all and (max-width: 600px) {
  .main-invoice-card
    .invoice-inner-main
    .disc-sign-main
    .left
    .Terms-of-Services {
    height: auto !important;
  }
  .table-row-custom-padding {
    table {
      td {
        &:nth-child(4) {
          img {
            margin-left: 40vw !important;
          }
        }
      }
    }
  }
  .accordion {
    overflow-x: auto;
  }
  #cx-main .cx-content-section .left-section .left-top-heder-main .left label,
  #cx-main .cx-content-section .left-section .left-top-heder-main .left span {
    font-size: 12px !important;
  }
  #cx-main
    .cx-content-section
    .left-section
    .main-invoice-card
    .invoice-inner-main
    .disc-sign-main
    .left
    .Terms-of-Services {
    height: auto !important;
  }
  #cx-main
    .cx-content-section
    .left-section
    .main-invoice-card
    .invoice-inner-main
    .name-details-main
    .name-details-inner
    .left
    .PON_No,
  #cx-main
    .cx-content-section
    .left-section
    .main-invoice-card
    .invoice-inner-main
    .name-details-main
    .name-details-inner
    .right
    .PON_No {
    width: 100% !important;
  }
  .reportSaleHEader {
    display: block !important;
  }

  .salasReport_topList li:last-child {
    padding-right: 0;
  }

  .marginttop4 {
    margin-top: 20px !important;
  }

  .place-heading {
    margin-top: 10px !important;
  }

  .detail-table-main {
    // overflow-x: auto;

    table {
      width: 800px !important;
    }
  }

  .detail-tab-con {
    overflow-x: auto;
  }

  .text-invoice-inner,
  .name-details-inner {
    flex-direction: column;
    margin: 10px !important;
    justify-content: center;
  }

  .name-details-inner .left,
  .name-details-inner .right {
    width: 100% !important;
  }

  .text-invoice-inner .left,
  .text-invoice-inner .right {
    width: 100% !important;
  }

  .text-invoice-inner .left label {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .logo-top-left {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .coninversereponsive {
    flex-direction: column-reverse;

    .right {
      margin-bottom: 15px;
      text-align: center;
    }
  }
}

/*================================================================================
    @media all and (max-width: 600px) END Tab
  ================================================================================*/

@media all and (max-width: 575px) {
  .modal.show .modal-dialog {
    margin: 30px;
  }
  .accordion {
    overflow-x: auto;
  }
  #cx-main .cx-content-section .main-row {
    margin-top: 2px !important;
  }
  .table-row-custom-padding {
    table {
      td {
        &:nth-child(4) {
          img {
            margin-left: 40vw !important;
          }
        }
      }
    }
  }
}

/*================================================================================
    @media all and (max-width: 568px) START iPhone - 5
  ================================================================================*/

@media all and (max-width: 568px) {
  .create-ac-form,
  .left-top-logo {
    margin: 0 20px;
  }
  .accordion {
    overflow-x: auto;
  }
  .role-table-header {
    flex-direction: column;

    .entries-wrapper {
      margin-left: 0 !important;
    }

    .search-input-wrapper {
      input {
        width: 100%;
      }

      img {
        left: auto;
        right: 12px;
      }
    }

    .entries-wrapper {
      // justify-content: right;
    }
  }

  .role-btns-wrapper {
    justify-content: right !important;
    position: relative;
    bottom: 60px;
    margin-left: 189px;

    button {
      margin-right: 10px;
    }
  }

  .RspSalary .role-btns-wrapper button:last-child {
    margin-right: 0;
  }

  .table-wrapper {
    // margin-top: -46px !important;
  }

  .role-btns-wrapper .up-img-sales {
    right: 10px !important;
    left: auto;
  }

  .invoice_upper_section div {
    margin-bottom: 10px;
    // padding: 10px 5px;
    width: 90%;
    margin-left: 5%;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
  }
  .role-btns-wrapper .up-img-sales {
    right: 10px !important;
  }
  .Custom-tabs-rable {
    flex-direction: row;
  }
  .responsiveEmpolydatda {
    display: flex;
    justify-content: space-between;
  }
  .create-btn-sub-header {
    width: 120px !important;
    height: 32px !important;
    font-size: 12px !important;
  }
  .search-input-wrapper,
  .entries-wrapper {
    margin-top: 10px !important;
  }
  .accoridance {
    overflow-x: hidden;
    .allAcordiance,
    .myAccordiance {
      a {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
  }
  .table-row-custom-padding {
    table {
      td {
        &:nth-child(4) {
          img {
            margin-left: 50vw !important;
          }
        }
      }
    }
  }
}

/*================================================================================
    @media all and (max-width: 568px) END iPhone - 5
  ================================================================================*/

@media all and (max-width: 550px) {
  .datemodel {
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;

    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      width: 100%;

      input {
        width: 80%;
      }
    }
  }
  .accordion {
    overflow-x: auto;
  }

  .companyModelMain {
    p {
      margin: 10px;
    }
  }
  .table-row-custom-padding {
    table {
      td {
        &:nth-child(4) {
          img {
            margin-left: 44vw !important;
          }
        }
      }
    }
  }
}

@media all and (max-width: 485px) {
  .form-switch .switch-blueBtnHeader {
    height: 24px !important;
    width: 50px !important;
  }
  .accordion {
    overflow-x: auto;
  }
  .print-modal-box-main .modal-content .option-btns .form-check-input {
    height: 14px !important;
    width: 14px !important;
  }
  .print-modal-box-main .modal-content .option-btns .form-check-label {
    font-size: 14px !important;
  }
  #cx-main #main-template-dropDown .dropdown-menu {
    right: 0px !important;
  }
  .salasReport_topList {
    // width:auto !important;
    justify-content: unset !important;
    width: 100% !important;

    li {
      padding-right: 10px !important;
      border: none !important;
    }
  }

  .invoice_tabel {
    overflow-x: auto !important;
  }

  .model_tabel {
    tr {
      td {
        font-size: 10px;
      }

      th {
        font-size: 12px;
      }
    }
  }

  .cx-header .cx-header-wrapper {
    height: 45px !important;
  }

  .bell img {
    width: 13px !important;
  }

  .btn-header .greenBtnHeader,
  .btn-header .blueBtnHeader {
    height: 24px !important;
    font-size: 12px !important;
  }

  .profile-header .pic {
    width: 22px !important;
    height: 22px !important;
  }

  #cx-main .cx-inneer-header {
    height: 40px !important;
  }

  .profile-header .add-green {
    right: -4px !important;
    bottom: -8px !important;
  }

  #cx-main .cx-inneer-header .main-inner .right a button {
    padding: 3px 15px !important;
  }

  #cx-main
    .cx-content-section
    .left-section
    .left-top-heder-main
    .right
    .header-green-icons,
  #cx-main
    .cx-content-section
    .left-section
    .left-top-heder-main
    .right
    .img-profile
    img {
    height: 20px !important;
    width: 20px !important;
    margin-right: 3px !important;
  }

  .seprator-line {
    margin-right: 3px !important;
    margin-top: 0px !important;
  }

  #cx-main
    .cx-content-section
    .left-section
    .left-top-heder-main
    .right
    .header-dropdown-main
    .btn {
    height: 23px !important;
  }

  #cx-main
    #purchase-edit-invoice
    .invoice-expense-top
    .react-datepicker-wrapper {
    width: 100% !important;
  }

  .daterespomsive {
    width: 50%;
  }

  .modalmarginleft05 {
    margin-left: 0.5rem !important;
  }

  .calendarI {
    right: 26px !important;
  }

  .overflowslarytable {
    overflow-x: auto;

    table {
      width: 400px !important;
    }
  }
}

/*================================================================================
    @media all and (max-width: 480px) START iPhone - 3 + 4
  ================================================================================*/
@media all and (max-width: 480px) {
  .role-btns-wrapper {
    bottom: 50px;
  }
  .rbc-date-cell {
    padding: 12px;
  }
  .mainFormSection label {
    margin-right: 34px;
  }
  .mb-4 {
    margin-bottom: 0.5rem !important;
  }
  .mainTimeModal .dateTimeSection label {
    margin-bottom: 0px !important;
  }
  .p-inputtext {
    height: 28px !important;
    font-size: 12px;
    width: 234px !important;
  }
  .accordion {
    overflow-x: auto;
  }
  .role-btns-wrapper button {
    width: 30px !important;
    height: 30px !important;
  }

  .entries-wrapper select {
    height: 30px !important;
    margin-left: 4px !important;
  }

  .createAccount .create-ac-form .top-heading {
    margin: 25px 0 !important;
  }

  .main-inner .left label {
    font-size: 12px !important;
  }

  .main-inner .right a button {
    font-size: 12px !important;
  }

  .cx-header .cx-header-wrapper {
    padding: 0 21px !important;
  }
  #cx-main {
    // margin-left: 60px !important;
    padding: 60px 15px 15px 15px !important;
  }
  h6 {
    font-size: 13px !important;
  }
  .sub-header-role {
    margin-bottom: 5px !important;
  }
  .mainIcons {
    margin-top: 5px !important;
    div {
      width: 50% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        position: inherit !important;
        bottom: inherit !important;
        right: inherit !important;
      }
    }
  }
  .custom-mb {
    margin-bottom: 5px !important;
  }
  #cx-main .table-wrapper-main {
    margin-top: 5px !important;
  }
  .mainHeader,
  .mainHeader2,
  .empolyDetails {
    margin-bottom: 10px !important;
  }
  .responsiveEmpolydatda {
    display: block;
    // justify-content: space-between;
  }
}

/*================================================================================
    @media all and (max-width: 480px) END iPhone - 3 + 4
  ================================================================================*/

@media all and (max-width: 480px) {
  .login-main {
    .login-form {
      .left {
        width: 288px !important;
      }

      .right {
        .email {
          a {
            width: 288px !important;
          }
        }
      }
    }
  }
  .accordion {
    overflow-x: auto;
  }

  .table-row-custom-padding {
    table {
      td {
        &:nth-child(4) {
          img {
            margin-left: 51vw !important;
          }
        }
      }
    }
  }
}

/*================================================================================
    @media all and (max-width: 375px) START iPhone - 6
  ================================================================================*/

@media all and (max-width: 385px) {
  .role-btns-wrapper button {
    margin-right: 2px;
  }
  .accordion {
    overflow-x: auto;
  }
  #cx-main #profile-dropdown-main .dropdown-menu {
    width: 280px !important;
  }

  .overflowxauto {
    overflow-x: auto;
  }

  #cx-main .cx-content-section .left-section .main-invoice-card {
    padding: 10px !important;
  }

  .header-green-icons {
    margin-right: 5px !important;
  }

  .table-main {
    overflow-x: auto;
  }

  #cx-main .cx-content-section .left-section .left-top-heder-main .left label,
  .porfileLabel {
    font-size: 12px !important;

    span {
      font-size: 12px !important;
    }
  }

  .input-otp {
    input {
      // height: 50px !important;
      margin-top: 20px !important;
      width: 50px !important;
    }
  }

  .createAccount .otp-section-main .text {
    margin: 0 20px !important;
  }

  .changePassPaddin {
    .form-control-div {
      .pass-main {
        // margin: 0 20px !important;
      }
    }
  }
  .table-row-custom-padding {
    table {
      td {
        &:nth-child(4) {
          img {
            margin-left: 100vw !important;
          }
        }
      }
    }
  }
}

@media all and (max-width: 350px) {
  .datemodalbotton {
    div {
      display: flex;
      flex-direction: column;
      width: 100%;

      button {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  .accordion {
    overflow-x: auto;
  }

  .companyModelMain {
    select {
      width: 70% !important;
    }
  }

  .salasReport_topList {
    width: 100% !important;
  }
  .mainTimeModal {
    .punchedHrs {
      div {
        div {
          display: block !important;
        }
      }
    }
  }
  .table-row-custom-padding {
    table {
      td {
        &:nth-child(4) {
          img {
            margin-left: 55vw !important;
          }
        }
      }
    }
  }
}

.drawer-open #cx-main {
  margin-left: 250px;
  transition: margin-left 0.3s ease-in;
}

.drawer-closed #cx-main {
  margin-left: 70px;
  transition: margin-left 0.3s ease-out;
}
.sub-header-role {
  background-color: white;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */

  h6 {
    font-size: 18px;
    font-weight: 600;
    color: var(--text);
  }
}

.table-wrapper-Admin-Admin {
  cursor: pointer;
  display: block;
  overflow-x: auto;
  /* white-space: nowrap; */
  -moz-border-radius-topright: 10px !important;
  -moz-border-radius-bottomleft: 10px !important;
  height: calc(100vh - 250px);
  margin-top: 15px;
  overflow-x: auto;
  overflow-y: scroll;
  padding-right: 10px;
  /* scrollbar-width: thin;
  scrollbar-color: #53b7e8 #f1f1f1; */

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
    -moz-border-radius-topright: 10px !important;
    -moz-border-radius-bottomleft: 10px !important;
  }

  th {
    text-align: start !important;
    padding: 10px;
    color: #000 !important;
    font-size: 0.75rem;
    font-weight: 700;
    background-color: #fff;
    /* border: none; */
    position: sticky !important;
    top: 0;
    white-space: nowrap;
  }

  td {
    text-align: start !important;
    padding: 9px;
    font-size: 0.75rem;
    font-weight: 200;
    color: black;
    height: 2.5rem !important;
    /* border: none; */
  }

  tr th:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    -moz-border-radius-topright: 10px !important;
    -moz-border-radius-bottomleft: 10px !important;
    padding-left: 2rem !important;
  }

  tr th:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    -moz-border-radius-topright: 10px !important;
    -moz-border-radius-bottomleft: 10px !important;
    padding-right: 2rem;
  }
  tr td:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    -moz-border-radius-topright: 10px !important;
    -moz-border-radius-bottomleft: 10px !important;
    padding-left: 2rem !important;
  }

  tr td:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    -moz-border-radius-topright: 10px !important;
    -moz-border-radius-bottomleft: 10px !important;
  }
}

.table-wrapper-Admin::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.table-wrapper-Admin::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-wrapper-Admin::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 10px;
  border: none;
}

.table-common th {
  background-color: #fff;
}
.firstTabelRow th:first-child {
  padding-left: 2rem !important;
}

.firstTabelRow {
  th {
    border-radius: 0;
    font-weight: 700 !important;
    font-size: 13px;
    font-weight: 700;
    color: #000 !important;
    padding: 15px 15px;
  }
}

.table-row-custom {
  background-color: white;
  border-radius: 10px;

  span {
    /* margin-left: 5px; */
    /* padding: 4px 10px; */
    border-radius: 14px;
    font-weight: 400;
    /* color: #939292; */
  }

  vertical-align: baseline;
}

.table-row-custom {
  td:nth-child(1) {
    text-align: center;
  }
  td {
    color: grey;
    font-size: 12px;
    font-weight: 400;
    padding: 7px 17px;
    /* text-align: center; */
    background-color: #fff;
    /* position: sticky; */
    /* top: 0px; */
    color: #000 !important;
    vertical-align: top;
  }
}
.arrowStyl {
  width: 30px;
  height: 30px;
}

.drawrModules {
  background-color: white;
  padding: 13px 10px 10px 10px;
  border-radius: 7px;
  margin: 0px 10px;
  height: calc(100vh - 108px);
  overflow-y: scroll;
  scrollbar-width: none;
  .hover-on-user {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 15px;
    letter-spacing: 1px;
  }
  /* .hover-on-user:hover {
    background-color: rgb(131, 131, 131);
    color: white;
  } */
  .selected {
    background-color: #53b7e8;
    color: white;
    font-weight: 600;
  }
}
.chatHdg {
  cursor: pointer;
  /* text-align: center; */
  font-weight: 600;
  margin: 5px 0 15px;
  .d-flex:hover {
    color: #53b7e8;
  }
  p {
    margin-left: 8px;
  }
}

.searchBarStyl {
  width: 100%;
  padding: 3px 35px 3px 10px;
  border-radius: 50px;
  border: 1px solid #ccc;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: 500;
}
.searchBarStyl:focus {
  outline: none;
}

.searchBarIconStyl {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
}
.iconHW {
  width: 40px;
  height: 40px;
  background-color: #ddeaf3;
  padding: 10px;
  border-radius: 50px;
}

.editorHW .ql-editor {
  min-height: 150px;
  background-color: white;
}
.table-wrapper-3 {
  .act-deac {
    text-align: end !important;
  }
}
.table-wrapper-3 .move-right {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}

body {
  // background-color: #ddeaf3;
}

.main-content-container {
  margin-left: 254px;
  padding: 20px;
  height: calc(100vh - 67px);
  overflow: auto;
  transition: all 0.5s;
  // scrollbar-width: none !important;
}

.main-content-container-after-shrink {
  margin-left: 77px;
  padding: 20px;
  height: 90vh;
  overflow: auto;
  transition: all 0.5s;
}

.background-body-wrapper {
  // background-color: #DDEAF3;
  // height: calc(100vh - 40px);
  // height: 80vh;
  padding: 20px;
  overflow: auto;
}

.background_main_wrapper {
  background-color: #ddeaf3;
}

// ::-webkit-scrollbar {
//   width: 0 !important;
//   height: 0 !important;
// }
.role-text {
  font-weight: 600;
  cursor: pointer;
}

.sub-header-role {
  background-color: var(--white_FFFFFF_LightMode);
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  // flex-wrap: wrap;
}

.create-btn-sub-header {
  border: none;

  border-radius: 18px;
  color: #53b7e8;
  font-size: 0.8rem;
  width: 84px;
  height: 35px;
  font-weight: 500;
}

.label-text {
  color: #828282;
  cursor: pointer;
  margin-bottom: 5px;
}

// .import_success_btn {
//   border: none;
//   background-color: #5ddf8f;
//   border-radius: 18px;
//   color: #fff;
//   font-size: 0.8rem;
//   // width: 100px;
//   padding: 20px;
//   height: 35px;
//   font-weight: 500;
// }

// .create-btn-sub-header::before{
//   content: "";
//   width: 9px;
//   height: 4px;
//   color: red;
//   border-left: 2px solid red;
// }
.request-to-register-btn {
  border: none;
  background-color: rgba(83, 183, 232, 0.1);
  border-radius: 18px;
  color: #53b7e8;
  font-size: 0.8rem;
  width: 169px;
  height: 35px;
  font-weight: 500;
  margin-left: 10px;
}

.role-content {
}

.search-input-wrapper {
  position: relative;
  // margin-top: 20px;

  img {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }

  input {
    width: 250px;
    height: 40px;
    border-radius: 10px;
    border: none;
    padding: 12px;
    font-size: 0.8rem;
    padding-right: 35px;
  }
}

.search-input-wrapper :focus {
  outline: none;
}

.entries-wrapper {
  display: flex;
  margin-top: 20px;
  margin-left: 20px;

  p {
    margin-bottom: 0;
    font-size: 0.8rem;
    color: rgba(17, 17, 17, 0.5);
    display: flex;
    align-items: center;
  }

  select {
    width: 100%;
    height: 40px;
    font-size: 0.8rem;
    color: rgba(17, 17, 17, 0.5);
    margin-left: 15px;
    border: 1px solid rgba(17, 17, 17, 0.5);
    cursor: pointer;
  }
}

.client-wrapper {
  display: flex;
  margin-top: 20px;
  margin-left: 20px;

  p {
    margin-bottom: 0;
    font-size: 0.8rem;
    color: rgba(17, 17, 17, 0.5);
    display: flex;
    align-items: center;
  }

  select {
    width: 125px;
    height: 40px;
    font-size: 0.8rem;
    color: rgba(17, 17, 17, 0.5);
    margin-left: 15px;
    border: 1px solid rgba(17, 17, 17, 0.5);
    cursor: pointer;
  }
}

.select-drop-icon {
  background-image: url("../images/drop_icon.svg") !important;
  background-size: 9px 12px !important;
  // border: 1px solid rgb(169 164 164 / 50%);
  border: 1px solid rgba(17, 17, 17, 0.5);
  color: #828282;
}

.role-table-header {
  display: flex;
}

.role-btns-wrapper {
  margin-top: 20px;

  button {
    width: 40px;
    height: 40px;
    border: none;
    color: #53b7e8;
    background-color: white;
    border-radius: 10px;
    margin-left: 20px;
  }
}

.import-icon-img {
  // margin-right: 20px !important;
}

.paymentreciapleMain {
  width: 80%;
}

.plus-icon {
  width: 84px;
  height: 40px;
  background-color: white;
  color: #53b7e8;
  border: none;
  font-size: 14px;
  border-radius: 10px;
  vertical-align: middle;
  margin-top: 20px;
}

.sub-header-span {
  color: #b1a9a9;
  margin-right: 20px;
  cursor: pointer;
  font-weight: 500;
}

.sub-header-span-active {
  color: #53b7e8;
  margin-right: 20px;
  cursor: pointer;
  font-weight: 500;
}

.sub-header-span-active-1 {
  color: #53b7e8;
  // margin-right: 20px;
  cursor: pointer;
  font-weight: 500;
}

.span-wrapper-sub-header {
  margin-top: 4.5px;
}

.role-searchbar-and-btns {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.table-wrapper {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  -moz-border-radius-topright: 10px !important;
  -moz-border-radius-bottomleft: 10px !important;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
    -moz-border-radius-topright: 10px !important;
    -moz-border-radius-bottomleft: 10px !important;
  }

  // tr:last-child{
  //   text-align: center;
  // }

  td {
    //chge
    // text-align: center;
    text-align: start !important;
    padding: 12px;
    font-size: 0.75rem;
    font-weight: 200;
    color: var(--black_000000_LightMode);
    height: 2.5rem !important;
  }

  th {
    text-align: start !important;
    // text-align: center;
    padding: 12px;
    color: #000 !important;
    font-size: 0.75rem;
    font-weight: 700;
  }

  th:first-child {
    // padding-left: 2rem;
    // width: 19%;
  }

  th:nth-child(2) {
    // padding-left: 2rem;
    // width: 28%;
  }

  th:nth-child(3) {
    // padding-left: 2rem;
    // width: 35%;
  }

  th:nth-child(4) {
    // padding-left: 0rem;
    // width: 20%;
  }

  // td:first-child,
  // th:first-child {
  //   border-radius: 10px 0 0 10px;
  //   padding: 12px;

  // }
  tr td:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    -moz-border-radius-topright: 10px !important;
    -moz-border-radius-bottomleft: 10px !important;
    padding-left: 2rem !important;
  }

  tr td:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    -moz-border-radius-topright: 10px !important;
    -moz-border-radius-bottomleft: 10px !important;
  }
}

.table-row-custom {
  background-color: white;
  border-radius: 10px;
}

.green-active {
  color: #1cd63a;
}

.red-deactive {
  color: #dc1818;
}

.yellow-active {
  color: #ffe600;
}

.create-role-text {
  font-size: 1.12rem;
  color: #111111;
  font-weight: 600;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  // padding: var(--bs-modal-header-padding);
  border-bottom: none !important;
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  // padding-top: 15px;
  // padding-left: 15px;
  // padding-right: 15px;
  padding: 20px;
}

.hr-line-modal {
  // padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  color: #828282;

  // padding: 15px;
  // border-bottom: 1px solid red;
}

.hr-line-modal-1 {
  padding-left: 20px;
  padding-right: 20px;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  // padding: var(--bs-modal-padding);
  // padding: 10px 20px 17px 10px;
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  padding: 3px 10px 0px;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: none;
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.enter-role-text {
  font-size: 0.8rem;
  color: #111111;
}
.select-drop-icon {
  background-size: 0 !important;
  // margin-top: 8px;
}

.modal-cancel-btn {
  width: 140px;
  height: 40px;
  background: none;
  color: #53b7e8;
  border: 1px solid #53b7e8;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 0.9rem;
}

.branchDeleteButton {
  display: flex;
  padding: 20px;
}

.sm-btn-delete {
  // width: calc(50% - 5px) !important;
}

.modal-create-btn {
  width: 140px;
  height: 40px;
  background-color: #53b7e8;
  color: white;
  border-radius: 5px;
  border: none;
  font-size: 0.9rem;
}

.form-select:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

//   WorkFlow css  usama

.create-workflow-topbody,
.create-workflow-middel {
  // border-bottom: 1px solid rgba(17, 17, 17, 0.2);
  // padding: 10px !important;
}

.create-workflow-step1 {
  font-size: 18px;
  font-weight: 600;
  color: #111111;
}

// stepper

.strvt-container {
  // width: 200px;
}

.strvt-step {
  padding: 10px;
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  background-color: cream;
}

.strvt-v-stepper {
  position: relative;
  /*   visibility: visible; */
}

/* regular step */
.strvt-step .strvt-circle {
  background-color: white;
  border: 3px solid gray;
  border-radius: 100%;
  width: 20px;
  /* +6 for border */
  height: 20px;
  display: inline-block;
}

.strvt-step .strvt-line {
  top: 23px;
  left: 9px;
  /*   height: 120px; */
  height: 100%;

  position: absolute;
  border-left: 3px dashed gray;
}

.option-text {
  color: lightgrey;
}

.strvt-step.strvt-completed .strvt-circle {
  visibility: visible;
  background-color: rgb(6, 150, 215);
  border-color: rgb(6, 150, 215);
  color: white;
  font-size: 14px;
}

.strvt-step.strvt-completed .strvt-line {
  border-left: 3px dashed rgb(6, 150, 215);
}

.strvt-step.strvt-active .strvt-circle {
  visibility: visible;
  border-color: rgb(6, 150, 215);
}

.strvt-step.strvt-empty .strvt-circle {
  visibility: hidden;
}

.strvt-step.strvt-empty .strvt-line {
  /*     visibility: hidden; */
  /*   height: 150%; */
  top: 0;
  height: 150%;
}

.strvt-step:last-child .strvt-line {
  border-left: 3px solid white;
  z-index: -1;
  /* behind the circle to completely hide */
}

#widthauto {
  width: 180px;
}

.strvt-content {
  margin-left: 20px;
  display: inline-block;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/* codepen override */
// html * {
//   font-size: 15px !important;
//   color: #000 !important;
//   font-family: Arial !important;
// }

.middbtn {
  color: white;
  width: auto;
}

.wfd-row {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  background-color: white;
  padding: 10px;
}

.wfd-row2 {
  border-radius: 10px;
}

.secondsft {
  // margin: 10px;
  .wfd-row {
    border-radius: none !important;
  }
}

.workflow-details-col {
  .wfd-label {
    color: rgba(17, 17, 17, 0.5) !important;
    font-size: 16px;
    line-height: 24px;
    line-height: 100%;
  }

  p {
    font-size: 1rem;
    margin-bottom: 0;
  }
}

.pri-mainbody {
  padding: 0px;
}

// .accor-main {
//   // background-color: rgba(83, 183, 232, 0.05) !important;
//   padding: 10px;
// }

// .accordion {
//   width: 100%;
//   // border: 7px solid white;
// }

// .accordion-item {
//   background-color: rgba(83, 183, 232, 0.05) !important;
//   border: none;
//   margin: 0;
// }

// .accordion-button {
//   border-radius: 0 !important;
//   // padding: 10px;
//   background-color: rgba(83, 183, 232, 0.05) !important;
//   margin-bottom: 10px;
// }

// .accordion-button:focus {
//   border: none !important;
//   background-color: rgba(83, 183, 232, 0.05) !important;
//   border-radius: 0 !important;
//   padding: 10px;
//   box-shadow: none;
//   outline: none !important;
//   border-bottom: 1px solid rgba(17, 17, 17, 0.2);
// }

.modelhead {
  width: 100%;
}

///////////////////  Service group style ////////////////////////////////

.service-group-popup:first-child {
  background-color: white;
}

// .create-workflow-main {
//   padding: 50px !important;
// }

.remove-border {
  border: none !important;
}

.steps-modal-wrapper {
  display: flex;
  justify-content: space-between;

  // background: rgba(83, 183, 232, 0.05);
  // background-color: red;
  // padding-right: 10px;
  // padding-top: 10px;
  li {
    list-style: none;
    align-items: center;
    padding: 0;
    margin-left: -31px;
  }
}

.steps-main-background {
  background: rgba(83, 183, 232, 0.05);
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  // padding-bottom: 10px;
  border-radius: 10px;
  margin-top: 20px;
  padding-bottom: 20px;
}

.circle-deropdown {
  transform: rotate(180deg);
  height: 28px;
}

.step-1-card {
  background: #ffffff;
  // background-color: #1cd63a;
  border-radius: 10px;
  padding: 20px;

  label {
    color: rgba(17, 17, 17, 0.5);
    font-size: 0.9rem;
  }

  p {
    font-size: 1rem;
    color: #111111;
  }
}

.field_btn {
  background: #53b7e8;
  border-radius: 5px;
  width: 160px;
  height: 40px;
  border: none;
  color: white;
  font-size: 1rem;
  margin-right: 20px;
}

.action_btn {
  border-radius: 5px;
  width: 160px;
  height: 40px;
  border: 1px solid #53b7e8;
  color: #53b7e8;
  font-size: 1rem;
  background: none;
}

.permission-content-wrapper {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px 25px;
}

.permission-heading {
  display: flex;
  align-items: center;
  font-weight: 600;

  label {
    cursor: pointer;
  }
}

.checkbox-wrapper {
  margin-top: 20px;
  display: flex;
  align-items: baseline;

  label {
    cursor: pointer;
  }
}

.save-btn-permission {
  width: 120px;
  height: 40px;
  background: #53b7e8;
  border-radius: 5px;
  color: white;
  border: none;
}

.multi-select-input-field {
  width: 100% !important;
  border-color: #11111180 !important;
}

.multi-select-input-field:focus {
  outline: none !important;
}

.msl-chip {
  background: #53b7e8 !important;
  border-radius: 30px !important;
  padding: 3px 3px 3px 6px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  line-break: anywhere;
  white-space: break-spaces;
  color: white !important;
  font-size: 0.8rem !important;
}

.msl-chip-delete {
  min-width: 20px;
  min-height: 20px;
  margin-left: 3px;
  background: none !important;
  color: white !important;
  // width: 100px !important;
  // height: 30px !important;
}

.msl-close-icn {
  height: 8px !important;
  width: 10px;
}

.msl:not(.msl-active, .msl-active-up):hover {
  border-color: #ced4da !important;
}

.msl {
  overflow: hidden;
  border: 1px solid #ced4da !important;
  border-radius: var(--border-radius);
  outline: none;
  cursor: text;
  transition: border-radius 0.2s;
  cursor: pointer !important;
}

input {
  // cursor: pointer;

  input[type="checkbox"]:checked {
    caret-color: #53b7e8 !important;
  }
}

.msl-active {
  background: var(--active-menu-background);
  box-shadow: none !important;
  z-index: 9999;
  border-color: transparent;
  border-radius: var(--active-menu-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
  transition: border-top-left-radius 0.4s, border-top-right-radius 0.4s;
}

.back-slash {
  color: rgba(17, 17, 17, 0.5);
  font-size: 14px;
}

.bread-cumb-color {
  color: rgba(17, 17, 17, 0.5);
}

.task-list-wrapper {
  background-color: white;
  padding: 12px;
  border-radius: 10px;
}

.hold-btn {
  border: none;
  color: white !important;
  font-size: 0.7rem !important;
  background: #1cd63a;
  border-radius: 5px;
  width: 75px;
  height: 25px;
}

.holded-btn {
  border: none;
  color: white !important;
  font-size: 0.7rem !important;
  background: #dc1818;
  border-radius: 5px;
  width: 75px;
  height: 25px;
  margin-left: 10px;
}

.allocate-color {
  color: #1cd63a;
}

.re-allocate-color {
  color: #53b7e8;
}

.paddingFirstRow {
  padding-left: 25px !important;
}

.branchlistFlex {
  display: flex;
  justify-content: space-around;
}

/////// Arbaz media query  ///////
///
.width75 {
  margin-left: 252px !important;
  // float: right;
  transition: all 0.5s;
  padding: 20px !important;
}

.width100 {
  margin-left: 77px !important;
  // float: right;
  transition: all 0.5s;
  padding: 20px !important;
}

#model-footer-arbaz-responsive {
  border: 1px solid khaki;
}

.tooltip.show {
  z-index: 100;
}

// @media all and (max-width:530px){
//   .shiftManagment-sub-header-role-arbaz-responsive{
//     flex-direction: column !important;
//   }
// }

// @media all and (max-width:480px){

// .client-wrapper {
// margin-left: 0;
// }
//   .ntries-wrapper-entries-arbaz-responsive{
//     width: 100%;

//   }

//   .ntries-wrapper-entries-arbaz-responsive select{
//     width: 75% !important;

//   }

//   .client-wrapper-client-arbaz-responsive{
//     display: flex;
//     width: 100%;
//     margin-top: 20px;
//     margin-left: 0px;
//   }

//   .client-wrapper-client-arbaz-responsive select{
//     width: 75%;
//     height: 40px;
//     font-size: 0.8rem;
//     color: rgba(17, 17, 17, 0.5);
//     margin-left: 40px;
//     border: 1px solid rgba(17, 17, 17, 0.5);
//     cursor: pointer;
//   }

//   .model-footer-arbaz-responsive{
//     width: 100% ;

//   }

.Bottom-btn-arbaz-responsive {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
}

//   .branchList-Bottom-btn-arbaz-responsive{
//     width: 100%;
//     justify-content: space-between;
//     margin: 0;
//   }

//   .branchList-modal-cancel-btn-arbaz-responsive{
//     width: 100% !important;
//     height: 33px;
// margin: 7px 0;
//   }

// }
//   .roundcheck{
//     border-radius: 50px !important;
//   }

// @media all and (max-width:430px){
//   .shiftManagment-sub-header-role-arbaz-responsive{
//     flex-direction: column !important;
//   }

//   .role-text-SM-arbaz-responsive{

//     font-size: 13px;
//     display: inline-flex;
//     padding: 3px;
//   }

// }

// @media all and (max-width:414px){
//   .responsive-arbaz{
//     width: 251px !important;

//   }

// }

// @media all and (max-width:400px){
//   .sub-header-role-arbaz-responsive{
//     display: block;
//   }
// }

// @media all and (max-width:390px){
//   .responsive-arbaz{
//     width: 228px !important;

//   }
// }
// @media all and (max-width:375px){
//     .responsive-arbaz{
//       width: 212px !important;

//     }
// }

// @media all and (max-width:360px){
//   .responsive-arbaz{
//     width: 200px !important;

//   }

//   .role-text-SM-arbaz-responsive{
//     font-weight: 600;
//     cursor: pointer;
//     display: inline-flex;
//     margin: 3px;
//     font-size: 12px;
//   }

// }

// @media all and (max-width:356px){

//   .client-wrapper-service-arbaz-responsive {

//     display :  flex;
//     width: 100%;
//     margin-top: 20px;
//     margin-left: 20px;
//   }

//   .client-wrapper-service-arbaz-responsive select{
//     width: 100%;
//     height: 40px;
//     font-size: 0.8rem;
//     color: rgba(17, 17, 17, 0.5);
//     margin-left: 15px;
//     // border: 1px solid rgba(17, 17, 17, 0.5);
//     cursor: pointer;
//   }
// }

// @media all and (max-width:350px){
//   .userList-sub-header-role-arbaz-responsive{
//     display: block;
//   }

// }

// ///////   min:widhth to max:witdh ( By Arbaz )  ///////
// @media only screen and (max-width: 732px) and (min-width: 494px) {
//   .role-table-header-arbaz-responsive{
//     display: inline-flex;
//   }

//   .search-input-wrapper-arbaz-responsive{
//     width: 45%;
//   }

//   .search-input-wrapper-arbaz-responsive input{
//     width: 90%;
//   }

//   .entries-wrapper-entries-arbaz-responsive{
//     width: 45%;
//   }

//   .client-wrapper-client-arbaz-responsive{
//     width: 45%;
//     margin-left: 0;
//   }

//   .client-wrapper-client-arbaz-responsive select{
//     border: 1px solid rgb(255, 238, 0);
//     width: 100%;
//     // margin-left: 0 !important;

//   }

//   .client-wrapper-service-arbaz-responsive{
//     width: 45%;
//   }

//   .client-wrapper-service-arbaz-responsive select{
//     width: 100%;
//   }

// }

// @media only screen and (max-width: 493px) and (min-width: 481px){
//   .search-input-wrapper-arbaz-responsive{
//     width: 45%;
//   }

//   .search-input-wrapper-arbaz-responsive input{
//     width: 100%;
//   }

//   .search-input-wrapper-arbaz-responsive img{
//     // position: absolute;
//     top: 12px;
//     left: 88%;
//     cursor: pointer;
//   }

//   .client-wrapper-client-arbaz-responsive{
//     width: 45%;
//     margin-left: 5px;
//   }

//   .client-wrapper-service-arbaz-responsive{
//     width: 45%;

//   }

// }

// ////////    Media Query at 370px    ////////
// @media all and (max-width:370px){

// }

.table-wrapper {
  cursor: pointer;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  -moz-border-radius-topright: 10px !important;
  -moz-border-radius-bottomleft: 10px !important;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
    -moz-border-radius-topright: 10px !important;
    -moz-border-radius-bottomleft: 10px !important;
  }

  // tr:last-child{
  //   text-align: center;
  // }

  td {
    text-align: center;
    padding: 12px;
    font-size: 0.75rem;
    font-weight: 200;
  }

  th {
    text-align: center;
    padding: 12px;
    color: rgba(17, 17, 17, 0.5);
    font-size: 0.75rem;
    font-weight: 700;
  }

  // td:first-child,
  // th:first-child {
  //   border-radius: 10px 0 0 10px;
  //   padding: 12px;

  // }
  tr td:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    -moz-border-radius-topright: 10px !important;
    -moz-border-radius-bottomleft: 10px !important;
  }

  tr td:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    -moz-border-radius-topright: 10px !important;
    -moz-border-radius-bottomleft: 10px !important;
  }
}

.green-active {
  color: #1cd63a !important;
}

@import "variables";

.cx-sidebar {
  ::-webkit-scrollbar {
    display: none !important;
  }

  .cx-sidebar-wrapper {
    transition: all 0.5s;
    transition: all 0.4s ease-in-out;
    width: 250px;
    background-color: var(--white_FFFFFF_LightMode);
    position: fixed;
    top: 0;
    z-index: 222;
    height: 100vh;
    padding: 30px 20px 30px 30px;

    aside {
      .top-logo-big {
        display: flex;
        justify-content: center;

        .left {
          img {
            max-width: 100%;
            max-height: 6rem;
            // height: auto;
            // width: 145px;
            // margin-top: -1rem;
          }
        }
      }

      .small-logo {
        a {
          img {
            height: auto;
            width: 38px;
          }
        }
      }

      .left-arrow {
        margin: 20px 0;

        svg {
          cursor: pointer;
        }
      }

      .menus-main {
        position: relative;
        transition: all 0.5s;

        height: calc(100vh - 200px);
        overflow-y: auto;

        .accordion-item {
          margin-bottom: 0px !important;
        }
        
        .accordion-button:not(.collapsed) {
          box-shadow: none !important;
        }

        .accordion-body {
          margin-top: 0px !important;
        }

        a {
          text-decoration: none;
        }

        .menu-name {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--gray_05_LightMode);
          display: flex;
          justify-content: space-between;
          align-items: center;

        }

        .innerBadgeChats {
          background-color: red;
          height: 18px;
          width: 18px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: 10px;
          color: #fff;
        }

        .main-logout {
          align-items: end;
          display: flex;
          // justify-content: end;
          margin-top: 50px;
          // position: fixed;
          bottom: 0;
        }

        .menus-items {
          margin-bottom: 30px;

          .icon-menu-name {
            display: flex;
            align-items: center;
            text-decoration: none;
            cursor: pointer;

            .icon-left {
              svg {
                height: 21px;
                width: 21px;
                margin-left: 3px;
              }

              margin-right: 15px;
            }
          }

          .icon-menu-name-arrow {
            display: flex;
            justify-content: space-between;

            .menu-left {
              display: flex;

              // align-items: center;
              .icon-left {
                margin-right: 15px;

                svg {
                  height: 21px;
                  margin-left: 3px;
                  width: 21px;
                }
              }
            }
          }
        }

        #logout {
          bottom: 2rem;
        }

        .sub-menus {
          margin-bottom: 30px;
          transition: all 0.5s;
          margin-left: 11px;
          border-left: 1px solid var(--blue_53B7E8_LightMode);

          .sub-menue-items {
            display: block;
            margin-left: 24px;

            .menu-name {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

.sidebarActive {
  width: 60px !important;
  padding: 15px !important;
  transition: all 0.5s;

  .arrow {
    display: none;
  }

  .menus-main .menus-items .icon-menu-name {
    text-align: center;
    display: block !important;
  }

  .icon-left {
    height: 5vh !important;
    margin-right: unset !important;
  }

  .menus-items {
    margin-bottom: 1rem !important;
  }

  .menu-name {
    display: none !important;
  }
}

.doubleArrowActive {
  transform: rotate(180deg) !important;
  text-align: center !important;
}

.none {
  display: none !important;
}
@import url(./main_responsive2.scss);
@import url(./main_responsive.scss);

@import url(./style.scss);
@import url(./variables.scss);
@import url(./Auth.scss);
@import url(./header.scss);
@import url(./sidebar.scss);
// The below css file is temporary
@import url(./myAttendence.scss);
@import url(./paginate.scss);
@import url(./comunications.scss);
@import url(./Role.scss);
@import url(./TeamsChart.scss);
@import url(./calendar.scss);
@import url(./Document.scss);
@import url(./Profile.scss);
@import url(./Call.scss);
@import url(./backdrop.scss);
@import url(./SalaryStructure.scss);
@import url(./Wallet.scss);
@import url(./phonebookdas.scss);
@import url(./CRMDashboard.scss);
@import url(./CRMstyle.scss);
@import url(./LeadManagement.scss);
@import url(./EnquiryDetails.scss);
@import url(./Campaign.scss);
@import url(./MasterData.scss);

// Chat Admin CSSS Added

/* ChatApp.css */
// @import url("../../pages/ChatAdmin/styles/header.css");
@import url("../../pages/ChatAdmin/styles/setting.css");
@import url("../../pages/ChatAdmin/styles/addQuestion.css");
@import url("../../pages/ChatAdmin/styles/questionListing.css");
@import url("../../pages/ChatAdmin/styles/chatUi.css");
@import url("../../pages/ChatAdmin/styles/paginate.css");

.main_container {
  color: var(--text);
  background-color: var(--background-color);
}

.chat-app {
  padding-top: 70px;
  display: flex;
  height: calc(100vh);
  overflow: hidden;
}

.chat-list {
  width: 250px;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  /* overflow-y: auto; */
  position: fixed;
  height: 100vh;
  z-index: 222;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
}

.chat-list.open {
  transform: translateX(0);
}

.chat-list.closed {
  transform: translateX(-180px);

  .chatHdg {
    .d-flex {
      flex-direction: row-reverse;

      svg {
        padding-left: 30px;
      }
    }
  }
}

.chat-list h2 {
  font-size: 16px;
  margin-bottom: 15px;
  font-family: monospace;
  font-weight: 500;
}

.chat-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.chat-list li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  color: var(--text);
  font-size: 16px;
  font-weight: 500;
}

/* Add more space between list items */
.chat-list li+li {
  margin-top: 5px;
}

/* Style selected item */
.chat-list li.selected {
  // background-color: var(--primary-color);
  background-color: #53b7e8;
  color: #fff;
}

/* .middle-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  margin-right: 20px;
  background-color: var(--background-color2);
  background-color: #ddeaf3;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
} */

.chat-details {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: scroll !important;
  /* position: relative; */
  background-color: white;
  margin: 3px 0 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chat-details-header {
  background-color: var(--background-color2);
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid #ccc;
}

.chat-details-header h2 {
  font-family: monospace;
}

.messages {
  display: flex;
  flex-direction: column;
  height: 580px;
  overflow-y: scroll;
  // scrollbar-color: var(--primary-color) rgb(247, 247, 247);
  scrollbar-color: #53b7e8 rgb(247, 247, 247);
  scrollbar-width: thin;
  padding: 0px 5px;
}

.timestamp {
  font-size: 12px;
  margin-bottom: 0px;
}

.timestamp.bot {
  color: #808080;
}

.timestamp.user {
  color: #d8d8d8;
}

.message {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  padding: 10px;
  border-radius: 20px;
}

.botIcon {
  align-self: self-start;
}

.message-body {
  font-size: 14px;
  padding: 0px;
  max-width: 100%;
}

.message-body.bot {
  color: white;
}

.message-body.user {
  color: black;
}

.message.bot {
  align-self: flex-start;
  background-color: #53b7e8;
  // background-color: var(--primary-color);
  color: white;
  border-radius: 0 20px 20px 20px;
}

.message.user {
  align-self: flex-end;
  background-color: white;
  color: black;
  border-radius: 20px 0 20px 20px;
  border: 1px solid #707c9740;
}

/* Message Input box */
.message-input {
  background-color: white;
  border-top: 1px solid #bdbdbd;
  /* border-bottom: 1px solid #ccc; */
  padding: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  /* margin: -20px; */
}

.message-input input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #bebdbd;
  background-color: var(--background-color2);
  border-radius: 5px;
  margin-right: 10px;
  outline: none;
}

.message-input button {
  width: 70px;
  padding: 10px 0;
  // background-color: var(--primary-color);
  background-color: #53b7e8;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex-shrink: 0;
}

@media screen and (max-width: 600px) {
  .message-input {
    flex-direction: column;
    width: 100%;
  }

  .message-input input {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 6px;
  }

  .message-input button {
    width: 100% !important;
    padding: 8px !important;
  }
}

.right-panel {
  width: 20%;
  border-left: 1px solid #ccc;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  overflow-y: auto;
}

.hideScrollBar::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.hideScrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.soft-shadow {
  box-shadow: 0px rgba(0, 0, 0, 0.01);
}

.textColor {
  color: #53b7e8;
}




















// End ChatAdmin Css

.rbc-day-bg-holiday {
  background-color: red;
  border-radius: 20px;
  margin: 5px 2px;
  border: 0.388864px solid #c2c2c2;
}
.form-control {
  &:focus {
    box-shadow: none;
  }
}
.img_proflie {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.arrangeSpace {
  white-space: break-spaces;
}

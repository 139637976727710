@import "variables";

.login-main {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.main-auth {
  background-color: var(--white_FFFFFF_LightMode);

  .login {
    .top-logo {
      margin: 30px 0;
      text-align: center;

      img {
        width: 240px;
        height: auto;
      }
    }

    .outer-form {
      display: flex;
      justify-content: center;
    }

    .login-form {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      hr {
        height: 100%;
        border: 1px solid gray;
      }

      .wrapper {
        position: relative;
        width: 120px;
        height: 100%;
      }

      .line {
        position: absolute;
        left: 49%;
        top: 0;
        bottom: 0;
        width: 1px;
        background: rgba(102, 102, 102, 0.25);
        z-index: 1;
      }

      .wordwrapper {
        text-align: center;
        height: 12px;
        position: absolute;
        left: 0;
        right: 0;
        top: 45%;
        margin-top: -12px;
        z-index: 2;
      }

      .word {
        text-transform: uppercase;
        color: var(--black_333333_LightMode);
        padding: 20px;
        font-weight: 400;
        font-size: 12px;
        background: #fff;
        line-height: 24px;
      }

      .left {
        // border-right: 1px solid black;
        width: 354px;

        .heading {
          text-align: center;
          margin-bottom: 30px;

          label {
            color: var(--black_111111_LightMode);
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
          }
        }

        .form-control-div {
          margin-bottom: 27px;

          .pass-main {
            display: flex;
            justify-content: space-between;

            .right-pass {
              img {
                cursor: pointer;
                margin-right: 10px;
              }
            }
          }
        }

        .login-button {
          a {
            button {
              margin-bottom: 27px;
              height: 40px;
              width: 100%;
              color: var(--white_FFFFFF_LightMode);
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 33px;
              border-radius: 12px;
              border: none;
              background-color: var(--blue_53B7E8_LightMode);
            }
          }
        }

        .your-pass-text {
          text-align: center;

          label {
            color: var(--gray_666666_LightMode);
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            a {
              text-decoration: underline;
              color: var(--green_009253_LightMode);
            }
          }
        }

        .form-label {
          // cursor: pointer;
          color: var(--gray_05_LightMode);
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }

        .form-control {
          height: 40px;
          border: 1px solid rgba(102, 102, 102, 0.35);
          border-radius: 12px;
          cursor: text;
        }
      }

      .right {
        .email {
          a {
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #111111;
            border-radius: 12px;
            width: 354px;
            height: 40px;

            label {
              margin-left: 15px;
              font-family: "Poppins";
              cursor: pointer;
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 30px;
              color: var(--black_333333_LightMode);
            }
          }
        }
      }
    }

    .bottom-content {
      text-align: center;
      margin: 30px 0 30px 0;

      label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: var(--gray_666666_LightMode);

        a {
          color: var(--black_333333_LightMode);
        }
      }
    }
  }
}

.createAccount {
  padding: 30px ;
  // .left-top-logo {
  //   width: 50%;
  //    margin-left: 25%;
  //   // text-align: center;
  //   img {
  //     margin-left: 9%;
  //     height: 36px;
  //     width: 36px;
  //   }
  // }

  .create-ac-form {
    display: flex;
    justify-content: center;
    .form-control-div {
      margin-bottom: 20px;
      // width: 600px;

      .form-label {
        cursor: pointer;
        color: var(--gray_05_LightMode);
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      .form-control {
        height: 40px;
        border: 1px solid rgba(102, 102, 102, 0.35);
        border-radius: 12px;

        &::placeholder {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          color: rgba(102, 102, 102, 0.6);
        }
      }

      .below-text {
        margin-top: 5px;

        label {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;

          color: #666666;
        }
      }

      .pass-main {
        display: flex;
        justify-content: space-between;

        .right-pass {
          img {
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
    }

    .para-above-btn {
      margin-bottom: 20px;

      label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--gray_666666_LightMode);

        a {
          color: var(--black_111111_LightMode);
          text-decoration: underline;
          font-weight: bold;
        }
      }
    }

    .ac-button {
      a {
        button {
          // margin-bottom: 20px;
          height: 40px;
          width: 100%;
          color: var(--white_FFFFFF_LightMode);
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 33px;
          border-radius: 12px;
          border: none;
          background-color: var(--blue_53B7E8_LightMode);
        }
      }
    }

    .need {
      text-align: center;
      margin-top: 20px;

      label {
        // margin-bottom: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        /* or 200% */

        letter-spacing: 0.25px;

        color: #666666;
      }

      a {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        /* or 200% */

        letter-spacing: 0.25px;

        color: #009253;
      }
    }

    .top-heading {
      text-align: center;
      margin-bottom: 40px;

      .heading {
        color: var(--black_333333_LightMode);
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 42px;
      }

      .para-below {
        color: var(--black_333333_LightMode);

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        a {
          color: var(--green_009253_LightMode);
          text-decoration: underline;
        }
      }
    }
  }

  .otp-section-main {
    margin-bottom: 50px;

    .text {
      text-align: center;
      margin-bottom: 40px;

      label {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        /* identical to box height */

        color: var(--gray_666666_LightMode);

        span {
          color: #111111;
          font-weight: bold;
        }
      }
    }

    .input-otp {
      display: flex;
      justify-content: center;

      #otp-input {
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        width: 65px;
        height: 40px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.02em;
        margin: 0 10px;

        &::placeholder {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 120%;
          /* or 43px */

          text-align: center;
          letter-spacing: -0.02em;

          /* Gray/500 */

          color: #667085;
        }
      }
    }
  }
}




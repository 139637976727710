.salary_structure_btn {
  // width: 140px;
  height: 40px;
  padding: 5px 15px;
  background-color: #53b7e8;
  color: white;
  border-radius: 5px;
  border: none;
  font-size: 0.9rem;
}

.custum_table {
  tbody {
    tr {
      td {
    &:nth-child(2),  
    &:nth-child(4){
        font-weight: 700;
    }  
    }
    }
  }
}

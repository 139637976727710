@import "variables.scss";

// Table responsiveness of 350 px
@media (min-width: 320px) and (max-width: 350px) {
  #main-default-edit-form {
    .responsiveTable {
      .select-table {
        width: 150px !important;
      }
      .dropdown-menu {
        width: 150px !important;
      }
    }
  }
}
.alerts-section {
  max-height: calc(100vh - 160px) !important;
  .alert-items-wrapper {
    height: calc(100vh - 245px) !important;
  }
  .manage-height {
    height: calc(100vh - 200px) !important;
  }
}
// @media all and (max-width: 1600px) {
//   .alerts-sectio {
//     height: calc(100vh - 90px) !important;
//     .alert-items-wrappe {
//       height: calc(100vh - 230px) !important;
//     }
//   }
//   .circle {
//     .CircularProgressbar {
//       width: 82.5% !important;
//     }
//   }
// }
.circle {
  .CircularProgressbar {
    width: 80% !important;
  }
}
@media all and (max-width: 1400px) {
  .alerts-section {
    // height: calc(100vh - 142px) !important;
    .alert-items-wrapper {
      // height: calc(100vh - 235px) !important;
    }
  }
  .circle {
    .CircularProgressbar {
      width: 90.5% !important;
    }
  }
}

@media all and (max-width: 1300px) {
  .mainHeader2 {
    display: block !important;
    .entries-wrapper-main {
      margin-top: 10px !important;
    }
  }
  .alerts-section {
    // height: calc(100vh - 102px) !important;
    .alert-items-wrapper {
      // height: calc(100vh - 190px) !important;
    }
  }
  .circle {
    .CircularProgressbar {
      width: 100% !important;
    }
  }
}

@media all and (max-width: 1150px) {
  .mainHeader,
  .mainHeader2 {
    display: block !important;
  }
  // .role-table-header-main{
  //    flex-wrap: wrap;
  // }
  .mainIcons {
    justify-content: flex-start !important;
    margin-top: 10px !important;
    .shareIcon {
      margin: 0px 10px 0px 0px !important;
    }
  }
  // }

  .alerts-section {
    // height: calc(100vh - 52px) !important;
    .alert-items-wrapper {
      // height: calc(100vh - 145px) !important;
    }
  }
}

@media all and (max-width: 1080px) {
  .timesheet-progress-wrapper {
    .timesheet-progress-main-heading {
      h5 {
      }
      button {
        width: 35%;
        font-size: 0.8rem !important;
      }
    }
  }
  h5 {
    font-size: 1rem !important;
  }
  .alert-text {
    p {
      font-size: 0.8rem !important;
    }
  }
  .legend-label {
    font-size: 0.7rem !important;
  }
  .capsule {
    .legend-color-capsule {
      width: 10px !important;
      height: 5px !important;
    }
  }
  .holidays-section {
    p {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      .holiday-date {
        float: left !important;
        border-bottom: 0.04rem solid gray;
      }
      span {
        font-size: 0.8rem !important;
      }
      .holiday-name {
        float: right !important;
      }
    }
  }
  .my-task-create-btn {
    font-size: 0.8rem !important;
  }
  .timesheet-progress-main-heading {
    button {
      width: 50% !important;
    }
  }
  .alerts-section {
    // height: calc(100vh - 172px) !important;
    .alert-items-wrapper {
      // height: calc(100vh - 255px) !important;
    }
  }
  .circle {
    .CircularProgressbar {
      width: 100% !important;
    }
  }
}

@media all and (max-width: 1035px) {
  .role-table-header-main {
    display: block !important;
    .entries-wrapper-main {
      margin-top: 10px !important;
    }
    .search-input-wrapper-main {
      margin: 0px !important;
    }
    .input-select-option {
      width: 100% !important;
      margin-top: 10px;
    }
  }
  .entries-wrapper-main {
    justify-content: space-between !important;
  }
  .select-drop {
    width: 60% !important;
  }
  .legend-label {
    font-size: 0.8rem;
  }
  .alerts-section {
    // height: calc(100vh - 72px) !important;
    .alert-items-wrapper {
      // height: calc(100vh - 155px) !important;
    }
  }
}

@media all and (max-width: 992px) {
  .mainTimeModal .dateTimeSection {
    margin-left: unset !important;
    margin-bottom: 15px !important;
  }

  .alerts-section {
    // height: calc(100vh - 172px) !important;
    .alert-items-wrapper {
      // height: calc(100vh - 255px) !important;
    }
  }
  .circle {
    .CircularProgressbar {
      width: 60% !important;
    }
  }
  .timesheet-progress-main-heading {
    button {
      width: 30% !important;
    }
  }
}
@media (max-width: 991px) {
  .new-conversation-btn {
    height: 4rem;
    button {
      bottom: 0px !important;
      box-shadow: 0 5px 5px 0 rgb(128 128 128 / 20%);
    }
  }
}

@media all and (max-width: 768px) {
  .dashboard-heading {
    margin-top: 4rem;
  }
  .role-content-main {
  }
  .role-content {
  }
  .calendar-section {
    margin-top: 4rem;
  }
  .my-tasks-section {
    .tab-content {
      .project-list {
        .project-img {
          width: 20% !important;
          margin-right: 1rem;
        }
        .project-title-wrapper {
          width: 30% !important;
          margin-right: 2rem;
        }
        .project-team-img {
          // width: 50% !important;
          margin-right: 1rem;
        }
      }
    }
  }
  .circle {
    .CircularProgressbar {
      width: 60% !important;
    }
  }
  .timesheet-progress-main-heading {
    button {
      width: 30% !important;
    }
  }
  // SideBar
  .menus-main {
    .menus-items {
      .icon-menu-name {
        cursor: pointer;
        .menu-name {
          font-size: 14px !important;
        }
      }
    }
    .sub-menus {
      .sub-menue-items {
        .menu-name {
          font-size: 14px !important;
          line-height: 20px !important;
        }
      }
    }
  }

  .project-list-wrapper {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    .project-progress-bar {
      .label {
        font-size: 12px !important;
      }
    }
    .project-status {
      .label {
        font-size: 12px !important;
        width: auto !important;
      }
    }
  }
  .form-switch {
    .switch-blueBtnHeader {
      margin-top: 7px !important;
    }
  }

  .cx-header {
    header {
      .bell {
        img {
          margin-top: 10px !important;
        }
      }
    }

    .btn-header {
      .greenBtnHeader {
        margin-top: 8px !important;
      }
    }
    .ProfileHeader {
      .dropdown-menu {
        padding: 10px !important;
        a {
          span {
            font-size: 12px;
          }
        }
      }
    }
    .profile-header {
      .pic {
        width: 30px !important;
        height: 30px !important;
        margin-top: 4px !important;
      }

      .add-green {
        right: -1px !important;
      }
    }
  }

  .calendar-section {
    margin-top: 0 !important;

    .rbc-calendar {
      height: 47vh !important;
    }
    .rbc-toolbar {
      font-size: 7px !important;
      .rbc-toolbar-label {
        font-size: 17px !important;
      }
    }
  }

  .dashboard-heading {
    margin-top: 0 !important;

    h3 {
      font-size: 16px !important;
      padding-bottom: 10px !important;
    }
  }

  .check-in-out {
    margin-top: 1.4rem;
  }

  .alerts-section {
    margin-top: 1rem !important;

    .alert-items-wrapper {
      padding: 0 !important;

      .alert-item {
        .alert-text {
          p {
            font-size: 12px !important;
          }
        }

        .alert-img {
          padding: 10px 0 !important;

          img {
            width: 80% !important;
          }
        }
      }
    }
  }

  .my-tasks-section {
    .my-task-main-heading {
      margin-bottom: 0.5rem !important;

      .my-task-create-btn {
        font-size: 10px !important;
        // padding: ;
      }
    }

    .tab-content {
      .project-list {
        width: 140% !important;
        padding: 5px !important;

        .project-img {
          width: 10% !important;

          img {
            width: 1.5rem !important;
            margin-left: 0.5rem !important;
          }
        }

        .project-title-wrapper {
          width: 30% !important;
          margin-right: 1rem !important;
          .project-title {
            h5 {
              font-size: 12px !important;
              margin: 0 !important;
            }

            p {
              font-size: 10px !important;
            }
          }
        }

        .project-team-img {
          img {
            width: 25px !important;
            height: 25px !important;
          }
        }

        .project-start-date {
          .date {
            font-size: 12px !important;
          }

          .label {
            font-size: 10px !important;
          }
        }
        .project-status {
          width: 35% !important;
        }
      }
    }

    .my-task-heading-tabs-wrapper {
      .my-task-heading-tabs {
        .tab-item {
          .nav-link {
            font-size: 12px !important;
            padding: 8px 10px 5px 0px !important;

            span {
              font-size: 11px !important;
              margin-left: 1px !important;
              padding: 1px 5px !important;
            }
          }
        }
      }
    }
  }

  .timesheet-progress-wrapper {
    .timesheet-progress-main-heading {
      h5 {
      }

      a {
        font-size: 10px !important;
      }
    }

    .progress-circles-section {
      .progress-circle {
        margin-top: 0.5rem !important;

        .circle {
          width: 100% !important;
          height: 50% !important;
        }

        .CircularProgressbar {
          width: 40% !important;
        }
      }

      .progress-label {
        p {
          font-size: 12px !important;
        }
      }
    }
  }

  .detailHead {
    font-size: 12px !important;
  }

  .detailLabel {
    font-size: 10px !important;
  }

  .detailText {
    font-size: 13px !important;
    padding-top: 0rem !important;
    line-height: 15px !important;
    margin-bottom: 0.2rem;
  }

  .responsiveEmpolydatda {
    margin-bottom: 0.2rem !important;
  }

  #cx-main {
    .table-wrapper-main {
      .custom-width1 {
        width: 10% !important;
        padding-right: 2rem !important;
      }
      .custom-width2 {
        width: 10% !important;
        padding-right: 2rem !important;
      }
    }
  }

  .mainFormSection {
    padding: 0 !important;

    label {
      margin-bottom: 0 !important;
    }
  }

  .text-grey {
    font-size: 12px !important;
  }

  .main-chat {
    .chatting-section-custom-height {
      // height: calc(100vh - 308px) !important;
    }
    .custom-height {
      height: calc(100vh - 127px) !important;
    }
    .new-conversation-btn {
      padding: 0 !important;
      height: 3rem !important;
      button {
        padding: 4px 10px !important;
        font-size: 12px !important;
        letter-spacing: normal !important;
      }
    }

    .left-chat-section {
      height: calc(100vh - 140px) !important;
      .left-heading-chat {
        padding: 12px 15px 10px !important;
        .left-head {
          label {
            font-size: 14px !important;
          }
        }
      }

      .user-chat-tab {
        .left-profile-pic {
          a {
            img {
              height: 34px !important;
              width: 34px !important;
              margin-top: 4px !important;
            }
          }
        }
      }

      .chat-users-accordion-wrapper {
        width: auto !important;
        overflow: hidden !important;
        .accordion {
          .accordion-item {
            width: 100% !important;
            .accordion-header {
              margin-bottom: 0.2rem !important;
            }
          }
        }
      }

      .nav-pills {
        .nav-link {
          padding: 5px 8px 0 5px !important;
          .user-chat-tab {
            .right-name-date {
              .top-section {
                height: 38% !important;

                .name {
                  label {
                    font-size: 12px !important;
                    line-height: 15px !important;
                  }
                }
              }
              .bottom-section {
                label {
                  font-size: 10px !important;
                }
              }
            }
          }
        }
      }
      .pinned-section {
        .left {
          label {
            font-size: 10px !important;
          }
        }
      }
    }

    .right-chat-section {
      .heading-chat-section {
        padding: 0 0 5px 0 !important;
        .search-input-wrapper {
          input {
            width: 100% !important;
            font-size: 12px !important;
            padding: 5px !important;
          }
          .left {
          }
          .name {
            label {
              font-size: 10px !important;
            }
          }
        }
        .left {
          .name {
            label {
              font-size: 14px !important;
            }
          }
        }
        .right {
          .right-icon-chat {
            height: 30px !important;
            width: 30px !important;
          }
        }
      }
    }

    .chatting-section {
      height: calc(100vh - 285px) !important;
      .message-main {
        .send-msg {
          .send-msg-main {
            padding: 5px !important;
            .msg-text {
              font-size: 10px !important;
            }
          }
        }
        .ActionDropdown {
          .ActionToggle {
            img {
              margin-right: 5px !important;
              margin-left: 5px !important;
              width: 15px !important;
            }
          }
        }
        .time-date-sms-send {
          font-size: 8px !important;
        }
        .time-date-sms-receive {
          font-size: 8px !important;
        }

        .receive-msg {
          .receive-msg-main {
            .msg-text {
              font-size: 10px !important;
            }
          }
        }
      }
    }

    .speed-dial {
      bottom: 132px !important;
      .p-speeddial-button {
        width: 30px !important;
        height: 30px !important;
      }
      .p-button {
        width: 30px !important;
        height: 30px !important;
      }
      .p-button-icon-only {
        width: 30px !important;
        height: 30px !important;
      }
      .p-speeddial-action {
        width: 30px !important;
        height: 30px !important;
      }
    }

    .text-input {
      input {
        margin: 0 !important;
        font-size: 10px !important;
      }
    }
    .send_section_btn_inputs {
      padding: 12px 6px 15px 12px !important;
    }
    .send-btn {
      width: 30px !important;
      height: 30px !important;
    }
    .right-chat-custom-height-1 {
      height: calc(100vh - 127px) !important;
    }
  }
  .announcement-modal-body {
    padding: 0.1rem 0.1rem 0.1rem 0.6rem !important;
  }
  .accoridance {
    .allAcordiance {
      a {
        button {
          font-size: 10px !important;
        }
      }
    }
  }
  .accoridance {
    .myAccordiance {
      a {
        button {
          font-size: 10px !important;
        }
      }
    }
  }
  .project-table-row {
    td {
      font-size: 12px !important;
    }
  }
  .teams-user-lists {
    height: calc(100vh - 285px) !important;
    .team-user {
      padding: 5px 10px !important;
    }
    .menus-items {
      .icon-menu-name-arrow {
        .menu-left {
          .team-image {
            img {
              width: 30px !important;
              height: 30px !important;
            }
          }
          .menu-name {
            font-size: 12px !important;
          }
        }
      }
    }
    .sub-menus {
      .menu-name {
        font-size: 12px !important;
        line-height: 11px !important;
      }
    }
  }

  .btn-green {
    font-size: 10px !important;
  }

  .btn-red {
    font-size: 10px !important;
  }

  .custom-alert {
    width: fit-content !important;
    white-space: break-spaces;
  }

  .employeeDet {
    p {
      font-size: 12px !important;
    }
  }
  // My Diary
  .diary-tabs-main {
    .nav-tabs {
      .nav-link {
        margin-top: 10px !important;
        padding: 5px 15px !important;
        font-size: 10px !important;
      }
    }
  }
  .diary-main-wrap {
    .mydiry-contain {
      .heading {
        h5 {
          font-size: 12px !important;
        }
      }
      .check-in-out-details {
        table {
          tr {
            td {
              font-size: 12px !important;
              padding-bottom: 5px !important;
            }
          }
        }
        .dairy-btn {
          font-size: 10px !important;
        }
        .emptydiart {
          p {
            font-size: 14px !important;
          }
        }
      }
      .mydiary-heading {
        h5 {
          font-size: 12px !important;
        }
        span {
          font-size: 12px !important;
        }
      }
    }
  }

  // My Profile
  .user-designation-details {
    .user-img-box {
      img {
        width: 40%;
      }
      p {
        font-size: 12px !important;
        margin-bottom: 0.2rem !important;
      }
    }
    .user-details {
      margin-top: 0.5rem !important;
      label {
        font-size: 10px !important;
      }
      p {
        font-size: 12px !important;
        margin-bottom: 0.5rem !important;
      }
    }
  }

  .personal-details {
    label {
      font-size: 10px !important;
    }
    p {
      font-size: 12px !important;
      margin-bottom: 0rem !important;
    }
  }

  .contact-details {
    label {
      font-size: 10px !important;
    }
    p {
      font-size: 12px !important;
      margin-bottom: 0rem !important;
    }
  }

  .timesheet-progress-wrapper {
    padding: 10px !important;
  }

  // Modal responsive Css
  .mainContentmodel {
    padding: 0 0px 0 8px !important;
  }
  .addNewBtn {
    width: 80px !important;
    height: 24px !important;
    font-size: 10px !important;
  }
  .box-shadow {
    padding: 10px !important;
  }
  .delete-news-modal {
    .delete-title {
      font-size: 14px !important;
    }

    .delete-body {
      p {
        font-size: 14px !important;
      }
      padding: 20px !important;
    }
  }
  .form-select {
    padding: 2px 10px !important;
  }

  .tasKCategory1 {
    font-size: 12px !important;
    padding: 4px 10px !important;
  }

  .modal-header {
    padding: 10px 20px 8px !important;
  }
  .announcement-header {
    .announcement-title {
      font-size: 14px !important;
      padding-left: 0.4rem !important;
    }
  }
  .modal-body {
    // padding: 10px !important;

    .editModalBody {
      padding: 5px 0 0px !important;

      .diffbtns {
        margin: 0 0 5px 0 !important;
      }
    }
    .bottomLeaveSec {
      label {
      }
      .leave-structure {
        .modal-footer-table-body {
          tr {
            td {
              font-size: 12px !important;
              line-height: 12px !important;
            }
          }
        }
      }
    }

    .listMain {
      .name {
        font-size: 13px;
      }
      .destion {
        font-size: 10px;
      }
    }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer;
      font-size: 10px;
    }

    .preview-img {
      transform: translate(-57%, 23%);
    }

    .Announcement {
      .top {
        h3 {
          font-size: 16px !important;
        }
      }
      .AnnouncementImg {
        height: 100px !important;
        width: 100% !important;
      }
      .discribtion {
        font-size: 12px !important;
        text-align: justify;
      }
    }
    .right-divider {
      &::after {
        top: 15%;
        left: 6.5rem;
        height: 79%;
      }
    }
  }

  .personalDetails-img-upload {
    input[type="file"] {
      width: 51% !important;
      transform: translate(50%) !important;
    }
    img {
      transform: translate(50%);
      width: 50% !important;
    }
    .custom-file-input {
      &::before {
        padding: 4px 28px !important;
      }
    }
  }
  .personalDetailsModal {
    label {
      line-height: 10px !important;
    }
  }

  // Common Modal Buttons
  .btn-width {
    width: 80px !important;
    height: 28px !important;
  }

  .mainBtnsSub {
    padding: 12px 18px !important;

    .cancelBtn {
      font-size: 12px !important;
    }

    .saveBtn {
      font-size: 12px !important;
    }
  }
}

@media all and (max-width: 776px) {
  .alerts-section {
    // height: calc(100vh - 72px) !important;
    .alert-items-wrapper {
      // height: calc(100vh - 155px) !important;
    }
  }
  .main-chat {
    // margin-top: 4rem !important;
  }
  .cancel-btn {
    font-size: 13px !important;
  }
  .sbmt-btn {
    font-size: 13px !important;
  }
  .create-news-modal {
    .create-body {
      padding: 0.5rem !important;

      .form-layout {
        .form-label {
          font-size: 0.8rem !important;
        }
        .form-control {
          font-size: 11px !important;
          padding: 0.375rem !important;
        }
      }
    }
  }
  .ActionDropdown {
    .ActionMenu {
      .actionnmenulist {
        width: 20vh !important;
      }
    }
  }
  .exportImg {
    bottom: 80px !important;
    right: 45px !important;
  }
}

@media all and (max-width: 480px) {
  .paginationMain label {
    display: none;
  }
  .entries-wrapper-main {
    justify-content: space-between !important;
    p {
      width: 45% !important;
      font-size: 0.8rem !important;
    }
  }
  .select-drop {
    width: 45% !important;
  }
  .editModalBody {
    display: block !important;
    .diffbtns {
      margin: 10px 0px;
      justify-content: space-between !important;
    }
  }
  .mainBtnsSub {
    padding: 10px 18px !important;
    .cancelBtn {
      // width: 100% !important;
      margin: 0px 8px 0px 0px !important;
    }
    .saveBtn {
      // width: 100% !important;
    }
  }
  .rbc-btn-group {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .my-tasks-section {
    .tab-content {
      .project-list {
        .project-img {
          width: 100% !important;
          margin-right: 1rem;
        }
        .project-title-wrapper {
          width: 50% !important;
          margin-right: 2rem;
        }
        .project-team-img {
          // width: 50% !important;
          margin-right: 1rem;
        }
      }
    }
  }
  .progress-circle {
    .circle {
      height: 150px !important;
      .CircularProgressbar {
        width: 40% !important;
      }
    }
  }
  .timesheet-progress-main-heading {
    button {
      width: 30% !important;
    }
  }
}

@media (max-width: 720px) {
  .my-tasks-section {
    .tab-content {
      .project-list {
        .project-img {
          width: 10% !important;
          margin-right: 1rem;
        }
        .project-title-wrapper {
          width: 30% !important;
          margin-right: 2rem;
        }
        .project-team-img {
          // width: 40% !important;
          margin-right: 1rem;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  // SideBar
  .menus-main {
    .menus-items {
      .icon-menu-name {
        .menu-name {
          font-size: 14px !important;
        }
      }
    }
    .sub-menus {
      .sub-menue-items {
        .menu-name {
          font-size: 14px !important;
          line-height: 20px !important;
        }
      }
    }
  }

  .project-list-wrapper {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    .project-progress-bar {
      .label {
        font-size: 12px !important;
      }
    }
    .project-status {
      .label {
        font-size: 12px !important;
        width: auto !important;
      }
    }
  }
  .form-switch {
    .switch-blueBtnHeader {
      margin-top: 7px !important;
    }
  }

  .cx-header {
    header {
      .bell {
        img {
          margin-top: 10px !important;
        }
      }
    }

    .btn-header {
      .greenBtnHeader {
        margin-top: 8px !important;
      }
    }
    .ProfileHeader {
      .dropdown-menu {
        padding: 10px !important;
        a {
          span {
            font-size: 12px;
          }
        }
      }
    }
    .profile-header {
      .pic {
        width: 30px !important;
        height: 30px !important;
        margin-top: 4px !important;
      }

      .add-green {
        right: -1px !important;
      }
    }
  }

  .calendar-section {
    margin-top: 0 !important;

    .rbc-calendar {
      height: 47vh !important;
    }
    .rbc-toolbar {
      font-size: 7px !important;
      .rbc-toolbar-label {
        font-size: 17px !important;
      }
    }
  }

  .dashboard-heading {
    margin-top: 0 !important;

    h3 {
      font-size: 16px !important;
      padding-bottom: 10px !important;
    }
  }

  .check-in-out {
    margin-top: 1.4rem;
  }

  .alerts-section {
    margin-top: 1rem !important;

    .alert-items-wrapper {
      padding: 0 !important;

      .alert-item {
        .alert-text {
          p {
            font-size: 12px !important;
          }
        }

        .alert-img {
          padding: 10px 0 !important;

          img {
            width: 80% !important;
          }
        }
      }
    }
  }

  .my-tasks-section {
    .my-task-main-heading {
      margin-bottom: 0.5rem !important;

      .my-task-create-btn {
        font-size: 10px !important;
        // padding: ;
      }
    }

    .tab-content {
      .project-list {
        width: 140% !important;
        padding: 5px !important;

        .project-img {
          width: 10% !important;

          img {
            width: 1.5rem !important;
            margin-left: 0.5rem !important;
          }
        }

        .project-title-wrapper {
          width: 30% !important;
          margin-right: 1rem !important;
          .project-title {
            h5 {
              font-size: 12px !important;
              margin: 0 !important;
            }

            p {
              font-size: 10px !important;
            }
          }
        }

        .project-team-img {
          img {
            width: 25px !important;
            height: 25px !important;
          }
        }

        .project-start-date {
          .date {
            font-size: 12px !important;
          }

          .label {
            font-size: 10px !important;
          }
        }
        .project-status {
          width: 35% !important;
        }
      }
    }

    .my-task-heading-tabs-wrapper {
      .my-task-heading-tabs {
        .tab-item {
          .nav-link {
            font-size: 12px !important;
            padding: 8px 10px 5px 0px !important;

            span {
              font-size: 11px !important;
              margin-left: 1px !important;
              padding: 1px 5px !important;
            }
          }
        }
      }
    }
  }

  .timesheet-progress-wrapper {
    .timesheet-progress-main-heading {
      h5 {
      }

      a {
        font-size: 10px !important;
      }
    }

    .progress-circles-section {
      .progress-circle {
        margin-top: 0.5rem !important;

        .circle {
          width: 100% !important;
          height: 50% !important;
        }

        .CircularProgressbar {
          width: 40% !important;
        }
      }

      .progress-label {
        p {
          font-size: 12px !important;
        }
      }
    }
  }

  .detailHead {
    font-size: 12px !important;
  }

  .detailLabel {
    font-size: 10px !important;
  }

  .detailText {
    font-size: 13px !important;
    padding-top: 0rem !important;
    line-height: 15px !important;
    margin-bottom: 0.2rem;
  }

  .responsiveEmpolydatda {
    margin-bottom: 0.2rem !important;
  }

  #cx-main {
    .table-wrapper-main {
      .custom-width1 {
        width: 10% !important;
        padding-right: 2rem !important;
      }
      .custom-width2 {
        width: 10% !important;
        padding-right: 2rem !important;
      }
    }
  }

  .mainFormSection {
    padding: 0 !important;

    label {
      margin-bottom: 0 !important;
    }
  }

  .text-grey {
    font-size: 12px !important;
  }

  .main-chat {
    .chatting-section-custom-height {
      // height: calc(100vh - 308px) !important;
    }
    .custom-height {
      height: calc(100vh - 127px) !important;
    }
    .new-conversation-btn {
      padding: 0 !important;
      height: 3rem !important;
      button {
        padding: 4px 10px !important;
        font-size: 12px !important;
        letter-spacing: normal !important;
      }
    }

    .left-chat-section {
      height: calc(100vh - 140px) !important;
      .left-heading-chat {
        padding: 12px 15px 10px !important;
        .left-head {
          label {
            font-size: 14px !important;
          }
        }
      }

      .user-chat-tab {
        .left-profile-pic {
          a {
            img {
              height: 34px !important;
              width: 34px !important;
              margin-top: 4px !important;
            }
          }
        }
      }

      .chat-users-accordion-wrapper {
        width: auto !important;
        overflow: hidden !important;
        .accordion {
          .accordion-item {
            width: 100% !important;
            .accordion-header {
              margin-bottom: 0.2rem !important;
            }
          }
        }
      }

      .nav-pills {
        .nav-link {
          padding: 5px 8px 0 5px !important;
          .user-chat-tab {
            .right-name-date {
              .top-section {
                height: 38% !important;

                .name {
                  label {
                    font-size: 12px !important;
                    line-height: 15px !important;
                  }
                }
              }
              .bottom-section {
                label {
                  font-size: 10px !important;
                }
              }
            }
          }
        }
      }
      .pinned-section {
        .left {
          label {
            font-size: 10px !important;
          }
        }
      }
    }

    .right-chat-section {
      .heading-chat-section {
        padding: 0 0 5px 0 !important;
        .search-input-wrapper {
          input {
            width: 100% !important;
            font-size: 12px !important;
            padding: 5px !important;
          }
          .left {
          }
          .name {
            label {
              font-size: 10px !important;
            }
          }
        }
        .left {
          .name {
            label {
              font-size: 14px !important;
            }
          }
        }
        .right {
          .right-icon-chat {
            height: 30px !important;
            width: 30px !important;
          }
        }
      }
    }

    .chatting-section {
      height: calc(100vh - 271px) !important;
      .message-main {
        .send-msg {
          .send-msg-main {
            padding: 10px !important;
            .msg-text {
              font-size: 10px !important;
            }
          }
        }
        .ActionDropdown {
          .ActionToggle {
            img {
              margin-right: 5px !important;
              margin-left: 5px !important;
              width: 15px !important;
            }
          }
        }
        .time-date-sms-send {
          font-size: 8px !important;
        }
        .time-date-sms-receive {
          font-size: 8px !important;
        }

        .receive-msg {
          .receive-msg-main {
            .msg-text {
              font-size: 10px !important;
            }
          }
        }
      }
    }

    .speed-dial {
      bottom: 209px !important;
      .p-speeddial-button {
        width: 30px !important;
        height: 30px !important;
      }
      .p-button {
        width: 30px !important;
        height: 30px !important;
      }
      .p-button-icon-only {
        width: 30px !important;
        height: 30px !important;
      }
      .p-speeddial-action {
        width: 30px !important;
        height: 30px !important;
      }
    }

    .text-input {
      input {
        margin: 0 !important;
        font-size: 10px !important;
      }
    }
    .send_section_btn_inputs {
      padding: 12px 6px 2px !important;
    }
    .send-btn {
      width: 30px !important;
      height: 30px !important;
    }
    .right-chat-custom-height-1 {
      height: calc(100vh - 127px) !important;
    }
  }
  .announcement-modal-body {
    padding: 0.1rem 0.1rem 0.1rem 0.6rem !important;
  }
  .accoridance {
    .allAcordiance {
      a {
        button {
          font-size: 10px !important;
        }
      }
    }
  }
  .accoridance {
    .myAccordiance {
      a {
        button {
          font-size: 10px !important;
        }
      }
    }
  }
  .project-table-row {
    td {
      font-size: 12px !important;
    }
  }
  .teams-user-lists {
    height: calc(100vh - 285px) !important;
    .team-user {
      padding: 5px 10px !important;
    }
    .menus-items {
      .icon-menu-name-arrow {
        .menu-left {
          .team-image {
            img {
              width: 30px !important;
              height: 30px !important;
            }
          }
          .menu-name {
            font-size: 12px !important;
          }
        }
      }
    }
    .sub-menus {
      .menu-name {
        font-size: 12px !important;
        line-height: 11px !important;
      }
    }
  }

  .btn-green {
    font-size: 10px !important;
  }

  .btn-red {
    font-size: 10px !important;
  }

  .custom-alert {
    width: fit-content !important;
    white-space: break-spaces;
  }

  .employeeDet {
    p {
      font-size: 12px !important;
    }
  }
  // My Diary
  .diary-tabs-main {
    .nav-tabs {
      .nav-link {
        margin-top: 10px !important;
        padding: 5px 15px !important;
        font-size: 10px !important;
      }
    }
  }
  .diary-main-wrap {
    .mydiry-contain {
      .heading {
        h5 {
          font-size: 12px !important;
        }
      }
      .check-in-out-details {
        table {
          tr {
            td {
              font-size: 12px !important;
              padding-bottom: 5px !important;
            }
          }
        }
        .dairy-btn {
          font-size: 10px !important;
        }
        .emptydiart {
          p {
            font-size: 14px !important;
          }
        }
      }
      .mydiary-heading {
        h5 {
          font-size: 12px !important;
        }
        span {
          font-size: 12px !important;
        }
      }
    }
  }

  // My Profile
  .user-designation-details {
    .user-img-box {
      img {
        width: 40%;
      }
      p {
        font-size: 12px !important;
        margin-bottom: 0.2rem !important;
      }
    }
    .user-details {
      margin-top: 0.5rem !important;
      label {
        font-size: 10px !important;
      }
      p {
        font-size: 12px !important;
        margin-bottom: 0.5rem !important;
      }
    }
  }

  .personal-details {
    margin-top: 1rem;
    label {
      font-size: 10px !important;
    }
    p {
      font-size: 12px !important;
      margin-bottom: 0rem !important;
    }
  }

  .contact-details {
    label {
      font-size: 10px !important;
    }
    p {
      font-size: 12px !important;
      margin-bottom: 0rem !important;
    }
  }

  .timesheet-progress-wrapper {
    padding: 10px !important;
  }

  // Modal responsive Css
  .mainContentmodel {
    padding: 0 0px 0 8px !important;
  }
  .addNewBtn {
    width: 80px !important;
    height: 24px !important;
    font-size: 10px !important;
  }
  .box-shadow {
    padding: 10px !important;
  }
  .delete-news-modal {
    .delete-title {
      font-size: 14px !important;
    }

    .delete-body {
      p {
        font-size: 14px !important;
      }
      padding: 20px !important;
    }
  }
  .form-select {
    padding: 2px 10px !important;
  }

  .tasKCategory1 {
    font-size: 12px !important;
    padding: 4px 10px !important;
  }

  .modal-header {
    padding: 10px 20px 8px !important;
  }
  .announcement-header {
    .announcement-title {
      font-size: 14px !important;
      padding-left: 0.4rem !important;
    }
  }
  .modal-body {
    // padding: 10px !important;

    .editModalBody {
      padding: 5px 0 0px !important;

      .diffbtns {
        margin: 0 0 5px 0 !important;
      }
    }
    .bottomLeaveSec {
      label {
      }
      .leave-structure {
        .modal-footer-table-body {
          tr {
            td {
              font-size: 12px !important;
              line-height: 12px !important;
            }
          }
        }
      }
    }

    .listMain {
      .name {
        font-size: 13px;
      }
      .destion {
        font-size: 10px;
      }
    }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer;
      font-size: 10px;
    }

    .preview-img {
      transform: translate(-57%, 23%);
    }

    .Announcement {
      .top {
        h3 {
          font-size: 16px !important;
        }
      }
      .AnnouncementImg {
        height: 160px !important;
      }
      .discribtion {
        font-size: 12px !important;
        text-align: justify;
      }
    }
    .right-divider {
      &::after {
        top: 15%;
        left: 6.5rem;
        height: 79%;
      }
    }
  }

  .personalDetails-img-upload {
    input[type="file"] {
      width: 51% !important;
      transform: translate(50%) !important;
    }
    img {
      transform: translate(50%);
      width: 50% !important;
    }
    .custom-file-input {
      &::before {
        padding: 4px 28px !important;
      }
    }
  }
  .personalDetailsModal {
    label {
      line-height: 10px !important;
    }
  }

  // Common Modal Buttons
  .btn-width {
    width: 80px !important;
    height: 28px !important;
  }

  .mainBtnsSub {
    padding: 12px 18px !important;

    .cancelBtn {
      font-size: 12px !important;
    }

    .saveBtn {
      font-size: 12px !important;
    }
  }
}
@media all and (max-width: 480px) {
  #mainHeader {
    .dropdown-menu {
      width: 250px !important;
    }
  }
  .cx-header .cx-header-wrapper {
    padding: 0px 0px 0px 21px !important;
  }
}
@media all and (max-width: 360px) {
  // Login Header
  .main-auth {
    .login {
      .top-logo {
        margin: 10px 0 20px !important;
        img {
          width: 140px !important;
        }
      }
    }
  }

  // SideBar
  .menus-main {
    .menus-items {
      .icon-menu-name {
        .menu-name {
          font-size: 14px !important;
        }
      }
    }
    .sub-menus {
      .sub-menue-items {
        .menu-name {
          font-size: 14px !important;
          line-height: 20px !important;
        }
      }
    }
  }

  .project-list-wrapper {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    .project-progress-bar {
      .label {
        font-size: 12px !important;
      }
    }
    .project-status {
      .label {
        font-size: 12px !important;
        width: auto !important;
      }
    }
  }
  .form-switch {
    .switch-blueBtnHeader {
      margin-top: 7px !important;
    }
  }

  .cx-header {
    header {
      .bell {
        img {
          margin-top: 10px !important;
        }
      }
    }

    .btn-header {
      .greenBtnHeader {
        margin-top: 8px !important;
      }
    }
    .ProfileHeader {
      .dropdown-menu {
        padding: 10px !important;
        a {
          span {
            font-size: 12px;
          }
        }
      }
    }
    .profile-header {
      .pic {
        width: 30px !important;
        height: 30px !important;
        margin-top: 4px !important;
      }

      .add-green {
        right: -1px !important;
      }
    }
  }

  .calendar-section {
    margin-top: 0 !important;

    .rbc-calendar {
      height: 47vh !important;
    }
    .rbc-toolbar {
      font-size: 7px !important;
      .rbc-toolbar-label {
        font-size: 17px !important;
      }
    }
  }

  .dashboard-heading {
    margin-top: 0 !important;

    h3 {
      font-size: 16px !important;
      padding-bottom: 10px !important;
    }
  }

  .check-in-out {
    margin-top: 1.4rem;
  }

  .alerts-section {
    margin-top: 1rem !important;

    .alert-items-wrapper {
      padding: 0 !important;

      .alert-item {
        .alert-text {
          p {
            font-size: 12px !important;
          }
        }

        .alert-img {
          padding: 10px 0 !important;

          img {
            width: 80% !important;
          }
        }
      }
    }
  }

  .my-tasks-section {
    .my-task-main-heading {
      margin-bottom: 0.5rem !important;

      .my-task-create-btn {
        font-size: 10px !important;
        // padding: ;
      }
    }

    .tab-content {
      .project-list {
        width: 170% !important;
        padding: 5px !important;

        .project-img {
          width: 10% !important;

          img {
            width: 1.5rem !important;
            margin-left: 0.5rem !important;
          }
        }

        .project-title-wrapper {
          width: 30% !important;
          margin-right: 1rem !important;
          .project-title {
            h5 {
              font-size: 12px !important;
              margin: 0 !important;
            }

            p {
              font-size: 10px !important;
            }
          }
        }

        .project-team-img {
          img {
            width: 25px !important;
            height: 25px !important;
          }
        }

        .project-start-date {
          .date {
            font-size: 12px !important;
          }

          .label {
            font-size: 10px !important;
          }
        }
        .project-status {
          width: 35% !important;
        }
      }
    }

    .my-task-heading-tabs-wrapper {
      .my-task-heading-tabs {
        .tab-item {
          .nav-link {
            font-size: 12px !important;
            padding: 8px 10px 5px 0px !important;

            span {
              font-size: 11px !important;
              margin-left: 1px !important;
              padding: 1px 5px !important;
            }
          }
        }
      }
    }
  }

  .timesheet-progress-wrapper {
    .timesheet-progress-main-heading {
      h5 {
      }

      a {
        font-size: 10px !important;
      }
    }

    .progress-circles-section {
      .progress-circle {
        margin-top: 0.5rem !important;

        .circle {
          width: 100% !important;
          height: 50% !important;
        }

        .CircularProgressbar {
          width: 40% !important;
        }
      }

      .progress-label {
        p {
          font-size: 12px !important;
        }
      }
    }
  }

  .detailHead {
    font-size: 12px !important;
  }

  .detailLabel {
    font-size: 10px !important;
  }

  .detailText {
    font-size: 13px !important;
    padding-top: 0rem !important;
    line-height: 15px !important;
    margin-bottom: 0.2rem;
  }

  .responsiveEmpolydatda {
    margin-bottom: 0.2rem !important;
  }

  #cx-main {
    .table-wrapper-main {
      .custom-width1 {
        width: 10% !important;
        padding-right: 2rem !important;
      }
      .custom-width2 {
        width: 10% !important;
        padding-right: 2rem !important;
      }
    }
  }

  .mainFormSection {
    padding: 0 !important;

    label {
      margin-bottom: 0 !important;
    }
  }

  .text-grey {
    font-size: 12px !important;
  }

  .main-chat {
    .chatting-section-custom-height {
      // height: calc(100vh - 308px) !important;
    }
    .custom-height {
      height: calc(100vh - 127px) !important;
    }
    .new-conversation-btn {
      padding: 0 !important;
      height: 3rem !important;
      button {
        padding: 4px 10px !important;
        font-size: 12px !important;
        letter-spacing: normal !important;
      }
    }

    .left-chat-section {
      height: calc(100vh - 140px) !important;
      .left-heading-chat {
        padding: 12px 15px 10px !important;
        .left-head {
          label {
            font-size: 14px !important;
          }
        }
      }

      .user-chat-tab {
        .left-profile-pic {
          a {
            img {
              height: 34px !important;
              width: 34px !important;
              margin-top: 4px !important;
            }
          }
        }
      }

      .chat-users-accordion-wrapper {
        width: auto !important;
        overflow: hidden !important;
        .accordion {
          .accordion-item {
            width: 100% !important;
            .accordion-header {
              margin-bottom: 0.2rem !important;
            }
          }
        }
      }

      .nav-pills {
        .nav-link {
          padding: 5px 8px 0 5px !important;
          .user-chat-tab {
            .right-name-date {
              .top-section {
                height: 38% !important;

                .name {
                  label {
                    font-size: 12px !important;
                    line-height: 15px !important;
                  }
                }
              }
              .bottom-section {
                label {
                  font-size: 10px !important;
                }
              }
            }
          }
        }
      }
      .pinned-section {
        .left {
          label {
            font-size: 10px !important;
          }
        }
      }
    }

    .right-chat-section {
      .heading-chat-section {
        padding: 0 0 5px 0 !important;
        .search-input-wrapper {
          input {
            width: 100% !important;
            font-size: 12px !important;
            padding: 5px !important;
          }
          .left {
          }
          .name {
            label {
              font-size: 10px !important;
            }
          }
        }
        .left {
          .name {
            label {
              font-size: 14px !important;
            }
          }
        }
        .right {
          .right-icon-chat {
            height: 30px !important;
            width: 30px !important;
          }
        }
      }
    }

    .chatting-section {
      height: calc(100vh - 271px) !important;
      .message-main {
        .send-msg {
          .send-msg-main {
            padding: 10px !important;
            .msg-text {
              font-size: 10px !important;
            }
          }
        }
        .ActionDropdown {
          .ActionToggle {
            img {
              margin-right: 5px !important;
              margin-left: 5px !important;
              width: 15px !important;
            }
          }
        }
        .time-date-sms-send {
          font-size: 8px !important;
        }
        .time-date-sms-receive {
          font-size: 8px !important;
        }

        .receive-msg {
          .receive-msg-main {
            .msg-text {
              font-size: 10px !important;
            }
          }
        }
      }
    }

    .speed-dial {
      bottom: 209px !important;
      .p-speeddial-button {
        width: 30px !important;
        height: 30px !important;
      }
      .p-button {
        width: 30px !important;
        height: 30px !important;
      }
      .p-button-icon-only {
        width: 30px !important;
        height: 30px !important;
      }
      .p-speeddial-action {
        width: 30px !important;
        height: 30px !important;
      }
    }

    .text-input {
      input {
        margin: 0 !important;
        font-size: 10px !important;
      }
    }
    .send_section_btn_inputs {
      padding: 12px 6px 2px !important;
    }
    .send-btn {
      width: 30px !important;
      height: 30px !important;
    }
    .right-chat-custom-height-1 {
      height: calc(100vh - 127px) !important;
    }
  }
  .announcement-modal-body {
    padding: 0.1rem 0.1rem 0.1rem 0.6rem !important;
  }
  .accoridance {
    .allAcordiance {
      a {
        button {
          font-size: 10px !important;
        }
      }
    }
  }
  .accoridance {
    .myAccordiance {
      a {
        button {
          font-size: 10px !important;
        }
      }
    }
  }
  .project-table-row {
    td {
      font-size: 12px !important;
    }
  }
  .teams-user-lists {
    height: calc(100vh - 285px) !important;
    .team-user {
      padding: 5px 10px !important;
    }
    .menus-items {
      .icon-menu-name-arrow {
        .menu-left {
          .team-image {
            img {
              width: 30px !important;
              height: 30px !important;
            }
          }
          .menu-name {
            font-size: 12px !important;
          }
        }
      }
    }
    .sub-menus {
      .menu-name {
        font-size: 12px !important;
        line-height: 11px !important;
      }
    }
  }

  .btn-green {
    font-size: 10px !important;
  }

  .btn-red {
    font-size: 10px !important;
  }

  .custom-alert {
    width: fit-content !important;
    white-space: break-spaces;
  }

  .employeeDet {
    p {
      font-size: 12px !important;
    }
  }
  // My Diary
  .diary-tabs-main {
    .nav-tabs {
      .nav-link {
        margin-top: 10px !important;
        padding: 5px 15px !important;
        font-size: 10px !important;
      }
    }
  }
  .diary-main-wrap {
    .mydiry-contain {
      .heading {
        h5 {
          font-size: 12px !important;
        }
      }
      .check-in-out-details {
        table {
          tr {
            td {
              font-size: 12px !important;
              padding-bottom: 5px !important;
            }
          }
        }
        .dairy-btn {
          font-size: 10px !important;
        }
        .emptydiart {
          p {
            font-size: 14px !important;
          }
        }
      }
      .mydiary-heading {
        h5 {
          font-size: 12px !important;
        }
        span {
          font-size: 12px !important;
        }
      }
    }
  }

  // My Profile
  .user-designation-details {
    .user-img-box {
      img {
        width: 40%;
      }
      p {
        font-size: 12px !important;
        margin-bottom: 0.2rem !important;
      }
    }
    .user-details {
      margin-top: 0.5rem !important;
      label {
        font-size: 10px !important;
      }
      p {
        font-size: 12px !important;
        margin-bottom: 0.5rem !important;
      }
    }
  }

  .personal-details {
    margin-top: 1rem;
    label {
      font-size: 10px !important;
    }
    p {
      font-size: 12px !important;
      margin-bottom: 0rem !important;
    }
  }

  .contact-details {
    label {
      font-size: 10px !important;
    }
    p {
      font-size: 12px !important;
      margin-bottom: 0rem !important;
    }
  }

  .timesheet-progress-wrapper {
    padding: 10px !important;
  }

  // Modal responsive Css
  .mainContentmodel {
    padding: 0 0px 0 8px !important;
  }
  .addNewBtn {
    width: 80px !important;
    height: 24px !important;
    font-size: 10px !important;
  }
  .box-shadow {
    padding: 10px !important;
  }
  .delete-news-modal {
    .delete-title {
      font-size: 14px !important;
    }

    .delete-body {
      p {
        font-size: 14px !important;
      }
      padding: 20px !important;
    }
  }
  .form-select {
    padding: 2px 10px !important;
  }

  .tasKCategory1 {
    font-size: 12px !important;
    padding: 4px 10px !important;
  }

  .modal-header {
    padding: 10px 20px 8px !important;
  }
  .announcement-header {
    .announcement-title {
      font-size: 14px !important;
      padding-left: 0.4rem !important;
    }
  }
  .modal-body {
    // padding: 10px !important;

    .editModalBody {
      padding: 5px 0 0px !important;

      .diffbtns {
        margin: 0 0 5px 0 !important;
      }
    }
    .bottomLeaveSec {
      label {
      }
      .leave-structure {
        .modal-footer-table-body {
          tr {
            td {
              font-size: 12px !important;
              line-height: 12px !important;
            }
          }
        }
      }
    }

    .listMain {
      .name {
        font-size: 13px;
      }
      .destion {
        font-size: 10px;
      }
    }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer;
      font-size: 10px;
    }

    .preview-img {
      transform: translate(-57%, 23%);
    }

    .Announcement {
      .top {
        h3 {
          font-size: 16px !important;
        }
      }
      .AnnouncementImg {
        height: 160px !important;
      }
      .discribtion {
        font-size: 12px !important;
        text-align: justify;
      }
    }
    .right-divider {
      &::after {
        top: 15%;
        left: 6.5rem;
        height: 79%;
      }
    }
  }

  .personalDetails-img-upload {
    input[type="file"] {
      width: 51% !important;
      transform: translate(50%) !important;
    }
    img {
      transform: translate(50%);
      width: 50% !important;
    }
    .custom-file-input {
      &::before {
        padding: 4px 28px !important;
      }
    }
  }
  .personalDetailsModal {
    label {
      line-height: 10px !important;
    }
  }

  // Common Modal Buttons
  .btn-width {
    width: 80px !important;
    height: 28px !important;
  }

  .mainBtnsSub {
    padding: 12px 18px !important;

    .cancelBtn {
      font-size: 12px !important;
    }

    .saveBtn {
      font-size: 12px !important;
    }
  }
}

.ErrorDataNotFound {
  text-align: center;
  width: 100%;

  td {
    border: none;
    text-align: center;
    color: #53b7e8 !important;
    font-size: 16px !important;
  }
}
.NotFound {
  text-align: center;
  width: 100%;

  border: none;
  text-align: center;
  color: red !important;
  font-size: 16px !important;
}
.NotFoundLeft {
  text-align: left;
  width: 100%;

  border: none;
  text-align: left;
  color: red !important;
  font-size: 16px !important;
}

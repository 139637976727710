.tab-buttons {
  display: flex;
}

.tab-buttons button {
  padding: 5px;
  margin: 5px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #d4d4d4;
  height: 47;
  //   flex: 1;
  width: 150px;
}
.tab-buttonstwo {
  display: flex;
}

.tab-buttonstwo button {
  padding: 5px;
  margin: 5px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #d4d4d4;
  height: 47;
  flex: 1;
}
.active-tab {
  background-color: var(--blueBorderColor_53B7E8_LightMode);
  color: white;
}

.inactive-tab {
  background-color: white;
  color: black;
}
.boxrow {
  display: flex;
  flex-direction: row;
  align-items: center;

  flex: 1;
  justify-content: space-between;
}
.box {
  background-color: var(--whitetbheadLightMode);
  display: flex;
  border-radius: 10px;
  margin-right: 10px;
  height: 100px;
  width: 300px;
  align-items: center;
  justify-content: space-between;

  // border-Radius: 10px;
  border: "1px solid #D4D4D4";
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
  // margin: '10px',
}
// .box p {
//   color: #5d7285;
//   font-size: 20;
// }
.text1 {
  font-size: 20px;
  font-weight: bold;
  color: #5d7285;
  text-align: end;
  margin-right: 20px;
}

.text2 {
  font-size: 18px;
  color: #5d7285;
  text-align: end;
  margin-right: 20px;
}
/* Responsive styles */
// @media (max-width: 768px) {
//   .tab-buttons,
//   .tab-buttonstwo {
//     flex-direction: column;
//     align-items: stretch;
//   }

//   .tab-buttons button,
//   .tab-buttonstwo button {
//     width: 100%;
//     margin: 5px 0;
//   }
// }
@media (max-width: 768px) {
  .boxrow {
    flex-direction: column;
  }
  .box {
    margin-top: 5px;
    width: 100%;
    display: flex;
  }
}
.table-container {
  overflow-x: auto;
  background-color: #ffffff;
  border-radius: 10px;
  // border: 0.5px solid #d0d0d0;
  margin-top: 30px;
}

.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table th {
  padding: 8px;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  color: #5d7285;
  // border: 0.5px solid #d0d0d0;
}
.custom-table td {
  // border: 1px solid #d0d0d0;
  padding: 8px;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  color: #5d7285;
}
.custom-table th {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 21px;
  color: #5d7285;
}

.custom-table .header {
  // border-top: 0.51px solid #d0d0d0;
}

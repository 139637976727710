.main-chat {
  .link_tag {
    text-decoration: none;
    color: white;

    &:hover {
      color: blue !important;
      text-decoration: underline;
    }
  }

  // margin-top: 4rem;
  .left-chat-section {
    padding-bottom: 10px;
    border-radius: 10px;
    background: var(--whitetbheadLightMode);
    height: calc(100vh - 108px);

    ::-webkit-scrollbar {
      display: none !important;
    }

    .chat-user-lists {
      height: calc(100vh - 239px);
      overflow-y: auto;
    }

    .chat-users-accordion-wrapper {
      // width: 106%;
      // transform: translateX(-12px);

      .accordion {
        margin-top: 0 !important;
        border: none !important;
        --bs-accordion-border-width: 0 !important;

        .accordion-item {
          .accordion-collapse {
            .accordion-body {
              height: calc(100vh - 314px) !important;
              overflow: scroll;
              padding: 0 5px;
            }
          }

          .accordion-header {
            padding: 0 13px;
            margin: 0.8rem 0rem;

            button {
            }
          }
        }
      }
    }

    .left-heading-chat {
      // margin-bottom: 10px;
      // border-bottom: 1px solid rgba(17, 17, 17, 0.2);
      padding: 20px 15px;
      display: flex;
      justify-content: space-between;

      .left-head {
        label {
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          /* identical to box height */

          color: rgba(17, 17, 17, 0.8);
        }
      }

      .right-icon {
      }
    }

    .pinned-section {
      display: flex;
      justify-content: space-between;
      padding: 0 2px;

      .left {
        label {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          /* identical to box height */

          color: rgba(17, 17, 17, 0.5);
        }
      }
    }

    .user-chat-tab {
      display: flex;
      position: relative;
      width: 100%;

      .left-profile-pic {
        display: flex;
        position: relative;

        a {
          img {
            margin-right: 10px;
            width: 40px;
            border-radius: 50%;
          }
        }

        .indication-img {
          position: absolute;
          bottom: 5px;
          right: 10px;
        }
      }

      .right-name-date {
        .top-section {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .date {
            position: absolute;
            right: 0;

            label {
              font-weight: 400;
              font-size: 10px;
              line-height: 15px;
              color: rgba(17, 17, 17, 0.6);
            }
          }

          .name {
            label {
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;

              color: rgba(17, 17, 17, 0.8);
            }
          }
        }

        .bottom-section {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 183px;

          label {
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            color: rgba(17, 17, 17, 0.6);
          }
        }
      }
    }

    .nav-pills .nav-link.active {
      background: #53b7e8;

      .user-chat-tab .right-name-date .top-section .date label {
        color: #fff;
      }

      .user-chat-tab .right-name-date .bottom-section label {
        color: #fff;
      }

      .user-chat-tab .right-name-date .top-section .name label {
        color: #ffffff;
      }
    }

    .nav-pills .nav-link {
      border-radius: 10px;
      background-color: transparent;
      padding: 10px;
    }

    .nav {
      // padding-bottom: 40px;
      // border-bottom: 1px solid rgba(17, 17, 17, 0.2);
    }

    #invite-people {
      padding-top: 10px;
    }

    .border-top {
      // border-top: 1px solid rgba(17, 17, 17, 0.2);
    }
  }

  .right-chat-section {
    position: relative;
    background: var(--whitetbheadLightMode);
    border-radius: 10px;
    width: calc(74% - 20px);
    padding: 1rem;
    height: auto !important;

    ::-webkit-scrollbar {
      display: none !important;
    }

    .heading-chat-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 0 0px 0;
      border-bottom: 1px solid rgba(17, 17, 17, 0.2);

      .search-input-wrapper {
        margin-top: 0 !important;

        input {
          font-size: 1rem;
          width: 500px;
          cursor: text;
          height: 40px;
          background-color: var(--white_FFFFFF_LightMode);
          border-radius: 10px;
          border: none;
          padding-left: 10px;
          color: var(--black_111111_LightMode);
          padding-right: 32px;
        }
      }

      .left {
        display: flex;
        align-items: center;

        .name {
          padding-left: 10px;

          label {
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            /* identical to box height */

            color: rgba(17, 17, 17, 0.8);
          }
        }

        .left-profile-pic {
          position: relative;
         // height: 40px;
          width: 40px;

          a {
            img {
              margin-right: 10px;

              border-radius: 50%;
            }
          }

          .indication-img {
            position: absolute;
            bottom: 0px;
            right: 1px;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;

        .right-icon-chat {
          margin-left: 15px;
          display: inline-block;
          height: 40px;
          width: 40px;
          background-color: #fff;
          box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.03),
            0px 7px 25px rgba(42, 139, 242, 0.03),
            0px 5px 25px rgba(42, 139, 242, 0.07);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  p {
    margin-bottom: 0;
  }

  .chatting-section {
    height: calc(100vh - 278px);
    // height: calc(100vh - 370px);
    overflow-y: auto;
    margin: 15px 0 0 0;

    .message-main {
      // display: flex;
      .send-msg {
        margin-bottom: 5px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        .send-msg-main {
          word-break: break-word;
          text-align: initial;
          // cursor: pointer;
          max-width: 400px;
          // flex-basis: 40%;
          // margin-bottom: 20px;

          background: #ffffff;
          border: 1px solid rgba(112, 124, 151, 0.25);
          border-radius: 10px 10px 0px 10px;
          padding: 2px 10px;

          .msg-text {
            word-break: break-word;
            font-weight: 500;
            font-size: 14px;
            line-height: 26px;
            // color: rgba(17, 17, 17, 0.5);

            a {
              // color: ;
              text-decoration: none;
            }
          }

          .msg-time {
            margin-top: 3px;
            text-align: right;
            font-family: "Rubik";
            font-style: normal;
            font-weight: 300;
            font-size: 8px;
            line-height: 9px;
            color: #08242b;
          }
        }
      }

      .receive-msg {
        margin-bottom: 5px;

        display: flex;

        .ActionDropdown {
          display: flex;
          align-items: flex-end;
          margin-bottom: 10px;
        }

        .right-receive-msg {
          // text-align: left;
          // display: block;
          // align-items: center;
          display: flex;
          flex-direction: column;
          position: relative;
        }
        .emojis{
          position: absolute;
          left: 90px;
          bottom: -30px;
        }

        .msg-with-img {
          margin-right: 10px;

          img {
            height: 36px;
            width: 36px;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .receive-msg-main {
          // flex-basis: 40%;
          // margin-bottom: 20px;
          background: #53b7e8;
          border-radius: 0px 10px 10px 10px;
          padding: 5px;

          .msg-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 26px;
            word-break: break-word;
            color: #ffffff;

            a {
              // color: ;
              text-decoration: none;
            }
          }

          .msg-time {
            margin-top: 3px;
            text-align: right;
            font-family: "Rubik";
            font-style: normal;
            font-weight: 300;
            font-size: 8px;
            line-height: 9px;
            color: #08242b;
          }
        }
      }

      .time-date-sms-receive {
        max-width: 50%;
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        color: rgba(17, 17, 17, 0.5);
        margin-bottom: 15px;
      }

      .time-date-sms-send {
        font-weight: 500;
        font-size: 12px;
        color: rgba(17, 17, 17, 0.5);
        display: flex;
        flex-direction: row-reverse;
        margin-right: 74px;
        margin-bottom: 15px;
      }
    }
  }
  .message-main:hover{
  }

  .send_section_btn_inputs {
    border-top: 1px solid rgba(17, 17, 17, 0.2);
    padding: 12px 15px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // position: absolute;
    bottom: 0;
  }

  .text-input {
    // border: .5px solid #d4ebf7;
    // border-radius: 12px;
    width: calc(100% - 100px);
    margin-left: 20px;

    // height: 40px;

    strong {
      display: none;
    }

    input {
      width: 100% !important;
      height: 30px !important;
      border: none !important;
    }

    ul {
      height: 300px;
      overflow-y: auto;
      // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border: 1px solid #ebebeb;
      padding: 15px 15px !important;
      border-radius: 10px;

      li:hover {
        background-color: #73caf5;
        color: white;
        border-radius: 10px;
      }

      li {
        background-color: #d4ebf7;
        margin-bottom: 10px;
        padding: 5px 15px;
        border-radius: 5px;
        transition: 0.2s;
      }
    }

    ul::-webkit-scrollbar-thumb:hover {
      background: #b30000;
    }

    ul::-webkit-scrollbar-thumb {
      background: red;
      border-radius: 10px;
    }

    ul::-webkit-scrollbar {
      width: 20px;
      display: block;
    }

    ul::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    input {
      width: 100%;
      height: 30px;
      border: none;
      margin-left: 15px;

      &:focus {
        outline: none !important;
      }
    }

    .form-control:focus {
      box-shadow: none;
    }
  }

  .send-btn {
    background-color: #53b7e8;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .speed-dial {
    left: -10px;
    bottom: 165px;
    position: relative;
    bottom: -20px;
    left: -10px;

    .p-speeddial-opened .p-speeddial-list {
      background-color: #fff;
      border-radius: 20px;
      padding: 0;
    }

    .p-speeddial-list {
      padding: 0;
    }

    .p-speeddial-item {
      margin-top: 10px;
    }

    .p-speeddial {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .p-speeddial-button.p-button.p-button-icon-only {
      height: 40px;
      width: 40px;
      // position: absolute;
    }

    .p-button {
      background-color: #53b7e8;
      border: none;
      border-radius: 50%;
    }

    .pi-plus:before {
      font-weight: bolder;
    }

    .p-button:enabled:hover,
    .p-button:not(button):not(a):not(.p-disabled):hover {
      background-color: #53b7e8;
      border: none;
    }

    .p-speeddial-action {
      font-weight: bolder;
      height: 40px;
      width: 40px;
      background-color: #53b7e8;
    }
  }
}

// Three dot Action Dropdown

.ActionDropdown {
  .ActionToggle {
    background-color: transparent !important;
    border: none;
    padding: 0;

    &:focus {
      background-color: transparent;
    }

    &::after {
      display: none;
    }

    &:hover {
      background-color: #fff;
    }
  }

  .ActionMenu {
    border: none;
    box-shadow: 2px 2px 10px var(--gray_05_LightMode);
    background-color: var(--white_FFFFFF_LightMode);
    padding: 0;
    position: absolute !important;
    margin: 0;
    box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 20%);
    // inset: 15px -10px auto auto !important;

    .actionnmenulist {
      padding: 4px 13px;
      margin: 5px 5px;
      list-style-type: none;
      border-radius: 10px;
      color: var(--gray_05_LightMode);
      cursor: pointer;
      width: 200px;

      li {
        color: var(--gray_05_LightMode);
        font-size: 14px;
        padding: 5px 0;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: var(--blue_53B7E8_LightMode);
          font-weight: 500;
        }
      }

      li a {
        color: var(--gray_05_LightMode);
        font-size: 14px;
        padding: 5px 0;
        transition: all 0.2s ease-in-out;
        text-decoration: none;

        &:hover {
          color: var(--blue_53B7E8_LightMode);
          font-weight: 500;
        }
      }
    }
  }
}

// Teams Tab

.teams-tab {
  background-color: transparent !important;

  .teams-user-tab {
    display: flex;
    position: relative;
    width: 100%;

    .left-profile-pic {
      display: flex;
      position: relative;

      a {
        img {
          margin-right: 10px;
          height: 40px;
          width: 40px;
          border-radius: 50%;
        }
      }

      .indication-img {
        position: absolute;
        bottom: -6px;
        right: 7px;
      }
    }

    .right-name-date {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .top-section {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name {
          display: flex;
          align-items: center;
          justify-content: center;

          .active {
            color: var(--blue_53B7E8_LightMode);
          }

          label {
            color: #000;
            font-weight: 600;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.teams-user-lists {
  height: calc(100vh - 270px);
  overflow-y: auto;
  width: auto;

  .team-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    .menus-items {
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        text-decoration: none;
      }

      // align-items: center;

      .icon-menu-name-arrow {
        display: flex;
        justify-content: space-between;

        .menu-left {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .menu-name {
            padding-left: 10px;
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: var(--gray_08_LightMode);
          }

          .team-image {
            margin: 0 10px 0 3px;

            img {
              width: 100%;
              height: auto;
              border-radius: 50%;
            }
          }
        }

        .arrow {
        }
      }
    }
  }

  .ActionDropdown {
  }

  .sub-menus {
    margin: 10px 0 20px 30px;
    transition: all 0.5s;
    border-left: 1px solid var(--blue_53B7E8_LightMode);

    a {
      text-decoration: none;
    }

    .sub-menue-items {
      margin-bottom: 15px;
      display: block;
      margin-left: 24px;
    }

    .menu-name {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--gray_05_LightMode);
    }
  }
}

// Chat History

.right-chat-custom-height-1 {
  height: calc(100vh - 106px) !important;
}

.right-chat-custom-height-2 {
  height: calc(100vh - 106px) !important;
}

.custom-height {
  height: calc(100vh - 160px);
}

.chatting-section-custom-height {
  height: calc(100vh - 334px) !important;
}

.new-conversation-btn {
  position: relative;

  button {
    color: var(--blue_53B7E8_LightMode);
    background-color: #fff;
    padding: 10px 25px;
    border: none;
    border-radius: 10px;
    font-weight: 600;
    letter-spacing: 0.04rem;
    position: absolute;
    right: 6px;
    bottom: 0;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: #fff;
      background-color: var(--blue_53B7E8_LightMode);
    }
  }
}

.toast {
  position: absolute;
}

.chat-lineseparater {
  border-top: 1px solid var(--gray_02_LightMode);
  margin: 1px 15px !important;
}

.announcement-modal-body {
  padding: 0.2rem 0.2rem 0.2rem 0.8rem;
}

.img-preview-section {
  position: absolute;
  bottom: 6rem;

  .ImgPreview {
    position: relative;

    .close_icon {
      cursor: pointer;
      position: absolute;
      top: -11px;
      right: 8px;
    }
  }
}

.image {
  width: 100px;
  height: 100px;
  margin: 0 10px 0 10px !important;
  background-color: white;
}

.time-date-sms-recieve {
  font-weight: 500;
  font-size: 12px;
  color: rgba(17, 17, 17, 0.5);
  display: flex;
  text-align: start;
  margin-left: 47px;
  margin-bottom: 15px;
}

.suggestion-list-wrapper {
  ul {
    height: calc(100vh - 200px);
    overflow-y: scroll;
  }

  .suggestion-item {
    .user-chat-tab {
      div {
        position: relative;
        display: flex;
        align-items: center;

        img {
          order: 1;
          position: relative;
          top: 0;
          left: 0;
        }

        p {
          order: 2;
          padding-left: 1rem;
        }
      }
    }
  }
}

.status-indication-green {
  background-color: green;
  border-radius: 50%;
}

.backgroundWhite {
  margin-left: 0.5rem;
  height: 35px !important;
  width: 35px !important;
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 4px 0px #80808052;
  border-radius: 50%;
  background-color: #fff !important;

  .set-status {
    margin-top: 2px;
    width: 20px !important;
    height: 20px !important;
  }
}

.status-img-shadow {
  label {
    cursor: pointer;
  }

  img {
    width: fit-content;
    height: fit-content;
    // border-radius: 50%;
    // box-shadow: 0px 2px 4px 0px #80808052;
  }
}

.name {
  .lastSeen {
    .text1 {
      font-weight: 100 !important;
      font-size: 10px !important;
    }
  }
}

.icon-left {
  position: relative;
}

.badge {
  position: absolute;
  top: -8px;
  right: -5px;
  height: 17px;
  width: auto;
  background-color: red;
  min-width: 17px;
  color: white;
  font-size: 10px;
  padding: 5px 2px;
  border-radius: 50%;
}

.badgeHeader {
  position: absolute;
  top: 4px;
  right: 3px;
  height: 17px;
  width: 17px;
  background-color: rgb(68, 192, 30);
  min-width: 17px;
  color: white;
  font-size: 10px;
  padding: 5px 2px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.teams-dropdown {
  ul {
    inset: 0px -90px auto auto !important;
    width: 130px !important;

    .actionnmenulist {
    }
  }
}

.memberImage {
  width: 20x;
  height: 20px;
}

.send_section_btn_inputs {
  position: relative;

  #output {
    // position: absolute;
  }

  canvas {
    position: absolute;
    top: -52%;
    // box-shadow: 0 5px 10px 0 gray !important;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 145px) !important;
    // border-radius: 10px 10px 0 0;
    border: 1px solid rgba(0, 0, 0, 0.425);
    // height: 90% !important;
    border-radius: 10px;
    z-index: 1;
  }
}

#output {
  canvas {
    width: 100px;
    height: 20px;
  }
}

.text-input-while-reply {
  position: relative;
  z-index: 0;

  // width: 270px;

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100px;
    bottom: 0;
    z-index: -1;
    margin-left: 10px !important;
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    // background-color: rgba(128, 128, 128);
    background-color: #e7e7e7;
    // box-shadow: 0 5px 10px 0 gray !important;
  }

  input {
    z-index: 99 !important;
    position: inherit;
    background-color: #fff;
    width: 95%;
    margin: 0 0 10px 20px;
  }
}

.repliesTo,
.replyTo {
  padding: 5px;
  // width: 70%;
  height: auto;
  background: transparent;
  background-color: #e7e7e7 !important;
  border-radius: 10px 10px 0px 10px;
  border: 1px solid #e7e7e7;

  .send-msg-main {
    max-width: 100% !important;
    width: fit-content !important;
    text-align: justify;
    margin: 5px 5px 10px 20px;
  }

  .cavnes_main {
    margin: 0 !important;

    canvas {
      width: 100% !important;
      height: 40px !important;
    }
  }
}

.call_popUp_main {
  position: fixed;
  top: 60px;
  right: 20px;
  width: 300px;
  height: auto;
  background-color: #414141;
  text-align: center;
  padding: 20px 0;
  border-radius: 15px;
  z-index: 1;

  .body_call {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 200px;
      height: 200px;
      border-radius: 25px;
    }
  }

  .heading {
    p {
      text-align: center;
      width: 100%;
      color: #53b7e8;
      font-size: 25px;
      margin: 0;
      padding: 0;
      font-weight: 600;
    }
  }

  .Footer_call {
    display: flex;
    justify-content: center;
    margin: 20px 0;

    .button_Call {
      display: flex;

      .approve {
        width: 50px;
        height: 50px;
        background-color: green;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        cursor: pointer;

        img {
          height: 30px;
          width: 30px;
        }
      }

      .reject {
        width: 50px;
        height: 50px;
        background-color: red;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        cursor: pointer;

        img {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
}

.replyTo {
  position: absolute;
  padding: 10px;
  width: 100%;
  height: auto;
  bottom: 68px;
}

.EmojiPickerReact {
  height: 418px !important;
  width: 334px !important;
  position: absolute !important;
  padding: 10px !important;
  bottom: 68px;
}

.jitsi-wrapper {
  position: relative;

  .button_Call {
    z-index: 1222;
    justify-content: right;
    width: 100%;
    position: absolute;
    display: flex;
    bottom: 5%;
    right: 20%;
    cursor: pointer;

    .reject {
      width: 100px;
      height: 30px;
      font-size: 12px;
      color: red;
      border-radius: 5px;
      // background-color: red;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;

      img {
        width: 30px;
        margin-right: 10px;
        transform: rotate(138deg);
      }
    }
  }
}

.search-input-wrapper {
  display: flex;

  .button {
    text-align: end;

    button {
      // width: 100px;
      padding: 5px;
      background-color: #53b7e8;
      color: white;
      padding: 5px 20px;

      &:hover {
        background-color: #53b7e8;
        color: white;
        padding: 5px 20px;
        transition: all 0.5s;
      }
    }
  }
}

//Files

.main_file_body {
  .single_Card_File {
    display: flex;
    margin-top: 10px;

    .img_file {
      margin-right: 20px;

      a {
        text-decoration: none !important;
      }

      img {
        height: 35px;
        width: 35px;
      }
    }

    .file_contain {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e7e7e7;
      padding-bottom: 10px;

      .left {
        .name {
          font-size: 14px;
          font-weight: 600;
          color: black;
          padding: 0;
          margin: 0;
          margin-bottom: 5px;
        }

        .date {
          font-size: 10px;
          font-weight: 400;
          color: rgba(17, 17, 17, 0.6);
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}

#file_sutom_arrow {
  right: 0px;
  bottom: -5px;
}

.HighlightClass {
  cursor: pointer;
  color: #53b7e8;
  padding: 0px 8px;
  background-color: white;
  border-radius: 5px;
}
.visualizer{
  width: 300px;
}

.senderName {
  font-size: 10px;
  display: flex;
  align-items: flex-start;
}

#search-chat-user {
  // .searchWrapper {
  //   margin-bottom: 10px;
  // }

  display: block;

  img {
    position: unset;
  }
}

.right-chat-section3 {
  background: var(--whitetbheadLightMode);
  border-radius: 10px;
  padding: 1rem;
  width: 98%;
  height: calc(100vh - 100px);
}

.right-chat-section2 {
  position: relative;
  background: var(--whitetbheadLightMode);
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  height: calc(100vh - 100px);

  .searchWrapper {
    border: 1px solid #ced4da !important;
    padding: 0.375rem 0.75rem !important;
    border-radius: 0.375rem !important;
  }

  .custoBtn {
    background-color: var(--blue_53B7E8_LightMode);
    border: none;
    border-radius: 17px;
    color: #fff;
    padding: 8px 16px;
  }
}

.styled-link {
  color: #b30000;
}

#mentionInput {
  // margin-top: 5px !important;
  // border: 1px solid #53b7e8;
  // border-radius: 7px;
  // border: none;
  padding: 5px 10px;
  // &:focus {
  //   box-shadow: none !important;
  //   border-color: #73caf5 !important;
  // }
}

.validationTxt {
  color: red;
  font-size: 12px;
  margin: 5px 0px 0px 5px;
}

.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: "Yes";
      text-transform: uppercase;
      padding-left: 10px;
      background-color: green;
      color: #fff;
    }
  }
  &-disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: "No";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: red;
    color: #fff;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
    }
  }
}

.toggle-switch-sandbox {
  position: relative;
  width: 140px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}

.toggle-switch-sandbox {
  position: relative;
  width: 120px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}

.toggle-switch-checkbox-sandbox {
  display: none;
}

.toggle-switch-label-sandbox {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  margin: 0;
}

.toggle-switch-inner-sandbox {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.toggle-switch-inner-sandbox:before,
.toggle-switch-inner-sandbox:after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}

.toggle-switch-inner-sandbox:before {
  content: "PRODUCTION";
  text-transform: uppercase;
  padding-left: 1px;
  background-color: green;
  color: #fff;
}

.toggle-switch-inner-sandbox:after {
  content: "SANDBOX";
  text-transform: uppercase;
  padding-right: 19px;
  background-color: red;
  color: #fff;
  text-align: right;
}

.toggle-switch-disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.toggle-switch-disabled:before {
  background-color: #ddd;
  cursor: not-allowed;
}

.toggle-switch-switch-sandbox {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 87px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.toggle-switch-checkbox-sandbox:checked + .toggle-switch-label-sandbox .toggle-switch-inner-sandbox {
  margin-left: 0;
}

.toggle-switch-checkbox-sandbox:checked + .toggle-switch-label-sandbox .toggle-switch-switch-sandbox {
  right: 0px;
}

.toggle-switch-enabled {
  position: relative;
  width: 120px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}

.toggle-switch-checkbox-enabled {
  display: none;
}

.toggle-switch-label-enabled {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  margin: 0;
}

.toggle-switch-inner-enabled {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.toggle-switch-inner-enabled:before,
.toggle-switch-inner-enabled:after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}

.toggle-switch-inner-enabled:before {
  content: "ENABLED";
  text-transform: uppercase;
  padding-left: 10px;
  background-color: green;
  color: #fff;
}

.toggle-switch-inner-enabled:after {
  content: "DISABLED";
  text-transform: uppercase;
  padding-right: 19px;
  background-color: red;
  color: #fff;
  text-align: right;
}

.toggle-switch-disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.toggle-switch-disabled:before {
  background-color: #ddd;
  cursor: not-allowed;
}

.toggle-switch-switch-enabled {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 87px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.toggle-switch-checkbox-enabled:checked + .toggle-switch-label-enabled .toggle-switch-inner-enabled {
  margin-left: 0;
}

.toggle-switch-checkbox-enabled:checked+ .toggle-switch-label-enabled .toggle-switch-switch-enabled {
  right: 0px;
}


#diffMultiSelect {
  .searchWrapper {
    padding: 0px !important;
  }

  .tasKCategory1 {
    border-radius: 0.375rem;
  }

  .optionListContainer {
    margin-top: 10px !important;
  }
}

.chip,
.singleChip {
  word-wrap: normal !important;
}
.form-data__highlighter{
  max-height: 100px;
  padding-top: 3px; 
  padding-bottom: 3px;
  margin-left: 10px;
}

.employee_wallet_main {
  .sub-header-role {
    a {
      color: var(--blue_53B7E8_LightMode);
      text-decoration: none;
    }
  }

  .subHeaderFlex {
    background-color: var(--white_FFFFFF_LightMode);
    border-radius: 12px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;

    .detailBox {
      .headtxt {
        color: var(--blue_53B7E8_LightMode);
        font-size: 18px;
      }

      .amntflex {
        display: flex;
        align-items: center;

        label {
          color: var(--blue_53B7E8_LightMode);
          margin-right: 5px;
          font-size: 20px;
          font-weight: 600;

          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }

    .withDrawBtn {
      .reachargeWal {
        background-color: var(--blueBorderColor_53B7E8_LightMode);
        border: none;
        border-radius: 18px;
        height: 40px;
        margin-right: 10px;
        width: 160px;

        a {
          color: #fff;
          font-size: 14px;
          text-decoration: none;
        }
      }
    }
  }

  .transactionBox {
    background-color: var(--white_FFFFFF_LightMode);
    border-radius: 12px;
    padding: 20px;
    width: 100%;

    .headTxt {
      color: var(--blue_53B7E8_LightMode);
      font-size: 18px;
    }
  }

  .search-input-wrapper {
    input {
      width: 100%;
    }
  }

  .search-input-wrapper-main {
    .form-select {
      width: 100% !important;
    }
  }

  .exportBtn {
    height: 40px;
    width: 40px;
    border-radius: 12px;
    background-color: var(--white_FFFFFF_LightMode);
    margin-top: 24px;
    border: none;
  }

  #walletTabs {
    width: 100%;

    .nav-pills {
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid grey;
      margin-bottom: 20px;
    }

    .nav-item {
      text-align: center;
      width: 25%;
    }

    .nav-link {
      color: grey;
    }

    .nav-pills .nav-link.active {
      color: var(--blue_53B7E8_LightMode);
      border-bottom: 2px solid var(--blue_53B7E8_LightMode);
      background-color: transparent;
      border-radius: 0px;
    }

    .innerTable {
      background-color: #fff;
      height: 390px;
      overflow-y: auto;
      background-color: #fff;
      border-radius: 12px;

      .innerTableMain {
        width: 100%;

        thead {
          tr {
            th {
              position: sticky;
              top: 0;
              border-bottom: 1px solid grey;
              background-color: #fff;
              color: grey;
              padding: 20px;
              font-size: 16px;
              font-weight: 400;
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 20px;
              color: grey;
              padding: 20px;
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  // Recharge Component code
  .mainAmntBox {
    .header {
      background-color: var(--blue_53B7E8_LightMode);
      padding: 20px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      h1 {
        color: #fff;
        font-size: 18px;
        font-weight: 400;
      }
    }

    .body {
      background-color: #fff;
      padding: 20px;

      .inputBox {
        border-bottom: 1px solid grey;
        padding: 20px 0px 40px 0px;
        margin-bottom: 20px;

        label {
          color: grey;
          font-size: 16px;
          margin-bottom: 5px;
        }
      }

      .form-control {
        width: 550px;

        &:focus {
          box-shadow: none;
          outline: none;
        }

        &::placeholder {
          color: grey;
          font-size: 14px;
        }
      }

      .threeBox {
        h1 {
          color: grey;
          font-size: 16px;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }

    .footer {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      padding: 20px;
      background-color: var(--blue_53B7E8_LightMode);
      margin-bottom: 20px;

      .colSix {
        h1 {
          color: #fff;
          font-size: 16px;
        }
      }

      .threeBox {
        h1 {
          color: #fff;
          font-weight: 500;
          font-size: 18px;
        }
      }
    }

    .cancelBtn {
      border: 1px solid var(--blue_53B7E8_LightMode);
      height: 40px;
      width: 140px;
      border-radius: 12px;
      background-color: transparent;
      color: var(--blue_53B7E8_LightMode);
      margin-right: 10px;
    }

    .payNow {
      border: none;
      height: 40px;
      width: 140px;
      border-radius: 12px;
      background-color: var(--blue_53B7E8_LightMode);
      color: #fff;
    }
  }
}

// Wallet Sales Styles
.employee_wallet_sales {
  .sub-header-role {
    a {
      color: var(--blue_53B7E8_LightMode);
      text-decoration: none;
    }
  }

  .search-input-wrapper {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .mainTableBox {
    height: calc(100vh - 180px);
    background-color: var(--white_FFFFFF_LightMode);

    border-radius: 12px;
    padding: 20px;

    .headingBox {
      .tableLab {
        color: var(--blue_53B7E8_LightMode);
        font-size: 18px;
        padding-bottom: 10px;
      }

      border-bottom: 1px solid rgb(209, 202, 202);
    }

    .upperFlex {
      display: flex;
      justify-content: end;
      align-items: center;
      margin-bottom: 20px;

      .CreateWallet {
        margin-top: 24px;
        border: none;
        background-color: var(--blue_53B7E8_LightMode);
        height: 40px;
        width: 220px;
        border-radius: 12px;
        margin-right: 20px;

        a {
          color: #fff;
          text-decoration: none;
        }
      }

      .exportBtn {
        height: 40px;
        width: 40px;
        border-radius: 12px;
        background-color: var(--white_FFFFFF_LightMode);
        margin-top: 24px;
        border: 1px solid var(--blue_53B7E8_LightMode);
      }
    }

    .insideTable {
      width: 100%;
      height: calc(100vh - 330px);
      overflow-y: auto;

      table {
        width: 100%;

        thead {
          tr {
            th {
              position: sticky;
              top: 0;
              background-color: #fff;
              border-bottom: 1px solid grey;
              font-size: 16px;
              color: grey;
              font-weight: 400;
              padding: 20px;
            }
          }
        }

        tbody {
          tr {
            &:first-child {
              position: sticky;
              top: 63px;
              background-color: #fff;
              padding: 0px 10px;
            }

            td {
              padding: 10px;
              font-size: 16px;
              color: grey;
              font-weight: 400;
            }

            .clearBtn {
              height: 40px;
              width: 140px;
              background-color: var(--blue_53B7E8_LightMode);
              color: #fff;
              border-radius: 12px;
              border: none;
            }
          }

          .Clearbtn {
            background-color: var(--blue_53B7E8_LightMode);
            height: 40px;
            width: 140px;
            border-radius: 12px;
            color: #fff;
            border: none;
          }

          .form-select {
            border: 1px solid #dee2e6 !important;
            width: 160px !important;
          }

          .form-control {
            width: 120px;
          }

          .actionBtn {
            height: 40px;
            width: 40px;
            border: 1px solid var(--blue_53B7E8_LightMode);
            border-radius: 12px;
            background-color: transparent;
          }
        }
      }
    }

    .paginationTxt {
      float: right;

      h1 {
        color: grey;
        font-size: 15px;
        margin-bottom: 0px;
        margin-top: 15px;
      }
    }
  }
}

// Transaction Receipt Styles
.employee_transaction_receipt {
  .sub-header-role {
    a {
      text-decoration: none;
      color: var(--blue_53B7E8_LightMode);
    }
  }

  .contentBox {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    height: calc(100vh - 190px);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .tableBordered {
      width: 100%;
      border: 1px solid #000;
      border-bottom: none;
      margin-bottom: 20px;

      .topFirst {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #000;

        .firstLeft {
          width: 63.5%;
          padding: 10px;
          border-right: 1px solid #000;
          text-align: end;

          h1 {
            font-size: 16px;
            color: grey;
            margin-bottom: 0px;
            font-weight: 600;
          }
        }

        .firstRight {
          width: 36.5%;
          padding: 10px;

          h1 {
            font-size: 16px;
            color: grey;
            margin-bottom: 0px;
            font-weight: 600;

            label {
              font-weight: 300;
            }
          }
        }
      }

      .Second {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #000;

        .firstLeft {
          width: 27.4%;
          padding: 10px;
          border-right: 1px solid #000;

          h1 {
            font-size: 16px;
            color: grey;
            margin-bottom: 0px;
            font-weight: 600;
          }
        }

        .firstRight {
          width: 70%;
          padding: 10px;

          label {
            font-weight: 300;
            color: grey;
          }
        }
      }

      .Third {
        display: flex;
        // justify-content: space-between;
        align-items: center;

        h1 {
          font-weight: 600;
          font-size: 16px;
          padding: 10px;
          color: grey;
          margin-bottom: 0px;

          label {
            color: grey;
            font-weight: 300;
          }

          &:nth-child(1) {
            width: 50%;
          }

          &:nth-child(2) {
            width: 32%;
          }

          &:nth-child(3) {
            width: 18%;
          }
        }
      }

      table {
        width: 100%;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        margin-bottom: 0px !important;

        thead {
          tr {
            th {
              border: 1px solid #000;
              padding: 10px;
              color: grey;
              font-size: 16px;

              &:first-child {
                border-left: none;
              }

              &:last-child {
                border-right: none;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              border: 1px solid #000;
              padding: 10px;
              color: grey;
              font-size: 16px;

              &:first-child {
                border-left: none;
              }

              &:last-child {
                border-right: none;
              }
            }

            .subTotal {
              font-weight: 600;
              border-left: none !important;
            }
          }
        }
      }
    }

    .printBtn {
      background-color: var(--blue_53B7E8_LightMode);
      height: 40px;
      width: 140px;
      color: #fff;
      border-radius: 12px;
      border: none;
    }
  }
}

// Create Store Styles
.employee_wallet_sales {
  .btnFlex {
    .Update {
      height: 40px;
      width: 140px;
      background-color: var(--blue_53B7E8_LightMode);
      color: #fff;
      border: none;
      border-radius: 12px;
      margin-right: 15px;
    }

    .Delete {
      height: 40px;
      width: 140px;
      background-color: transparent;
      color: var(--blue_53B7E8_LightMode);
      border: 1px solid var(--blue_53B7E8_LightMode);
      border-radius: 12px;
    }

    .CategoryStockBtn {
      height: 40px;
      width: 250px;
      background-color: var(--blue_53B7E8_LightMode);
      border: none;
      border-radius: 12px;
      margin-right: 15px;

      a {
        color: #fff;
        text-decoration: none;
      }
    }

    .CategoryVie {
      height: 40px;
      width: 220px;
      background-color: var(--blue_53B7E8_LightMode);
      border: none;
      border-radius: 12px;
      margin-right: 15px;

      a {
        color: #fff;
        text-decoration: none;
      }
    }

    .CategoryCre {
      height: 40px;
      width: 200px;
      background-color: transparent;
      border: 1px solid var(--blue_53B7E8_LightMode);
      border-radius: 12px;

      a {
        color: var(--blue_53B7E8_LightMode);
        text-decoration: none;
      }
    }
  }
}

.createStoreBox {
  height: calc(100vh - 180px);
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .innerConmtainer {
    width: 100%;
  }

  .CreateBtn {
    background-color: var(--blue_53B7E8_LightMode);
    border: none;
    color: #fff;
    height: 40px;
    width: 140px;
    border-radius: 12px;
  }

  .innerConmtainer {
    .StoreCreatedBox {
      width: 100%;

      table {
        width: 100%;

        tbody {
          tr {
            td {
              padding: 10px;
              color: grey;
              width: 50%;
            }

            .key {
              font-weight: 600;
              color: grey;
            }
          }
        }
      }
    }
  }
}

.createStock {
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;

  .innerConmtainer {
    width: 100%;
  }

  .CreateBtn {
    background-color: var(--blue_53B7E8_LightMode);
    border: none;
    color: #fff;
    height: 40px;
    width: 140px;
    border-radius: 12px;
  }

  .innerConmtainer {
    .StoreCreatedBox {
      width: 100%;

      table {
        width: 100%;

        tbody {
          tr {
            td {
              padding: 10px;
              color: grey;
              width: 50%;
            }

            .key {
              font-weight: 600;
              color: grey;
            }
          }
        }
      }
    }
  }
}

// Create categories styles
.employee_wallet_sales {
  .CreateCategories {
    height: calc(100vh - 180px);
    background-color: #fff;
    border-radius: 12px;
    padding: 60px 20px 20px;
    width: 100%;

    .innerBox {
      width: 100%;

      .mainFormCheck {
        .form-switch {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;

          &:checked,
          :focus {
            box-shadow: none;
          }
        }
      }

      .Create {
        height: 40px;
        width: 140px;
        background-color: var(--blue_53B7E8_LightMode);
        color: #fff;
        border: none;
        border-radius: 12px;
        margin-right: 15px;
      }
    }
  }
}

.modal-header .modal-title {
  color: var(--blue_53B7E8_LightMode);
}

.modal-body {
  text-align: left;

  .SendBtn {
    background-color: var(--blue_53B7E8_LightMode);
    height: 40px;
    width: 140px;
    border-radius: 12px;
    color: #fff;
    border: none;
  }
}

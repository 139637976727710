.uploadWrap {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  margin-top: 22px;
}

.Settign_logo_uplaod {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  /* border: 1px solid black; */
  margin-top: 10px;
  margin-bottom: 6px;
  background-color: #ddeaf3;
}

.uploadBtn {
  padding: 5px 9px;
  margin-top: 3px;
  cursor: pointer;
  /* background-color: var(--primary-color); */
  background-color: #53b7e8 ;
  color: white;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}

.setting-section {
  /* margin: -20px; */
  /* margin-top: 50px; */
  width: 100%;
  /* border-top: 1px solid #ccc; */
  padding-top: 20px;
}
.custmPdgSetng {
  padding-top: 7px;
}
/* color settings */

/* TableWithColorPicker.css */

.table-container {
  margin: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  /* border: 1px solid #ddd; */
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.colorPickerCloseIcon {
  background-color: var(--primary-color);
  color: white;
  height: fit-content;
  outline: none;
  border-radius: 8px;
  border: none;
  padding: 5px 8px;
  margin-left: 20px;
}

.color-picker-button {
  /* background-color: var(--primary-color); */
  background-color: #53b7e8;
  color: white;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 8px 12px;
  font-weight: 600;
  /* font-family: monospace; */
}

.addSetting_btn {
  /* position: absolute;
    top: 100px; */
  /* right: 50px; */
  outline: none;
  background-color: white;
  /* border: 1px solid var(--primary-color); */
  border: 1px solid #53b7e8;
  border-radius: 18px;
  /* color: var(--primary-color); */
  color: #53b7e8;
  font-size: 14px;
  height: 35px;
  margin-right: 10px;
  width: 160px;
  text-decoration: none;
  font-weight: 600;
  /* font-family: monospace; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colors p {
  margin: 0;
}

.selection {
  width: 100%;
  font-weight: 300;
  outline: none;
  padding: 10px 15px;
}

@media screen and (max-width: 768px) {
  .uploadWrap {
    flex-direction: column;
    width: 100%;
    padding-left: 23px;
  }
}

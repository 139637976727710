.agent-table th {
  text-align: center;
}

.agentmaintable {
  border-radius: 10px;
}
.agent-head-section {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 859px) {
  .agent-head-section {
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  }
}
.shows-main {
  display: flex;
  align-items: center;
  gap: 10px;
}
.shows-agent {
  color: grey;
  font-size: 12px;
}
.table-heading {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}
.table-data {
  color: #000;
  font-size: 12px;
  font-weight: 600;
}

.details-link {
  color: #fff;
  text-decoration: none;
  font-size: 12px;
}
.agent-row td {
  text-align: center;
}
.button-maindiv {
  display: flex;
  justify-content: flex-end;
}
.button-subdiv {
  display: flex;
  gap: 10px;
  margin: 10px;
}
.second-section-div {
  background-color: #fff;
}
.tabledata {
  display: flex;
  flex-direction: column;
}
.tablerowdata {
  display: flex;
  justify-content: space-evenly;
}
.tabledata1 {
  margin-left: 5%;
}
.tablerowdata label {
  font-size: 12px;
  padding: 10px;
}

.imporButton {
  background-color: var(--blueBorderColor_53B7E8_LightMode);
  border: none;
  border-radius: 50px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
}
.import_Button1 {
  background-color: var(--blueBorderColor_53B7E8_LightMode);
  border: none;
  border-radius: 50px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
}
.registerbutton {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

@import "variables";
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.custom-alert {
  padding: 10px 30px !important;
  background-color: var(--greenLight_E0FAE4_LightMode);
  border: 1px solid var(--green_009253_LightMode);
  border-radius: 10px;

  p {
    font-size: 0.75rem;
  }
}

// News And Blogs Create / Edit / Delete Modal

.table-wrapper {
  .edit-btn {
    background-color: transparent;
    padding: 0;
    border: none;
    outline: none;

    &:hover {
      background-color: transparent;
    }
  }

  .delete-btn {
    background-color: transparent;
    padding: 0;
    border: none;
    outline: none;

    &:hover {
      background-color: transparent;
    }
  }
}

.cancel-btn {
  border: 1px solid #53b7e8 !important;
  background-color: #fff;
  color: var(--blue_53B7E8_LightMode);
  border: none;
  border-radius: 12px;
  width: 120px;
  height: 40px;

  // &:hover {
  //   background-color: var(--blueLight_53B7E8_LightMode) !important;
  //   color: var(--blue_53B7E8_LightMode) !important;
  //   border: none;
  // }
}

.sbmt-btn {
  background-color: var(--blue_53B7E8_LightMode);
  color: #fff;
  border: none;
  border-radius: 20px;

  // padding: 8px 25px;
  &:hover {
    background-color: var(--blue_53B7E8_LightMode) !important;
    color: #fff !important;
    border: none;
  }
}

.delete-news-modal {
  .modal-content {

    // width: 75%;
    .modal-header {
      padding: 20px 20px 10px;
      // border-bottom: 1px solid rgba(17, 17, 17, 0.1) !important;

      .delete-title {
        // width: 17.3vw;
        // padding-bottom: 10px;
        // padding-left: 1.8rem;
      }
    }

    .delete-body {
      padding: 30px 20px;

      p {
        color: rgba(17, 17, 17, 0.5);
      }
    }

    .mainBtnsSub2 {
      padding: 10px 20px 18px;
    }
  }
}

.create-news-modal {
  .modal-content {
    width: 100% !important;

    .create-header {
      padding: 20px 0 10px !important;
      margin: 0 20px;
      border-bottom: 1px solid #828282 !important;

      .btn-close {}

      .create-title {
        // width: 38.5vw;
        // padding-bottom: 10px;
        // border-bottom: 1px solid rgba(17, 17, 17, 0.1) !important;
      }
    }

    .create-body {
      padding: 1rem;
      border: 1px solid rgb(221 234 243 / 60%) !important;
      margin: 1rem;
      border-radius: 10px;

      .form-layout {
        .form-top {
          display: flex;
          align-items: center;
        }

        .form-group {
          margin: 0 5px;
          width: 20%;
        }

        .form-label {
          color: var(--gray_05_LightMode);
        }

        .form-select {
          font-size: 12px;
          color: var(--gray_05_LightMode);
          border: 1px solid var(--blue_53B7E8_LightMode);
        }

        .form-control {
          font-size: 12px;
          color: var(--gray_05_LightMode);
          border: 1px solid var(--blue_53B7E8_LightMode);
        }

        .form-control {
          font-size: 12px;
          color: var(--gray_05_LightMode);
        }

        option {
          font-size: 12px;
          color: var(--gray_05_LightMode);
        }

        .form-bottom {
          display: flex;

          .form-label {
            margin-right: 10px;
          }

          .form-control {
            margin: 0 6px;
          }

          .text-area {
            width: 42vw;
          }
        }
      }
    }
  }
}

.views-news-modal {
  .modal-content {
    width: 23vw;

    .views-header {
      display: flex;
      padding-bottom: 5px;

      .views-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(17, 17, 17, 0.1) !important;

        .left-title {
          display: flex;
          align-items: center;
          justify-content: start;

          p {
            font-size: 0.8rem;
            color: var(--gray_05_LightMode);
          }
        }

        .right-count {
          span {
            font-size: 13px;
            padding: 3px 12px;
            border-radius: 20px;
            color: #fff;
            background-color: var(--blue_53B7E8_LightMode);
          }
        }
      }
    }

    .view-body {
      padding: 0px 20px 30px;

      .list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;

        .list-img {}

        .member-name {}

        .member-designation {
          color: var(--gray_05_LightMode);
          font-size: 0.9rem;
        }
      }
    }
  }
}

.announcement-header {

  // border-bottom: 1px solid var(--gray_02_LightMode) !important;
  // margin: 0 5%;
  .announcement-title {
    padding-left: 1rem;
    font-size: 20px;
  }
}

.right-divider {
  position: relative;

  &::after {
    position: absolute;
    top: 18%;
    right: -7%;
    content: "";
    height: 60%;
    width: 2px;
    background-color: var(--gray_02_LightMode);
    z-index: 1;
  }
}

.Member-Modal {
  .modal-content {
    height: 90vh;
  }
}
.question-answer-list {
  width: 100%;
  /* margin: 20px 0; */
  font-family: Arial, sans-serif;
}

.qa-item {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.qa-item label {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
  display: block;
}

.qa-item p {
  margin: 0 0 10px 0;
  font-size: 18px;
  color: #555;
}

.qa-question {
  width: 80%;
}

.qa-answer {
  width: 80%;
  margin-left: auto;
}

.qa-answer label {
  width: fit-content;
  margin-left: auto;
}

.qa-answer p {
  width: fit-content;
  margin-left: auto;
}

.questionListing_edite_icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

#call_main_wrap {
  .call_list_Left {
    padding: 15px 12px;

    .inpute_top {
      input {
        background: var(--whitetbheadLightMode);
        border: 1px solid rgba(17, 17, 17, 0.2);
        border-radius: 10px;
        width: 100%;
        font-size: 14px;
        height: 40px;
        padding: 0 12px;
      }
    }

    .list-main-wrapper {
      // margin-top: 15px;
      height: calc(100vh - 250px);
      overflow-y: auto;

      .call-single-left {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        width: 100%;
        margin: 2px 0;

        .list-main {
          display: flex;
          align-items: center;

          .img-call-wrap {
            position: relative;

            img {
              border-radius: 50%;
            }

            span {
              img {
                position: absolute;
                bottom: 0px;
                right: 0;
              }
            }
          }

          .caller_Name {
            font-size: 14px;
            color: #111111cc;
            font-weight: 600;
            margin-left: 25px;
          }
        }

        .Call_vedio {
          a {
            padding: 8px;
            border-radius: 50%;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .right-heading-chat {
    .right-head {
      label {
        font-size: 18px;
        color: black;
        font-weight: 600;
      }
    }
  }

  .History-tabs-main {
    ul {
      // margin-top: -60px;
      display: flex;
      justify-content: end;
      margin-top: -25px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(17, 17, 17, 0.2);

      li {
        margin-left: 15px;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #dddddd;
          border-radius: 17.3325px;
          color: #1111114d;
          height: 35px;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .nav-link.active {
        background-color: #edf7fc;
        color: #53b7e8;
        border-color: #53b7e8;
      }
    }
    .callerlistWrapper {
      height: calc(100vh - 201px);
      overflow-y: auto;
    }
    .single-all-contact {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .all-left {
        display: flex;
        align-items: center;

        .img-call-wrap {
          position: relative;
          margin-right: 20px;

          img {
            height: 40px;
            width: 40px;
            border-radius: 50%;
          }

          span {
            position: absolute;
            bottom: -5px;
            right: 1px;

            img {
              height: 12px;
              width: 12px;
            }
          }
        }

        .name {
          p {
            font-size: 14px;
            font-weight: 600;
            color: #111111cc;
          }

          .green {
            font-size: 10px;
            font-weight: 400px;
            color: #00b31d;
          }
          .orange {
            font-size: 10px;
            font-weight: 400px;
            color: #ffa903;
          }
          .red {
            font-size: 10px;
            font-weight: 400px;
            color: #ff0000;
          }
        }
      }

      .all-right {
        text-align: end;
        .time {
          font-size: 10px;
          font-weight: 400;
          color: #11111199;
        }

        .duration {
          font-size: 10px;
          font-weight: 400;
          color: #11111199;
        }
      }
    }
  }
}

#crm-call-form #tabsBelow {
  background-color: none;
  padding: 0px;
}
#crm-call-form .nav-tabs {
  background-color: var(--backColorMainDarksecond) !important;
}
#crm-call-form .nav-tabs .nav-link {
  background-color: #fff !important;
  margin-right: 10px;
  border-radius: 10px;
  // width: 203px !important;
}
#crm-call-form .tab-content {
  margin-top: -15px;
  background-color: var(--backColorMainDarksecond) !important;
}

.custoBtnForCrmCall {
  background-color: var(--blueBorderColor_53B7E8_LightMode);
  border: none;
  border-radius: 50px;
  color: #fff;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
}

.custoBtnForCrmCallLink {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  text-decoration: none;
}
.SerReqModalTitle {
  font-size: 1.25rem;
}
.SerReqModalBody {
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
}

.SerReqModalBody p {
  color: var(--black_000000_LightMode) !important;
}
.inBound-btn-sub-header {
  border: none;
  background-color: #fff;
  border-radius: 10px !important;
  color: var(--blue_53B7E8_LightMode);
  font-size: 14px;
  width: 160px;
  height: 35px;
  margin-right: 10px;
}
.outBound-btn-sub-header {
  border: none;
  background-color: #fff;
  border-radius: 10px !important;
  color: var(--blue_53B7E8_LightMode);
  font-size: 14px;
  width: 190px;
  height: 35px;
  margin-right: 10px;
}
.sml-btn-sub-header {
  border: none;
  background-color: var(--blueBorderColor_53B7E8_LightMode) !important;
  border-radius: 10px !important;
  color: #fff;
  font-size: 14px;
  height: 35px;
  padding: 0px 20px;
  margin-right: 10px;
}
.totlCountTxt {
  p {
    margin-bottom: 0;
    font-size: 0.8rem;
    color: rgba(17, 17, 17, 0.5);
    display: flex;
    align-items: center;
  }
}
#crmOfc-calr-hdr .create-btn-sub-header {
  border: none;
  background-color: var(--blue_deeplight_EEF8FD);
  border-radius: 18px;
  color: var(--blue_53B7E8_LightMode);
  font-size: 14px;
  width: auto !important;
  padding: 0 15px;
  height: 35px;
  margin-right: 10px;
}

@media (max-width: 1035px) {
  .cus-mb {
    margin-top: 10px !important;
  }
}
@media (max-width: 568px) {
  .cus-mt {
    margin-top: 14px !important;
  }
}

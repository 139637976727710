@import "variables";

.accordion-button {
  display: block;
  padding: 0;
  background-color: transparent;
  color: var(--black_000000_LightMode) !important;

  .table-wrapper {
    margin-top: 0;

    table {
      border-collapse: collapse;
    }
  }
}

  

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.accordion-button:not(.collapsed) {
  background-color: transparent;
}

.accordion-header {
  padding: 0px;
  // border-radius: 10px;
}

.accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}

.accordion-item {
  // width: 1300px;
  margin-bottom: 9px;
  border: none !important;
  // border-radius: 10px !important;
  background-color: transparent;
}

.accordion-item:first-of-type .accordion-button,
.accordion-item:first-of-type {
  border-radius: 10px;
}

.accordion-body {
  padding: 0;
}

.accordion-body {
  margin-top: 3px;
  padding: 0;
  // background-color: white;
  border-radius: 10px;
}

// .darkModeEdit {
//   background-color: #000 !important;
//   border: 1px solid #53b7e8 !important;
// }

// .lightModeEdit {
//   background-color: #FFF !important;
// }

.accordion {
  margin-top: 30px;
  // overflow-x: auto;
}

.AccordianBody {
  // margin-top: 15px;
  display: block;
  white-space: nowrap;
  border-radius: 10px;
  background-color: var(--white_FFFFFF_LightMode);
  padding: 0px 0 10px 0;
  //   border-top: 1px solid ;
  height: 600px;
  overflow-y: auto;

  table {
    width: 100%;
    border-collapse: separate;
    // border-spacing: 0 8px;
    padding: 0 15px;

    // text-align: center;
    thead {
      tr {
        &:nth-child(1) {
          box-shadow: 0 1px 0 0 #ddeaf3;
        }
      }
    }

    .table-row-custom {
      td {
        color: grey;
        font-size: 12px;
        font-weight: 400;
        // text-align: center;
        background-color: #fff;
        position: sticky;
        top: 0px;
      }
    }

    .tabel-body-acordince {
      vertical-align: baseline;

      .arrangeSpace {
        white-space: break-spaces;
      }

      .Rejected-tabel-data {
        color: #ff0000;
      }

      .Approve-tabel-data {
        color: #07be24;
      }

      .disabled {
        img {
          cursor: not-allowed;
        }
      }

      .pending-tabel-data {
        color: #ffa903;
      }
    }
  }

  td {
    padding: 12px 14px !important;
    font-size: 0.75rem;
    color: var(--black_000000_LightMode);

    ul {
      padding-left: 1rem;
    }
  }

  th {
    padding: 14px;
    color: var(--gray_05_LightMode);
    font-size: 0.75rem;
    font-weight: 400;
  }

  tr:first-child {
    // border-radius: 10px 0 0 10px;
    // padding-left: 3rem;
  }

  td:last-child,
  th:last-child {
    // border-radius: 0 10px 10px 0;
  }
}

.topAccordiance {
  h2 {
    button {
      background-color: transparent;
    }
  }
}

// Add Timesheet modal final styles
.mainTimeModal {
  display: flex;
  justify-content: space-between;

  .dateTimeSection {
    margin-left: 10px;

    label {
      padding-left: 0.8rem;
    }
  }

  .punchedHrs {
    display: flex;
    justify-content: space-between;

    .punch-hrs {
      justify-content: space-between;

      label:nth-child(2) {
        font-weight: 600;
      }
    }

    .wrk-hrs {
      // justify-content: space-between;
      label:nth-child(2) {
        font-weight: 600;
      }
    }
  }
}

.mainFormSection {
  // border: 1px solid var(--backColorMainDarksecond);
  border-radius: 10px;
  padding: 15px 15px 10px;
  margin-left: 8px;
  // box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%), inset 0 1px 0 rgb(0 0 0 / 10%);
}

.box-shadow {
  box-shadow: 0px 1px 6px 0px lightgrey;
}

.tasKCategory1 {
  // color: var(--gray_05_LightMode) !important;
  border: 1px solid var(--blueBorderColor_53B7E8_LightMode) !important;
  position: relative !important;
  width: 100% !important;
  height: 38px;
  // height: 42px !important;
  // cursor: text;
}

.css-13cymwt-control {
  border: 1px solid var(--blueBorderColor_53B7E8_LightMode) !important;
}

.css-1u9des2-indicatorSeparator {
  background-color: var(--blueBorderColor_53B7E8_LightMode) !important;
}

.searchWrapper {
  padding: 0px !important;
}
.create-sub-channel{
  .searchWrapper{
    border: 1px solid #53b7e8;
    padding: 0px 6px!important;
    margin-top: -8px;
  }
}

.multiSelectContainer input {
  margin-top: 0px !important;
  font-size: 20px !important;
  height: 40px;

  &::placeholder {
    color: var(--gray_05_LightMode) !important;
  }
}

.disabledColor {
  background-color: #bfbebe !important;
}

.rightAxe {
  img {
    cursor: pointer;
  }
}

.dropDOwnImgh {
  background-image: url("../images/sidebar/icons/dropDown.svg") !important;
  //Af
  background-position: 98%;
}

.tasKCategory3 {
  color: var(--gray_05_LightMode) !important;
  // border: 1px solid var(--blueBorderColor_53B7E8_LightMode) !important;
  border: none;
  position: relative !important;
  width: 100% !important;
}

.watchTimesheet {
  position: absolute;
  right: 50px;
  top: 194px;
}

.tasKCategory2 {
  color: var(--gray_05_LightMode);
  border: 1px solid var(--blueBorderColor_53B7E8_LightMode);
  position: relative;
  background-color: #ffff;
}

.watchTimesheet2 {
  position: absolute;
  right: 50px;
  bottom: 260px;
}

.modal-header {
  .btn-close {
    font-size: 12px;
  }
}

.addNewBtn {
  width: 100px;
  height: 30px;
  left: 242px;
  background-color: var(--blueBorderColor_53B7E8_LightMode);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 11px;
  margin-left: 6px;
}

.bodypadding {
  border-radius: 50px;

  td {
    padding: 8px 15px;
  }
}

.table-row-custom-padding {
  table {
    td {
      font-weight: 500;

      &:nth-child(1) {
        width: 18% !important;
      }

      &:nth-child(2) {
        width: 30% !important;
        padding-left: 3.1rem !important;
      }

      &:nth-child(3) {
        width: 27% !important;
        padding-left: 0.5rem !important;
      }

      &:nth-child(4) {
        width: 30% !important;

        // padding-left: 2rem !important;
        img {
          position: absolute;
          right: 20px;
          top: 18px;
        }
      }
    }
  }
}

.switch-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 !important;
  margin: 0 !important;

  input {
    margin-left: 1rem !important;
    padding: 0 !important;
    float: right !important;
    background-color: var(--gray_05_LightMode);
    border: none;
    box-shadow: none;
    background-image: #fff !important;

    &:checked {
      background-color: #828282;
      outline: none;
      box-shadow: none;
      border: none;
      background-image: #fff !important;
    }
  }

  .switch-whitebtn {
    background-image: url(../images/switch.svg);

    &:focus {
      border: none;
      box-shadow: none;
      background-image: url(../images/switch.svg);
    }
    &:checked{
      background-color: #53b7e8;
    }
  }

  label {
  }
}

.preview-img {
  transform: translate(-168%, 32%);
}

.fixed-width {
  white-space: normal;
}

.p-datepicker:not(.p-datepicker-inline) {
  z-index: 10001 !important;
}

.p-inputtext {
  margin: 0;
  border-color: #53b7e8;
  height: 39px;
}

.p-inputtext:enabled:hover {
  border-color: #53b7e8;
}

.p-calendar .p-inputtext {
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    border-color: #53b7e8;
  }
}

.deassignBtn {
  border: none;
  background-color: red;
  color: #fff;
  padding: 6px 8px;
  border-radius: 10px;
}

.allocateionBtn {
  border: none;
  background-color: var(--blueBorderColor_53B7E8_LightMode);
  color: #fff;
  padding: 6px 8px;
  border-radius: 10px;
  // margin-top: 10px;
}
.allocateionBtnwhite {
  border: 1px solid  var(--blueBorderColor_53B7E8_LightMode);;
  background-color: var(--blueBorderColor_53B7E8_LightMode);
  background-color: white;
  color:  var(--blueBorderColor_53B7E8_LightMode);
  padding: 6px 8px;
  border-radius: 10px;
  // margin-top: 10px;
}

// Teams Preference styles
.createCard {
  margin-top: 20px;
  width: 100%;
  border-radius: 12px;
  height: auto;
  background-color: var(--white_FFFFFF_LightMode);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;

  .imageHolder {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    .imgInside {
      height: 250px;
      width: fit-content;
      object-fit: cover;
      border-radius: 20%;
    }

    .imgInside2 {
      height: 120px;
      width: fit-content;
      object-fit: cover;
      border-radius: 20%;
    }
  }

  .headingCusto {
    font-size: 16px;
  }

  .removeTable {
    border: 1px solid var(--blueBorderColor_53B7E8_LightMode);
    //border-right: none !important;
    //padding: 10px;
    //border-top-left-radius: 12px;
    //border-bottom-left-radius: 12px;
    border-radius: 12px;
    max-height: 200px;
    overflow-y: auto;
  }
}

.innerRoleSec {
  margin: 20px 0px;
  width: 100%;
  border-radius: 12px;
  height: auto;
  background-color: var(--white_FFFFFF_LightMode);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
}

.costingResultDetail {
  margin-top: 15px;

  .headingTxt {
    font-size: 16px !important;
    color: var(--gray_05_LightMode);
    font-weight: 600;
    margin: 0px 10px 10px 5px;
  }

  span {
    font-size: 14 !important;
    color: var(--gray_05_LightMode);
  }
}

.txtBoldTotal {
  font-weight: 600;
  font-size: 20px;
}

.proffesionalDetails {
  background-color: var(--white_FFFFFF_LightMode);
  height: 418px;
  border-radius: 12px;
  width: 100%;
  padding: 7px;
  margin-bottom: 20px;

  .profileCircle {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16), 0 4px 10px rgba(0, 0, 0, 0.23);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    img {
      height: 140px;
      width: 140px;
      border-radius: 50%;
    }
  }

  .detailslist {
    list-style: none;
    width: 80%;
    margin-bottom: 0rem !important;
    padding-left: 0rem !important;

    li {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;

      .nameLbl {
        font-weight: 600;
        color: var(--black_000000_LightMode);
        font-size: 13px;
      }

      .nameSpn {
        font-size: 15px;
        color: var(--gray_05_LightMode);
      }

      a {
        font-size: 13px;
      }
    }
  }
}

.personalDetails {
  background-color: var(--white_FFFFFF_LightMode);
  width: 100% !important;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;

  .headingtxt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
  }

  .headTxt {
    font-size: 18px;
    font-weight: 600;
    color: var(--black_000000_LightMode);
  }

  .actionBtn {
    border: none;
    background-color: transparent;
    height: 40px;
    width: 90px;
    color: var(--black_000000_LightMode);
    border: 1px solid #eee;
    border-radius: 12px;
    margin-bottom: 5px;
  }

  .keyValues {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;

    .key {
      font-size: 13px;
      color: var(--black_000000_LightMode);
      margin-bottom: 5px;
    }

    .value {
      font-size: 13px;
      color: var(--black_000000_LightMode);
      margin-bottom: 5px;
    }

    .nextLine {
      width: 70%;
    }
  }
}

.selectImg {
  .modalImg {
    height: 100px;
    width: 100px;
    border-radius: 12px;
    object-fit: cover;
    cursor: pointer;
  }
}

.impButton {
  border: none;
  padding: 10px;
  border-radius: 12px;
  background-color: var(--blueBorderColor_53B7E8_LightMode);
  color: #fff;
  height: 40px;
  font-size: 12px;
}

#tabsBelow {
  background-color: var(--white_FFFFFF_LightMode);
  padding: 20px;
  border-radius: 12px;

  .nav-tabs .nav-link.active {
    background-color: var(--blueBorderColor_53B7E8_LightMode) !important;
    color: #fff !important;
  }

  .nav-tabs .nav-link:hover {
    background-color: var(--blueBorderColor_53B7E8_LightMode) !important;
    color: #fff !important;
  }

  .nav-link {
    color: var(--black_000000_LightMode) !important;
    width: 300px !important;
    margin-bottom: 5px !important;
  }

  .prevBtn,
  .nextBtn {
    border: none;
    height: 40px;
    width: 80px;
    color: var(--white_FFFFFF_LightMode);
    border-radius: 12px;
    margin: 0px 5px;
    background-color: var(--blueBorderColor_53B7E8_LightMode);
  }
}

.settingsCard {
  background-color: var(--white_FFFFFF_LightMode);
  // height: 300px;
  border-radius: 12px;
  padding: 0px 20px 10px 20px;
  margin-top: 10px;
}

.belowLine {
  border-bottom: 1px solid #eee;
}

.txtHeadingClr {
  color: var(--black_000000_LightMode);
}

.settingFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;

  .demoTxt {
    color: var(--gray_05_LightMode);
  }
}

.form-select.tasKCategory1 {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 38px!important;
  //background-position: 97%;
}

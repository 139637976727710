.payment-reciept-wrapper {
    padding: 20px;

    background: #ffffff;

    p {
        color: rgba(17, 17, 17, 0.5);
        font-size: 0.9rem;
        margin-bottom: 0
    }
}

.payment-reciept-header {
    display: flex;
    justify-content: space-between;
    // background-color: #53B7E80D;
    background: #F6FBFE;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    padding: 20px;

    p {
        margin-bottom: 0;
    }

    label {
        color: rgba(17, 17, 17, 0.5);
        font-size: 0.9rem;
    }

    span {
        font-size: 0.9rem;
    }
}

.advance-pay-reciept {
    font-size: 1.8rem;
    font-weight: 600;
}

.reciept-number {
    color: rgba(17, 17, 17, 0.5);
    font-size: 0.9rem;
    margin-right: 9px;

}

.date-reciept {
    font-size: 0.9rem;

}

.stamp-box {
    width: 282px;
    height: 120px;


    border: 1px solid #D9D9D9;
    border-radius: 10px;
}

.reciept-footer {
    background: #F6FBFE;
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;


}

.amount-details {
    background-color: #F6FBFE;
    text-align: center;
    padding: 10px;
}

.amount-details-wrapper {
    label {
        color: #11111180;
        margin-right: 20px;
    }

    span {
        color: #11111180;
    }
}

.amount-details-wrapper-1 {
    label {
        color: #11111180;
        margin-right: 20px;
    }

    span {
        color: #11111180;
    }
}

.balance {
    color: #111111 !important;
}

.border-before {
    border-left: 1px solid #11111180;
    padding-left: 30px;
    margin-left: 15px;
}

.invoice-head-content {
    border-radius: 10px;
    padding: 10px;
    background-color: white;

}

.invoice-header {
    display: flex;
    justify-content: space-between;
}

.right-side-menus {
    display: flex;
    justify-content: space-between;

}

.tax-invoice-content {
    padding: 0 40px 40px;
    background-color: white;
    border-radius: 10px;


}

.taxinvoice-section-1 {
    border: 1px solid #111111;
    padding: 10px;
}

#upload-btn-cloud {
    width: 193.93px;
    height: 93px;
    border: 1px dashed #456786;
    cursor: pointer;
}

.upload-icon-text-cloud {
    position: absolute;
    top: 23%;
    left: 21%;
}

.tax-invoice {
    font-weight: 600;
}

.invoice-numbers {
    font-size: 11px;
    color: #232122;
}

.invoice-number-color {
    font-size: 11px;
    color: rgba(17, 17, 17, 0.5);
    margin-left: 15px;

}

.date-label-input-cloud {
    background: #ffffff;
    border: 1px dashed #00A9FC;
    margin-left: 44px;

    color: black;
    height: 21px;
    width: 118px;
    font-size: 10px;
    padding: 5px;
}

.date-label-input-cloud-1 {
    background: #ffffff;
    border: 1px dashed #00A9FC;
    margin-left: 22px;

    color: black;
    height: 21px;
    width: 118px;
    font-size: 10px;
    padding: 5px;
}

.owner-name-input {
    padding: 4px;
    font-size: 11px;
    border: 1px dashed #456786;
    width: 100%;

}

.owner-name-input:focus {
    outline: none;
}

.select-drop-icon-cloud {
    padding: 4px;
    font-size: 11px;
    border: 1px dashed #456786 !important;
    border-radius: none !important;
}

.cloud-text-area {
    border: 1px dashed #456786 !important;
    height: 60px;
    width: 100%;
    padding: 4px;
    font-size: 11px;
}

.cloud-text-area:focus {
    outline: none;
}

.place-supply {
    font-weight: 600;
    font-size: 14px;
}

.contac-number-input {
    // width: 100%;
    height: 21px;
    border: 1px dashed #00A9FC !important;
    font-size: 14px;
    padding: 4px;

}

.edit-span {
    font-size: 12px;
    color: #456786;
}


// ///////////////////// usama styles

.textleftalign {
    background-color: bisque !important;
    align-items: flex-end;
}

.uplodeAccount {
    position: relative;
    top: 25px;
}

.section1_Accounting {
    display: flex;
    justify-content: space-between;
}

.maindivSection-2 {
    width: 33%;

    p {
        font-size: 11px;
        color: #111111;
        font-weight: 600;
        margin-bottom: 2px;
    }
}

.parparapar p {
    font-size: 11px;
    color: #111111;
    font-weight: 600;
    margin-bottom: 2px;
}

.minboxwrapper {
    display: flex;
    justify-content: space-between;
}

.minInputBox {
    background: #ffffff;
    border: 1px dashed #456786;
    // margin-left: 44px;

    color: black;
    height: 21px;
    // width: 118px;
    font-size: 10px;
    padding: 5px;
}

.minboxwrapperLeft {
    display: flex;
    justify-content: space-between;
    // width: 111%;
}

.minInputBoxLeft {
    background: #ffffff;
    border: 1px dashed #456786;
    color: black;
    height: 21px;
    // width: 118px;
    font-size: 10px;
    padding: 5px;
}

.leftinput {
    width: 193.5px;
}

.selectborderremove {
    select {
        border-radius: 0.1rem;
    }
}

.accountsectiontabelrecipt {
    margin-top: 40px;
    margin-left: -11px;
    margin-right: -11px;

    table {
        width: 100%;

        tr {

            th,
            td {
                border: 1px solid black;
            }

            th {
                // padding-left: 14px;
                text-align: center;
                font-size: 11px;
            }

            td {
                padding: 10px;

                input {
                    border: none;
                    background-color: transparent;
                    text-align: center;
                    width: 100%;
                    font-size: 11px;
                }

                :focus {
                    outline: none;
                }
            }
        }
    }
}

.accountsectiontotal {
    margin-top: 30px;
    border-bottom: 1px solid grey;

    .maintabel {
        div {
            display: flex;
            padding: 0 20px;
            justify-content: space-between;

            label {
                font-size: 11px;
                color: #232122;
                font-weight: 500;
            }

            p {
                // width: 30px;
                font-size: 11px;
                color: #232122;
                margin-bottom: 2px;
                font-weight: 500;
            }
        }
    }
}

.accountsectiontotal-2 {
    margin-top: 10px;
    border-bottom: 1px solid black;

    .maintabel-2 {
        div {
            display: flex;
            padding: 0 20px;
            justify-content: space-between;
            align-items: center;

            label {
                font-size: 12px;
                color: #232122;
                font-weight: 700;
            }

            p {
                // width: 30px;
                font-size: 12px;
                color: #232122;
                margin-bottom: 2px;
                font-weight: 700;
            }
        }
    }
}

.bottonsectionaccounyting {
    padding: 20px;

    .textareabottom {
        textarea {
            border: 1px solid #111111;
            height: 100px;
            width: 334px;
            border-radius: 5px;
            padding: 10px;
        }
    }

    .uplodebottomsection {
        height: 100px;
        // width: 414px;
        border: 1px solid black;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        label {
            margin-top: 42px;
            div {
                p {
                    font-size: 11px;
                }
            }
        }
    }
}

//////tex 



.texAccountTabel {
    table tr:first-child{
        background-color: #F6FBFE;
        height: 40px; 
    }
    tr{
        border-top-left-radius: 50px;
    }
    margin-top: 40px;
    // margin-left: -11px;
    // margin-right: -11px;

    table {
        width: 100%;

        tr {
            th {
                // padding-left: 14px;
                text-align: center;
                font-size: 11px;
            }
            td {
                padding: 10px;
                input {
                    border: none;
                    background-color: transparent;
                    text-align: center;
                    width: 100%;
                    font-size: 11px;
                }

                :focus {
                    outline: none;
                }
            }
        }
    }
}
// style={{backgroundColor:"#F6FBFE",height:"40px",width:"273px"}}
.Txemaintabel-2 {
    div {
        display: flex;
        padding: 0 20px;
        justify-content: space-between;
        align-items: center;
        background-color: #F6FBFE;
        height:40px;
        width:273px;
        margin-bottom: 10px;

        label {
            font-size: 12px;
            color: #232122;
            font-weight: 700;
        }

        p {
            // width: 30px;
            font-size: 12px;
            color: #232122;
            margin-bottom: 2px;
            font-weight: 700;
        }
    }
}
.botdernonr{
    border-bottom: none;
}
.taxBreckups{
    display: flex;
    div{
        p{
            font-size: 16px;
            font-weight: 400;
            color: #111111;
            margin-right: 20px;
        }
    }
    .linTax{
        border-top: 2px solid grey;
        width:83%;
        position: relative;
        top: 12px;
    }
}
.tabeldataTex{
    font-size: 11px ;
    font-weight: 500;
    font-size: 11px !important;
    color: #11111180;
}
.tabeldataTexhead{
    color: black !important;
    font-size: 11px !important;
}
.textablelast{
    width: 300px !important;
}
.btnbtnbtn{
    button{
        width: 120px;
    }
}
.intimeinput{
    input{
        width: 100px;
    }
}
.brackaddbtn{
    label{
        font-weight: 600;
    }
    svg{
        border: 1px solid #53b7e8;
        border-radius: 5px;
    }
}
.breckinpute{
    input{
        width: 300px;
    }
}
.Qutationtabelselect{
    width: 20%;
    select{
        border-color: #53b7e8;
    }
}
.midbtn{
    width: auto !important;
}


//reponsive 

@media all and (max-width:624px){
    .responsivetopbar{
        display: block !important;
        .firstdiv{
            justify-content: space-between;
        }
    }
}

@media all and (max-width:486px){
    .responsivetopbar{
        .firstdiv{
            display: block !important;
            // justify-content: space-between;
        }
    }
}
.paginationBttns {
  display: flex !important;
  /* flex-wrap: wrap; */
  justify-content: end;
  list-style: none;
  position: absolute;
  bottom: 15px;
  right: 20px;
  position: relative;
}

.paginationBttns a {
  padding: 6px 10px 6px 10px;
  margin-left: 10px;
  border-radius: 5px;
  /* border: 1px solid var(--primary-color); */
  border: 1px solid #53b7e8;
  /* color: var(--primary-color) !important; */
  color: #53b7e8;
  font-size: 13px;
  cursor: pointer;
  text-decoration: none;
}

.paginationBttns a:hover {
  color: white !important;
  /* background-color: var(--primary-color); */
  background-color: #53b7e8;
}

.paginationActive a {
  color: white !important;
  /* background-color: var(--primary-color); */
  background-color: #53b7e8;
}

@media all and (max-width: 768px) {
  .paginationBttns {
    li {
      margin-bottom: 20px !important;
    }
  }
}

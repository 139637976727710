/* Phonebookdash.css */

.phonebook-dash-container {
  padding: 20px;
}

.phonebook-dash-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.phonebook-dash-item {
  background-color: var(--whitetbheadLightMode);
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  flex: 1 1 calc(33.333% - 40px); /* Three items per row with a gap of 20px */
  box-sizing: border-box;
  min-width: 200px; /* Ensure items don't get too small */
  max-width: 300px; /* Optional: limit the max width */
  text-align: center;

}
.phonebook-dash-item-two{
  background-color: var(--whitetbheadLightMode);
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  // flex: 1 1 calc(33.333% - 40px); /* Three items per row with a gap of 20px */
  box-sizing: border-box;
  // min-width: 200px; /* Ensure items don't get too small */
  // max-width: 300px; /* Optional: limit the max width */
  // text-align: center;
    margin-right: 20px;
    position: relative;
    overflow: hidden; /* Ensures the image does not overflow the container */

}
.promotiondate{
  font-size: 14px;
  height: 43px;
  border: 1px solid #ced4da ;
  margin-left: 20px;
  padding-left: 5px;
  border-radius: 10px;

}
.darkMode .promotiondate{
  font-size: 14px;
  height: 43px;
  border: 1px solid #ced4da ;
  margin-left: 20px;
  padding-left: 5px;
  border-radius: 10px;
  background-color: #414141;

}
.phonebook-dash-item-two img {
  max-width: 100%; /* Ensures the image scales down with the container */
  height: auto; /* Maintains the aspect ratio */
  display: block; /* Removes any extra space below the image */
}

.outerrowstyle {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the image horizontally */
}

.innerrowstyle {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Ensures the child elements take the full width of the container */
}

.head {
  font-size: 1rem; /* Adjust font size as needed */
}
.darkMode .head {
  color:white;
  font-size: 1rem; /* Adjust font size as needed */
}
.resett {
  display: flex;
  background-color:#53B7E8;
 
  width: 100px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: white;
  
}

@media (max-width: 768px) {
  .phonebook-dash-item-two {
    /* Adjust padding/margin or any other styles for smaller screens */
  }
}

.schedule_Date{
  font-size: 12px;color: #212529;
  width: 100px;

}

.darkMode .schedule_Date{
  
  font-size: 12px;color:white;
  width: 100px;

}
// .innerrowstyle{
//   flex-direction: row;
//   display: flex;
//   align-items: center;
 
// }
.outerrowstyle{
  flex-direction: row;
  justify-content: space-between;
  display: flex;

}
.item-title {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: var(--black_111111_LightMode);
}

.item-count {
  font-size: 1em;
  color: #666;
  color: var(--gray_05_LightMode);
}

#tabledash {
  width: 100%;

  border-collapse: collapse;
  text-align: center;
}
.lefttg {
  right: -25px !important;
  position: relative;
  top: 30px;
}
#tabledash th,
#tabledash td {
  border: 1px solid #dee2e6;
  padding: 8px;
}
#tabledash tr {
  color: var(--whiteMainaLightMode);
}

#tabledash th {
  background-color: var(--whitetbheadLightMode);
  color: var(--whiteMainaLightMode);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .phonebook-dash-item {
    flex: 1 1 calc(50% - 40px); /* Two items per row on small screens */
  }
}

@media (max-width: 480px) {
  .phonebook-dash-item {
    flex: 1 1 100%; /* One item per row on extra small screens */
  }
}

// WeeklyMain---------

.WeeklyMain {
  background-color: var(--white_FFFFFF_LightMode);
  border: 1px solid #ccc;
  padding: 20px;
  margin-top: 10px;
  border-radius: 10px;
}

.summary {
  font-size: 15px;
  color: #666;
}
.summarybld {
  font-size: 20px;
  color: black;
  color: var(--black_111111_LightMode);
}
.rowstyle {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}
.form_bgnd {
  border: 1px solid #ffff;
  border-radius: 10px;
  background-color: #f8f8ff;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
}
.option {
  font-size: 15px;
  color: #666;
}
.Barcharttsize {
  width: "100%";
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .Barcharttsize {
    height: 30vh;
  }
}
@media (max-width: 560px) {
  .Barcharttsize {
    height: 30vh;
  }
  .resdom {
    margin-top: 20px;
  }
  .lefttg {
    right: -25px !important;
    position: relative;
    top: 50px;
  }
}
// whatsApppp

.Searchwhats {
  border: 1px solid #666;
  padding-left: 20px;
}
.tasKCategory1 {
  height: 30px;
  border-radius: 10px !important;
  background-color: var(--whitetbheadLightMode) !important;
}
.tasKshow {
  height: 30px;
  width: 60%;
  padding-left: 0px;
  padding-right: 30px;
  border-radius: 10px !important;
  border: 1px solid var(--blue_53B7E8_LightMode);
}

.tasKshowusser {
  height: 30px;
  width: 100%;
  padding-left: 0px;
  padding-right: 30px;
  border-radius: 10px !important;
  border: 1px solid var(--blue_53B7E8_LightMode);
}

.whatsapp-button {
  background-color: var(--blue_53B7E8_LightMode);
  border: none;
  border-radius: 10px;
  color: white;
  padding: 5px 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.what-button {
  background-color: var(--blue_53B7E8_LightMode);
  border: none;
  border-radius: 10px;
  color: white;
  padding: 5px 0px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.whatsapp-button:hover {
  background-color: var(--blue_53B7E8_LightMode);
}

@media (max-width: 768px) {
  .whatsapp-button {
    width: 100%;
    font-size: 14px;
  }
}

/* Container styles */
// .table-responsive {
//   width: 100%;
//   overflow-x: auto;

//   background-color: crimson;
// }

/* Table styles  starting*/
.tablemain {
  width: 100%;
  border-collapse: collapse;
}

.tablemain th,
.tablemain td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  vertical-align: top;
}

.tablemain th {
  background-color: #f2f2f2;
}

/* Token column specific style */
.token-column {
  word-wrap: break-word; /* Ensure long words break and wrap */
  white-space: normal; /* Allow text to wrap */
  width: 50%; /* Adjust width as needed */
}
.Source {
  white-space: nowrap;
  align-items: center;
  justify-content: center;
}
/* Button styles */
.btn {
  padding: 5px 10px;
  margin: 2px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.create-btn-sub-headerwhtsapp {
  border: none;
  //background-color: rgba(83, 183, 232, 0.1);
  border-radius: 18px;
  color: #53b7e8;
  font-size: 0.8rem;
  width: 184px;
  height: 35px;
  font-weight: 500;
}

.btn-delete {
  background-color: var(--blue_53B7E8_LightMode);
  color: white;
}

.btn-update {
  background-color: var(--blue_53B7E8_LightMode);
  color: white;
}

.btn-delete:hover {
  background-color: var(--blue_53B7E8_LightMode);
  color: white;
}

.btn-update:hover {
  background-color: var(--blue_53B7E8_LightMode);
  color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .tablemain th,
  .tablemain td {
    font-size: 12px;
    padding: 6px;
  }

  .token-column {
    width: auto; /* Adjust for smaller screens */
  }
}

/* Pagination styles */
.paginationx {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.paginationx button {
  background-color: var(--blue_53B7E8_LightMode);
  border: 1px solid #ddd;
  padding: 4px 6px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 14px;
  color: black;
}

.paginationx button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination-info {
  margin: 0 10px;
}

/* Create token */
a {
  text-decoration: none;
}
.textcolor {
  color: white;
}
/* Push Notification */

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}
.pushmain {
  h4 {
    color: var(--black_000000_LightMode);
    font-size: 20px;
  }
  p {
    color: var(--black_000000_LightMode);
    font-size: 12px;
    width: 60%;
    margin-top: 10px;
  }
  a {
    color: var(--black_000000_LightMode);
    font-size: 18px;
    font-weight: 900;
  }
  label {
    color: var(--black_000000_LightMode);
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .pushbox {
    width: 100% !important;
  }

  .buttonpushmain {
    align-items: end;
    justify-content: end;
    display: flex;
  }

  .buttonpush {
    background-color: var(--black_000000_LightMode);
  }
}
